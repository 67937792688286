const resourcesConfig = {
  "frogs": {
    images: {
      wallper: require("../games-folder/frogs-game/images/wallper.png"),
      frog: require("../games-folder/frogs-game/images/frog.svg"),
      tableLamp: require("../games-folder/frogs-game/images/table-lamp.svg"),
      frogHouse: require("../games-folder/frogs-game/images/house.svg"),
      frogLight: require("../games-folder/frogs-game/images/frog-lighting.svg"),
      frogHouseLight: require("../games-folder/frogs-game/images/light-for-home.svg"),
      lamp: require("../games-folder/frogs-game/images/lamp.svg"),
      frogMother: require("../games-folder/frogs-game/images/mother.svg"),
      al: require("../games-folder/frogs-game/images/al.svg"),
      il: require("../games-folder/frogs-game/images/il.svg"),
      la: require("../games-folder/frogs-game/images/la.svg"),
      lo: require("../games-folder/frogs-game/images/lo.svg"),
      lu: require("../games-folder/frogs-game/images/lu.svg"),
      ly: require("../games-folder/frogs-game/images/ly.svg"),
      le: require("../games-folder/frogs-game/images/le.svg"),
      ol: require("../games-folder/frogs-game/images/ol.svg"),
      ul: require("../games-folder/frogs-game/images/ul.svg"),
      el: require("../games-folder/frogs-game/images/el.svg"),
    },
    sounds: {
      backgroundSound: require("../games-folder/frogs-game/sounds/background.mp3"),
      startInstructionSound: require("../games-folder/frogs-game/sounds/instruction.mp3"),
      jumpSound: require("../games-folder/frogs-game/sounds/jump.mp3"),
      actionSound: require("../games-folder/frogs-game/sounds/action.mp3"),
      finalSound: require("../games-folder/frogs-game/sounds/praise.mp3"),
    },
  },

  "smaller-bigger": {
    images: {
      wallperImage: require("../games-folder/bigger-smaller/images/wallper.png"),
      elephant: require("../games-folder/bigger-smaller/images/elephant.svg"),
      mouse: require("../games-folder/bigger-smaller/images/mouse.svg"),
      bigHouse: require("../games-folder/bigger-smaller/images/bigHouse.svg"),
      smallHouse: require("../games-folder/bigger-smaller/images/smallHouse.svg"),
      giraffe: require("../games-folder/bigger-smaller/images/giraffe.svg"),
      zebra: require("../games-folder/bigger-smaller/images/zebra.svg"),
      aircraft: require("../games-folder/bigger-smaller/images/aircraft.svg"),
      bus: require("../games-folder/bigger-smaller/images/bus.svg"),
      sparrow: require("../games-folder/bigger-smaller/images/sparrow.svg"),
      peacock: require("../games-folder/bigger-smaller/images/peacock.svg"),
      notebook: require("../games-folder/bigger-smaller/images/notebook.svg"),
      book: require("../games-folder/bigger-smaller/images/book.svg"),
      table: require("../games-folder/bigger-smaller/images/table.svg"),
      chair: require("../games-folder/bigger-smaller/images/chair.svg"),
      tree: require("../games-folder/bigger-smaller/images/tree.svg"),
      bush: require("../games-folder/bigger-smaller/images/bush.svg"),
    },
    sounds: {
      backgroundSound: require("../games-folder/bigger-smaller/sounds/background.mp3"),
      startInstructionSound: require("../games-folder/bigger-smaller/sounds/instruction.mp3"),
      rightAnswerSound: require("../games-folder/bigger-smaller/sounds/yes.mp3"),
      wrongAnswerSound: require("../games-folder/bigger-smaller/sounds/no.mp3"),
      whatBiggerSound: require("../games-folder/bigger-smaller/sounds/whatBigger.mp3"),
      whatSmallerSound: require("../games-folder/bigger-smaller/sounds/whatSmaller.mp3"),
      whoBiggerSound: require("../games-folder/bigger-smaller/sounds/whoBigger.mp3"),
      whoSmallerSound: require("../games-folder/bigger-smaller/sounds/whoSmaller.mp3"),
      finalSound: require("../games-folder/bigger-smaller/sounds/praise.mp3"),
    },
  },

  "fish": {
    images: {
      wallper: require("../games-folder/fish-game/images/wallper.svg"),
      homeMask: require("../games-folder/fish-game/images/homeMask.svg"),
      leftCoral: require("../games-folder/fish-game/images/left-coral.svg"),
      rightCoral: require("../games-folder/fish-game/images/right-coral.svg"),
      mainFish: require("../games-folder/fish-game/images/mainFish.svg"),
      lynx: require("../games-folder/fish-game/images/lynx.svg"),
      slingshot: require("../games-folder/fish-game/images/slingshot.svg"),
      robot: require("../games-folder/fish-game/images/robot.svg"),
      shell: require("../games-folder/fish-game/images/shell.svg"),
      rocket: require("../games-folder/fish-game/images/rocket.svg"),
      justFish: require("../games-folder/fish-game/images/justFish.svg"),
      rainbow: require("../games-folder/fish-game/images/rainbow.svg"),
    },
    sounds: {
      backgroundSound: require("../games-folder/fish-game/sounds/background.mp3"),
      startInstructionSound: require("../games-folder/fish-game/sounds/instruction.mp3"),
      actionSound: require("../games-folder/fish-game/sounds/action.mp3"),
      finalSound: require("../games-folder/fish-game/sounds/praise.mp3"),
    },
  },

  "dinosaur": {
  images: {
    wallper: require("../games-folder/dinosaur/images/wallper.png"),
    dinosaurDirt: require("../games-folder/dinosaur/images/dinosaurDirt.png"),
    dinoClear: require("../games-folder/dinosaur/images/dinoClear.png"),
    toothbrush: require("../games-folder/dinosaur/images/toothbrush.png"),
    ar: require("../games-folder/dinosaur/images/ar.png"),
    er: require("../games-folder/dinosaur/images/er.png"),
    ir: require("../games-folder/dinosaur/images/ir.png"),
    or: require("../games-folder/dinosaur/images/or.png"),
    ra: require("../games-folder/dinosaur/images/ra.png"),
    re: require("../games-folder/dinosaur/images/re.png"),
    ro: require("../games-folder/dinosaur/images/ro.png"),
    ru: require("../games-folder/dinosaur/images/ru.png"),
    ry: require("../games-folder/dinosaur/images/ry.png"),
    ur: require("../games-folder/dinosaur/images/ur.png"),
  },
  sounds: {
    backgroundSound: require("../games-folder/dinosaur/sounds/backgroundSound.mp3"),
    startInstructionSound: require("../games-folder/dinosaur/sounds/instruction.mp3"),
    actionSound: require("../games-folder/dinosaur/sounds/toothbrashsound.mp3"),
    finalSound: require("../games-folder/dinosaur/sounds/praise.mp3"),
  },
},


"animals-at-farm": {
    images: {
      wallper: require("../games-folder/animals-farm/images/wallper.png"),
      goat: require("../games-folder/animals-farm/images/goat.png"),
      cow: require("../games-folder/animals-farm/images/cow.png"),
      cat: require("../games-folder/animals-farm/images/cat.png"),
      sheep: require("../games-folder/animals-farm/images/sheep.png"),
      donkey: require("../games-folder/animals-farm/images/donkey.png"),
      cock: require("../games-folder/animals-farm/images/cock.png"),
      pig: require("../games-folder/animals-farm/images/pig.png"),
      dog: require("../games-folder/animals-farm/images/dog.png"),
      catShadow: require("../games-folder/animals-farm/images/catShadow.png"),
      cockShadow: require("../games-folder/animals-farm/images/cockShadow.svg"),
      cowShadow: require("../games-folder/animals-farm/images/cowShadow.svg"),
      dogShadow: require("../games-folder/animals-farm/images/dogShadow.svg"),
      donkeyShadow: require("../games-folder/animals-farm/images/donkeyShadow.svg"),
      goatShadow: require("../games-folder/animals-farm/images/goatShadow.svg"),
      pigShadow: require("../games-folder/animals-farm/images/pigShadow.svg"),
      sheepShadow: require("../games-folder/animals-farm/images/sheepShadow.svg"),
    },
    sounds: {
      backgroundSound: require("../games-folder/animals-farm/sounds/background.mp3"),
      startInstructionSound: require("../games-folder/animals-farm/sounds/instruction.mp3"),
      incorrectSound: require("../games-folder/animals-farm/sounds/no.mp3"),
      catSound: require("../games-folder/animals-farm/sounds/cat.mp3"),
      cowSound: require("../games-folder/animals-farm/sounds/cow.mp3"),
      dogSound: require("../games-folder/animals-farm/sounds/dog.mp3"),
      donkeySound: require("../games-folder/animals-farm/sounds/donkey.mp3"),
      goatSound: require("../games-folder/animals-farm/sounds/goat.mp3"),
      cockSound: require("../games-folder/animals-farm/sounds/koko.mp3"),
      pigSound: require("../games-folder/animals-farm/sounds/pig.mp3"),
      sheepSound: require("../games-folder/animals-farm/sounds/sheep.mp3"),
      finalSound: require("../games-folder/animals-farm/sounds/praise.mp3"),
      winSound: require("../games-folder/animals-farm/sounds/win.mp3"),
    },
  },

  "animals-in-forest": {
    images: {
      wallper: require("../games-folder/animals-in-forest/images/wallper.png"),
      squirell: require("../games-folder/animals-in-forest/images/squirell.png"),
      hedgehog: require("../games-folder/animals-in-forest/images/hedgehog.png"),
      rabbit: require("../games-folder/animals-in-forest/images/rabbit.svg"),
      boar: require("../games-folder/animals-in-forest/images/boar.png"),
      fox: require("../games-folder/animals-in-forest/images/fox.svg"),
      bear: require("../games-folder/animals-in-forest/images/bear.svg"),
      owl: require("../games-folder/animals-in-forest/images/owl.png"),
      wolf: require("../games-folder/animals-in-forest/images/wolf.svg"),
      squirellShadow: require("../games-folder/animals-in-forest/images/squirellShadow.svg"),
      hedgehogShadow: require("../games-folder/animals-in-forest/images/hedgehodShadow.svg"),
      rabbitShadow: require("../games-folder/animals-in-forest/images/rabbitShadow.svg"),
      boarShadow: require("../games-folder/animals-in-forest/images/boarShadow.svg"),
      foxShadow: require("../games-folder/animals-in-forest/images/foxShadow.svg"),
      bearShadow: require("../games-folder/animals-in-forest/images/bearShadow.svg"),
      owlShadow: require("../games-folder/animals-in-forest/images/owlShadow.svg"),
      wolfShadow: require("../games-folder/animals-in-forest/images/wolfShadow.svg"),
    },
    sounds: {
      backgroundSound: require("../games-folder/animals-in-forest/sounds/backgroundSound.mp3"),
      startInstructionSound: require("../games-folder/animals-in-forest/sounds/startInstructionSound.mp3"),
      finalSound: require("../games-folder/animals-in-forest/sounds/finalSound.mp3"),
      incorrectAnswerSound: require("../games-folder/animals-in-forest/sounds/no.mp3"),
      bearSound: require("../games-folder/animals-in-forest/sounds/bearSound.mp3"),
      boarSound: require("../games-folder/animals-in-forest/sounds/boarSound.mp3"),
      foxSound: require("../games-folder/animals-in-forest/sounds/foxSound.mp3"),
      hedgehogSound: require("../games-folder/animals-in-forest/sounds/hedgehogSound.mp3"),
      owlSound: require("../games-folder/animals-in-forest/sounds/owlSound.mp3"),
      rabbitSound: require("../games-folder/animals-in-forest/sounds/rabbitSound.mp3"),
      squirellSound: require("../games-folder/animals-in-forest/sounds/squirellSound.mp3"),
      wolfSound: require("../games-folder/animals-in-forest/sounds/wolfSound.mp3"),
    },
  },

  "animals-of-hot-countries": {
    images: {
      wallper: require("../games-folder/animals-hot-countries/images/wallper.png"),
      hippopotamus: require("../games-folder/animals-hot-countries/images/hippopotamus.png"),
      camel: require("../games-folder/animals-hot-countries/images/camel.svg"),
      giraffee: require("../games-folder/animals-hot-countries/images/giraffee.png"),
      zebra: require("../games-folder/animals-hot-countries/images/zebra.png"),
      kangaroo: require("../games-folder/animals-hot-countries/images/kangaroo.png"),
      crocodile: require("../games-folder/animals-hot-countries/images/crocodile.png"),
      lion: require("../games-folder/animals-hot-countries/images/lion.png"),
      elephant: require("../games-folder/animals-hot-countries/images/elephant.png"),
      tiger: require("../games-folder/animals-hot-countries/images/tiger.png"),
      camelShadow: require("../games-folder/animals-hot-countries/images/camelShadow.svg"),
      crocodileShadow: require("../games-folder/animals-hot-countries/images/crocShadow.svg"),
      elephantShadow: require("../games-folder/animals-hot-countries/images/elephantShadow.svg"),
      giraffeeShadow: require("../games-folder/animals-hot-countries/images/giraffeShadow.svg"),
      kangarooShadow: require("../games-folder/animals-hot-countries/images/kangarooShadow.svg"),
      lionShadow: require("../games-folder/animals-hot-countries/images/lionShadow.svg"),
      tigerShadow: require("../games-folder/animals-hot-countries/images/tigerShadow.svg"),
      zebraShadow: require("../games-folder/animals-hot-countries/images/zebraShadow.svg"),
      hippopotamusShadow: require("../games-folder/animals-hot-countries/images/hippopotamusShadow.svg"),
    },
    sounds: {
      backgroundSound: require("../games-folder/animals-hot-countries/sounds/backgroundSound.mp3"),
      startInstructionSound: require("../games-folder/animals-hot-countries/sounds/startInstructionSound.mp3"),
      incorrectAnswerSound: require("../games-folder/animals-hot-countries/sounds/no.mp3"),
      finalSound: require("../games-folder/animals-hot-countries/sounds/finalSound.mp3"),
      camelSound: require("../games-folder/animals-hot-countries/sounds/camel.mp3"),
      crocodileSound: require("../games-folder/animals-hot-countries/sounds/crocodile.mp3"),
      elephantSound: require("../games-folder/animals-hot-countries/sounds/elephant.mp3"),
      giraffeeSound: require("../games-folder/animals-hot-countries/sounds/giraffee.mp3"),
      hippopotamusSound: require("../games-folder/animals-hot-countries/sounds/hippopotamus.mp3"),
      kangarooSound: require("../games-folder/animals-hot-countries/sounds/kangaroo.mp3"),
      lionSound: require("../games-folder/animals-hot-countries/sounds/lion.mp3"),
      tigerSound: require("../games-folder/animals-hot-countries/sounds/tiger.mp3"),
      zebraSound: require("../games-folder/animals-hot-countries/sounds/zebra.mp3"),
    },
  },

  "find-in-room": {
    images: {
      wallperImage: require("../games-folder/find-in-room-game/images/wallper.png"),
      alarm: require("../games-folder/find-in-room-game/images/alarm.svg"),
      shoes: require("../games-folder/find-in-room-game/images/shoes.svg"),
      cubes: require("../games-folder/find-in-room-game/images/cubes.svg"),
      car: require("../games-folder/find-in-room-game/images/car.svg"),
      ball: require("../games-folder/find-in-room-game/images/ball.svg"),
      scooter: require("../games-folder/find-in-room-game/images/scooter.svg"),
      aircraft: require("../games-folder/find-in-room-game/images/aircraft.svg"),
    },
    sounds: {
      backgroundSound: require("../games-folder/find-in-room-game/sounds/backgroundSound.mp3"),
      startInstructionSound: require("../games-folder/find-in-room-game/sounds/startInstruction.mp3"),
      finalSound: require("../games-folder/find-in-room-game/sounds/finalSound.mp3"),
      incorrectAnswerSound: require("../games-folder/find-in-room-game/sounds/no.mp3"),
      aircraftSound: require("../games-folder/find-in-room-game/sounds/aircraft.mp3"),
      alarmSound: require("../games-folder/find-in-room-game/sounds/alarm.mp3"),
      ballSound: require("../games-folder/find-in-room-game/sounds/ball.mp3"),
      carSound: require("../games-folder/find-in-room-game/sounds/car.mp3"),
      cubesSound: require("../games-folder/find-in-room-game/sounds/cubes.mp3"),
      scooterSound: require("../games-folder/find-in-room-game/sounds/scooter.mp3"),
      shoesSound: require("../games-folder/find-in-room-game/sounds/shoes.mp3"),
    },
  },
"guess-action": {
  images: {
    motherEat: require("../games-folder/guess-action/images/motherEating.png"),
    motherPlay: require("../games-folder/guess-action/images/motherPlaying.png"),
    motherGo: require("../games-folder/guess-action/images/motherGo.png"),
    motherLie: require("../games-folder/guess-action/images/motherLie.png"),
    motherCLothes: require("../games-folder/guess-action/images/motherClothes.png"),
    motherRead: require("../games-folder/guess-action/images/motherRead.png"),
    motherWalk: require("../games-folder/guess-action/images/motherWalk.png"),
    motherCook: require("../games-folder/guess-action/images/motherCooking.png"),
    fatherBuy: require("../games-folder/guess-action/images/fatherBuy.png"),
    fatherEat: require("../games-folder/guess-action/images/fatherEat.png"),
    fatherPlay: require("../games-folder/guess-action/images/fatherPlay.png"),
    fatherRead: require("../games-folder/guess-action/images/fatherRead.png"),
    fatherRun: require("../games-folder/guess-action/images/fatherRun.png"),
    fatherWash: require("../games-folder/guess-action/images/fatherWash.png"),
    fatherWork: require("../games-folder/guess-action/images/fatherWork.png"),
    fatherLie: require("../games-folder/guess-action/images/fatherLie.png"),
    grandmaClean: require("../games-folder/guess-action/images/grandmaClean.png"),
    grandmaRead: require("../games-folder/guess-action/images/grandmaRead.png"),
    grandmaSeat: require("../games-folder/guess-action/images/grandmaSeat.png"),
    grandmaSing: require("../games-folder/guess-action/images/grandmaSing.png"),
    grandmaSleep: require("../games-folder/guess-action/images/grandmaSleep.png"),
    grandmaWash: require("../games-folder/guess-action/images/grandmaWash.png"),
    grandmaWork: require("../games-folder/guess-action/images/grandmaWork.png"),
    repeatTask: require("../games-folder/guess-action/images/repeatTask.png"),
  },
  sounds: {
    startInstructionSound: require("../games-folder/guess-action/sounds/startInstruction.mp3"),
    finalSound: require("../games-folder/guess-action/sounds/finalSound.mp3"),
    correctAnswerSound: require("../games-folder/guess-action/sounds/rightAnswer.mp3"),
    incorrectAnswerSound: require("../games-folder/guess-action/sounds/incorrectAnswer.mp3"),
    fatherBuySound: require("../games-folder/guess-action/sounds/fatherBuy.mp3"),
    fatherEatSound: require("../games-folder/guess-action/sounds/fatherEat.mp3"),
    fatherWashSound: require("../games-folder/guess-action/sounds/fatherWash.mp3"),
    grandmaCleanSound: require("../games-folder/guess-action/sounds/grandmaClean.mp3"),
    grandmaSingSound: require("../games-folder/guess-action/sounds/grandmaSong.mp3"),
    grandmaWashSound: require("../games-folder/guess-action/sounds/grandmaWash.mp3"),
    motherCookSound: require("../games-folder/guess-action/sounds/motherCook.mp3"),
    motherGoSound: require("../games-folder/guess-action/sounds/motherGo.mp3"),
    motherSleepSound: require("../games-folder/guess-action/sounds/motherSleep.mp3"),
  },
},

"find-in-the-park": {
  images: {
    wallper: require("../games-folder/find-in-park/images/wallper.png"),
    squirrel: require("../games-folder/find-in-park/images/squirrel.png"),
    girlBycicle: require("../games-folder/find-in-park/images/girlBycicle.png"),
    grandpa: require("../games-folder/find-in-park/images/grandpa.png"),
    manGo: require("../games-folder/find-in-park/images/manGo.png"),
    rabbit: require("../games-folder/find-in-park/images/rabbit.png"),
    babyGo: require("../games-folder/find-in-park/images/babyGo.png"),
    boyCry: require("../games-folder/find-in-park/images/boyCry.png"),
    momSong: require("../games-folder/find-in-park/images/momSong.png"),
    bear: require("../games-folder/find-in-park/images/bear.png"),
    fatherCook: require("../games-folder/find-in-park/images/fatherCook.png"),
    star: require("../games-folder/find-in-park/images/star.svg"),
    repeatTask: require("../games-folder/find-in-park/images/repeatTask.png"),
  },
  sounds: {
    startInstruction: require("../games-folder/find-in-park/sounds/startInstruction.mp3"),
    bonusStar: require("../games-folder/find-in-park/sounds/bonusStar.mp3"),
    finalSound: require("../games-folder/find-in-park/sounds/finalSound.mp3"),
    incorrectAnswer: require("../games-folder/find-in-park/sounds/no.mp3"),
    babySpeaker: require("../games-folder/find-in-park/sounds/babySpeaker.mp3"),
    babyAction: require("../games-folder/find-in-park/sounds/babyAction.mp3"),
    bearSpeaker: require("../games-folder/find-in-park/sounds/bearSpeaker.mp3"),
    bearAction: require("../games-folder/find-in-park/sounds/bearAction.mp3"),
    boySpeaker: require("../games-folder/find-in-park/sounds/boySpeaker.mp3"),
    boyAction: require("../games-folder/find-in-park/sounds/boyAction.mp3"),
    fatherSpeaker: require("../games-folder/find-in-park/sounds/fatherSpeaker.mp3"),
    fatherAction: require("../games-folder/find-in-park/sounds/fatherAction.mp3"),
    girlSpeaker: require("../games-folder/find-in-park/sounds/girlSpeaker.mp3"),
    girlAction: require("../games-folder/find-in-park/sounds/girlAction.mp3"),
    grandpaSpeaker: require("../games-folder/find-in-park/sounds/grandpaSpeaker.mp3"),
    grandpaAction: require("../games-folder/find-in-park/sounds/grandpaAction.mp3"),
    motherSpeaker: require("../games-folder/find-in-park/sounds/motherSpeaker.mp3"),
    motherAction: require("../games-folder/find-in-park/sounds/motherAction.mp3"),
    rabbitSpeaker: require("../games-folder/find-in-park/sounds/rabbitSpeaker.mp3"),
    rabbitAction: require("../games-folder/find-in-park/sounds/rabbitAction.mp3"),
    squirellAction: require("../games-folder/find-in-park/sounds/squirellAction.mp3"),
    squirellSpeaker: require("../games-folder/find-in-park/sounds/squirellSpeaker.mp3"),
    uncleSpeaker: require("../games-folder/find-in-park/sounds/uncleSpeaker.mp3"),
    uncleAction: require("../games-folder/find-in-park/sounds/uncleAction.mp3"),
  },
},

"artist-mistakes": {
images: {
  wallper: require("../games-folder/artist-mistakes/images/wallper.svg"),
  hedgehogIncorrect: require("../games-folder/artist-mistakes/images/hedgehogIncorrect.png"),
  hedgehog: require("../games-folder/artist-mistakes/images/hedgehog.svg"),
  squirellIncorrect: require("../games-folder/artist-mistakes/images/squirellIncor.png"),
  squirell: require("../games-folder/artist-mistakes/images/squirell.png"),
  foxIncorrect: require("../games-folder/artist-mistakes/images/foxIncorrect.svg"),
  fox: require("../games-folder/artist-mistakes/images/fox.svg"),
  bear: require("../games-folder/artist-mistakes/images/bear.svg"),
  bearIncorrect: require("../games-folder/artist-mistakes/images/bearIncorrect.png"),
  incorrectWolf: require("../games-folder/artist-mistakes/images/wolfIncorrect.svg"),
  wolf: require("../games-folder/artist-mistakes/images/wolf.svg"),
  boarIncorrect: require("../games-folder/artist-mistakes/images/boarIncorrect.svg"),
  boar: require("../games-folder/artist-mistakes/images/boar.svg"),
  deerIncorrect: require("../games-folder/artist-mistakes/images/deerIncorrect.svg"),
  deer: require("../games-folder/artist-mistakes/images/deer.svg"),
  rabbitIncorrect: require("../games-folder/artist-mistakes/images/rabbitIncorrect.svg"),
  rabbit: require("../games-folder/artist-mistakes/images/rabbit.svg"),
  birdIncorrect: require("../games-folder/artist-mistakes/images/birdIncorrect.svg"),
  bird: require("../games-folder/artist-mistakes/images/bird.svg"),
  elkIncorrect: require("../games-folder/artist-mistakes/images/elkIncorrect.svg"),
  elk: require("../games-folder/artist-mistakes/images/elk.svg"),
},
sounds: {
  startInstruction: require("../games-folder/artist-mistakes/sounds/startInstruction.mp3"),
  backgroundSound: require("../games-folder/artist-mistakes/sounds/backgroundSound.mp3"),
  rightAnswer: require("../games-folder/artist-mistakes/sounds/rightAnswer.mp3"),
  finalSound: require("../games-folder/artist-mistakes/sounds/finalSound.mp3"),
},
},



"seasons": {
images: {
  autumnIncorrect: require("../games-folder/seasons-game/images/autumnIncorrect.svg"),
  autumn: require("../games-folder/seasons-game/images/autumn.svg"),
  springIncorrect: require("../games-folder/seasons-game/images/springIncorrect.png"),
  spring: require("../games-folder/seasons-game/images/vesna.svg"),
  rainIncorrect: require("../games-folder/seasons-game/images/rainIncorrect.svg"),
  rain: require("../games-folder/seasons-game/images/rain.svg"),
  gradenSpringIncorrect: require("../games-folder/seasons-game/images/gardenSpringIncorrect.svg"),
  gradenSpring: require("../games-folder/seasons-game/images/gardenSpring.svg"),
  birdOnTreeIncorrect: require("../games-folder/seasons-game/images/birdOnTreeIncorrect.svg"),
  birdOnTree: require("../games-folder/seasons-game/images/birdOnTree.svg"),
  cleanerIncorrect: require("../games-folder/seasons-game/images/cleanerIncorrect.svg"),
  cleaner: require("../games-folder/seasons-game/images/cleaner.svg"),
  winterGirlIncorrect: require("../games-folder/seasons-game/images/winterGirlIncorrect.svg"),
  winterGirl: require("../games-folder/seasons-game/images/winterGirl.svg"),
  gardenIncorrect: require("../games-folder/seasons-game/images/gardenIncorrect.svg"),
  garden: require("../games-folder/seasons-game/images/garden.svg"),
  boyAutumnIncorrect: require("../games-folder/seasons-game/images/boyAutumnIncorrect.svg"),
  boyAutumn: require("../games-folder/seasons-game/images/boyAutumn.svg"),
  boyOnIceIncorrect: require("../games-folder/seasons-game/images/boyOnIceIncorrect.svg"),
  boyOnIce: require("../games-folder/seasons-game/images/boyOnIce.svg"),
},
sounds: {
  startInstruction: require("../games-folder/seasons-game/sounds/startInstruction.mp3"),
  backgroundSound: require("../games-folder/seasons-game/sounds/backgroundSound.mp3"),
  rightAnswer: require("../games-folder/seasons-game/sounds/rightAnswer.mp3"),
  finalSound: require("../games-folder/seasons-game/sounds/finalSound.mp3"),
},
},

"animals-sounds": {
  images: {
    dynamic: require("../games-folder/animals-sounds/images/dynamic.svg"),
    wolf: require("../games-folder/animals-sounds/images/wolf.png"),
    dolphin: require("../games-folder/animals-sounds/images/dolphin.png"),
    snake: require("../games-folder/animals-sounds/images/snake.png"),
    whale: require("../games-folder/animals-sounds/images/whale.png"),
    goat: require("../games-folder/animals-sounds/images/goat.png"),
    cow: require("../games-folder/animals-sounds/images/cow.png"),
    cat: require("../games-folder/animals-sounds/images/cat.png"),
    horse: require("../games-folder/animals-sounds/images/horse.png"),
    frog: require("../games-folder/animals-sounds/images/frog.svg"),
    bear: require("../games-folder/animals-sounds/images/bear.png"),
    mouse: require("../games-folder/animals-sounds/images/mouse.png"),
    monkey: require("../games-folder/animals-sounds/images/monkey.png"),
    sheep: require("../games-folder/animals-sounds/images/sheep.png"),
    donkey: require("../games-folder/animals-sounds/images/donkey.png"),
    pig: require("../games-folder/animals-sounds/images/pig.png"),
    elephant: require("../games-folder/animals-sounds/images/elephant.png"),
    dog: require("../games-folder/animals-sounds/images/dog.png"),
    seal: require("../games-folder/animals-sounds/images/seal.png"),
  },
  sounds: {
    startInstruction: require("../games-folder/animals-sounds/sounds/startInstruction.mp3"),
    rightAnswer: require("../games-folder/animals-sounds/sounds/rightAnswer.mp3"),
    finalSound: require("../games-folder/animals-sounds/sounds/finalSound.mp3"),
    incorrectAnswer: require("../games-folder/animals-sounds/sounds/no.mp3"),
    bear: require("../games-folder/animals-sounds/sounds/bear.mp3"),
    cow: require("../games-folder/animals-sounds/sounds/cow.mp3"),
    dog: require("../games-folder/animals-sounds/sounds/dog.mp3"),
    dolphin: require("../games-folder/animals-sounds/sounds/dolphin.mp3"),
    donkey: require("../games-folder/animals-sounds/sounds/donkey.mp3"),
    elephant: require("../games-folder/animals-sounds/sounds/elephant.mp3"),
    frog: require("../games-folder/animals-sounds/sounds/frog.mp3"),
    goat: require("../games-folder/animals-sounds/sounds/goat.mp3"),
    horse: require("../games-folder/animals-sounds/sounds/horse.mp3"),
    monkey: require("../games-folder/animals-sounds/sounds/monkey.mp3"),
    mouse: require("../games-folder/animals-sounds/sounds/mouse.mp3"),
    pig: require("../games-folder/animals-sounds/sounds/pig.mp3"),
    seal: require("../games-folder/animals-sounds/sounds/seal.mp3"),
    sheep: require("../games-folder/animals-sounds/sounds/sheep.mp3"),
    snake: require("../games-folder/animals-sounds/sounds/snake.mp3"),
    whale: require("../games-folder/animals-sounds/sounds/whale.mp3"),
    wolf: require("../games-folder/animals-sounds/sounds/wolf.mp3"),
    cat: require("../games-folder/animals-sounds/sounds/cat.mp3"),
  },
},

"home-appliances": {
  images: {
    dynamic: require("../games-folder/home-appliances/images/dynamic.svg"),
    switchLight: require("../games-folder/home-appliances/images/switchLight.png"),
    girlTooth: require("../games-folder/home-appliances/images/girlTooth.png"),
    shower: require("../games-folder/home-appliances/images/shower.png"),
    bell: require("../games-folder/home-appliances/images/bell.png"),
    pot: require("../games-folder/home-appliances/images/pot.png"),
    keys: require("../games-folder/home-appliances/images/keys.png"),
    computer: require("../games-folder/home-appliances/images/computer.png"),
    waterTap: require("../games-folder/home-appliances/images/waterTap.png"),
    cupSpoon: require("../games-folder/home-appliances/images/cupSpoon.png"),
    bag: require("../games-folder/home-appliances/images/bag.png"),
    remoteController: require("../games-folder/home-appliances/images/remoteController.png"),
    vacuumCleaner: require("../games-folder/home-appliances/images/vacuumCleaner.png"),
    washMachine: require("../games-folder/home-appliances/images/washMachine.png"),
    tv: require("../games-folder/home-appliances/images/tv.png"),
    phone: require("../games-folder/home-appliances/images/phone.png"),
    kettle: require("../games-folder/home-appliances/images/kettle.png"),
    alarm: require("../games-folder/home-appliances/images/alarm.png"),
  },
  sounds: {
    startInstruction: require("../games-folder/home-appliances/sounds/startInstruction.mp3"),
    rightAnswer: require("../games-folder/home-appliances/sounds/rightAnswer.mp3"),
    finalSound: require("../games-folder/home-appliances/sounds/finalSound.mp3"),
    incorrectAnswer: require("../games-folder/home-appliances/sounds/no.mp3"),
    switchLight: require("../games-folder/home-appliances/sounds/switchLight.mp3"),
    girlTooth: require("../games-folder/home-appliances/sounds/girlTooth.mp3"),
    shower: require("../games-folder/home-appliances/sounds/shower.mp3"),
    bell: require("../games-folder/home-appliances/sounds/bell.mp3"),
    pot: require("../games-folder/home-appliances/sounds/pot.mp3"),
    keys: require("../games-folder/home-appliances/sounds/keys.mp3"),
    computer: require("../games-folder/home-appliances/sounds/computer.mp3"),
    waterTap: require("../games-folder/home-appliances/sounds/water.mp3"),
    cupSpoon: require("../games-folder/home-appliances/sounds/cupSpoon.mp3"),
    bag: require("../games-folder/home-appliances/sounds/bag.mp3"),
    remoteController: require("../games-folder/home-appliances/sounds/remoteController.mp3"),
    vacuumCleaner: require("../games-folder/home-appliances/sounds/vacuumCleaner.mp3"),
    washMachine: require("../games-folder/home-appliances/sounds/washMachine.mp3"),
    tv: require("../games-folder/home-appliances/sounds/tv.mp3"),
    phone: require("../games-folder/home-appliances/sounds/phone.mp3"),
    kettle: require("../games-folder/home-appliances/sounds/kettle.mp3"),
    alarm: require("../games-folder/home-appliances/sounds/alarm.mp3"),
  },
},
"countryside-sounds": {
  images: {
    dynamic: require("../games-folder/countryside/images/dynamic.svg"),
    gate: require("../games-folder/countryside/images/gate.png"),
    fire: require("../games-folder/countryside/images/fire.png"),
    forest: require("../games-folder/countryside/images/forest.png"),
    hammer: require("../games-folder/countryside/images/hammer.png"),
    doorOpen: require("../games-folder/countryside/images/doorOpen.png"),
    saw: require("../games-folder/countryside/images/saw.png"),
    doorKnock: require("../games-folder/countryside/images/doorKnock.png"),
    axe: require("../games-folder/countryside/images/axe.png"),
    hose: require("../games-folder/countryside/images/hose.png"),
    drill: require("../games-folder/countryside/images/drill.png"),
  },
  sounds: {
    startInstruction: require("../games-folder/countryside/sounds/startInstruction.mp3"),
    rightAnswer: require("../games-folder/countryside/sounds/rightAnswerSound.mp3"),
    finalSound: require("../games-folder/countryside/sounds/finalSound.mp3"),
    incorrectAnswer: require("../games-folder/countryside/sounds/no.mp3"),
    gate: require("../games-folder/countryside/sounds/gate.mp3"),
    fire: require("../games-folder/countryside/sounds/fire.mp3"),
    forest: require("../games-folder/countryside/sounds/forest.mp3"),
    hammer: require("../games-folder/countryside/sounds/hammer.mp3"),
    doorOpen: require("../games-folder/countryside/sounds/doorOpen.mp3"),
    saw: require("../games-folder/countryside/sounds/saw.mp3"),
    doorKnock: require("../games-folder/countryside/sounds/doorKnock.mp3"),
    axe: require("../games-folder/countryside/sounds/axe.mp3"),
    hose: require("../games-folder/countryside/sounds/hose.mp3"),
    drill: require("../games-folder/countryside/sounds/drill.mp3"),
  },
},

"street-sounds": {
  images: {
    bottle: require("../games-folder/street-sounds/images/bottle.png"),
    bell: require("../games-folder/street-sounds/images/bell.png"),
    peopleTalking: require("../games-folder/street-sounds/images/peopleTalking.png"),
    carSignal: require("../games-folder/street-sounds/images/carSignal.png"),
    ball: require("../games-folder/street-sounds/images/ball.png"),
    playground: require("../games-folder/street-sounds/images/playground.png"),
    purchases: require("../games-folder/street-sounds/images/purchases.png"),
    whistle: require("../games-folder/street-sounds/images/whistle.png"),
    siren: require("../games-folder/street-sounds/images/siren.png"),
    firework: require("../games-folder/street-sounds/images/firework.png"),
    go: require("../games-folder/street-sounds/images/go.png"),
  },
  sounds: {
    startInstruction: require("../games-folder/street-sounds/sounds/startInstruction.mp3"),
    rightAnswer: require("../games-folder/street-sounds/sounds/rightAnswer.mp3"),
    finalSound: require("../games-folder/street-sounds/sounds/finalSound.mp3"),
    incorrectAnswer: require("../games-folder/street-sounds/sounds/no.mp3"),
    bottle: require("../games-folder/street-sounds/sounds/bottle.mp3"),
    bell: require("../games-folder/street-sounds/sounds/bell.mp3"),
    peopleTalking: require("../games-folder/street-sounds/sounds/peopleTalking.mp3"),
    carSignal: require("../games-folder/street-sounds/sounds/carSignal.mp3"),
    ball: require("../games-folder/street-sounds/sounds/ball.mp3"),
    playground: require("../games-folder/street-sounds/sounds/playground.mp3"),
    purchases: require("../games-folder/street-sounds/sounds/purchases.mp3"),
    whistle: require("../games-folder/street-sounds/sounds/whistle.mp3"),
    siren: require("../games-folder/street-sounds/sounds/siren.mp3"),
    firework: require("../games-folder/street-sounds/sounds/firework.mp3"),
    go: require("../games-folder/street-sounds/sounds/go.mp3"),
  },
},

"transport-sounds": {
  images: {
    dynamic: require("../games-folder/transport-sounds/images/dynamic.svg"),
    helicopter: require("../games-folder/transport-sounds/images/helicopter.png"),
    sportcar: require("../games-folder/transport-sounds/images/sportcar.png"),
    boat: require("../games-folder/transport-sounds/images/boat.png"),
    car: require("../games-folder/transport-sounds/images/car.png"),
    metro: require("../games-folder/transport-sounds/images/metro.png"),
    moto: require("../games-folder/transport-sounds/images/moto.png"),
    steamship: require("../games-folder/transport-sounds/images/steamship.png"),
    train: require("../games-folder/transport-sounds/images/train.png"),
    aircraft: require("../games-folder/transport-sounds/images/aircraft.png"),
    tram: require("../games-folder/transport-sounds/images/tram.png"),
    trolleybus: require("../games-folder/transport-sounds/images/trolleybus.png"),
    elecTrain: require("../games-folder/transport-sounds/images/elecTrain.png"),
  },
  sounds: {
    startInstruction: require("../games-folder/transport-sounds/sounds/startInstruction.mp3"),
    rightAnswer: require("../games-folder/transport-sounds/sounds/rightAnswer.mp3"),
    finalSound: require("../games-folder/transport-sounds/sounds/finalSound.mp3"),
    incorrectAnswer: require("../games-folder/transport-sounds/sounds/no.mp3"),
    helicopter: require("../games-folder/transport-sounds/sounds/helicopter.mp3"),
    sportcar: require("../games-folder/transport-sounds/sounds/sportcar.mp3"),
    boat: require("../games-folder/transport-sounds/sounds/boat.mp3"),
    car: require("../games-folder/transport-sounds/sounds/car.mp3"),
    metro: require("../games-folder/transport-sounds/sounds/metro.mp3"),
    moto: require("../games-folder/transport-sounds/sounds/moto.mp3"),
    steamship: require("../games-folder/transport-sounds/sounds/steamship.mp3"),
    train: require("../games-folder/transport-sounds/sounds/train.mp3"),
    aircraft: require("../games-folder/transport-sounds/sounds/aircraft.mp3"),
    tram: require("../games-folder/transport-sounds/sounds/tram.mp3"),
    trolleybus: require("../games-folder/transport-sounds/sounds/trolleybus.mp3"),
    elecTrain: require("../games-folder/transport-sounds/sounds/elecTrain.mp3"),
  },
},

"nature-sounds": {
  images: {
    dynamic: require("../games-folder/nature-sounds/images/dynamic.svg"),
    wind: require("../games-folder/nature-sounds/images/wind.png"),
    waterfall: require("../games-folder/nature-sounds/images/waterfall.png"),
    wave: require("../games-folder/nature-sounds/images/wave.png"),
    hail: require("../games-folder/nature-sounds/images/hail.png"),
    thunder: require("../games-folder/nature-sounds/images/thunder.png"),
    rain: require("../games-folder/nature-sounds/images/rain.png"),
    puddle: require("../games-folder/nature-sounds/images/puddle.png"),
    sweep: require("../games-folder/nature-sounds/images/sweep.png"),
    river: require("../games-folder/nature-sounds/images/river.png"),
    icicle: require("../games-folder/nature-sounds/images/icicle.png"),
  },
  sounds: {
    startInstruction: require("../games-folder/nature-sounds/sounds/startInstruction.mp3"),
    rightAnswer: require("../games-folder/nature-sounds/sounds/rightAnswer.mp3"),
    finalSound: require("../games-folder/nature-sounds/sounds/finalSound.mp3"),
    incorrectAnswer: require("../games-folder/nature-sounds/sounds/no.mp3"),
    wind: require("../games-folder/nature-sounds/sounds/wind.mp3"),
    waterfall: require("../games-folder/nature-sounds/sounds/waterfall.mp3"),
    wave: require("../games-folder/nature-sounds/sounds/wave.mp3"),
    hail: require("../games-folder/nature-sounds/sounds/hail.mp3"),
    thunder: require("../games-folder/nature-sounds/sounds/thunder.mp3"),
    rain: require("../games-folder/nature-sounds/sounds/rain.mp3"),
    puddle: require("../games-folder/nature-sounds/sounds/puddle.mp3"),
    sweep: require("../games-folder/nature-sounds/sounds/sweep.mp3"),
    river: require("../games-folder/nature-sounds/sounds/river.mp3"),
    icicle: require("../games-folder/nature-sounds/sounds/icicle.mp3"),
  },
},

"emotions-sounds": {
  images: {
    dynamic: require("../games-folder/emotions-sounds/images/dynamic.svg"),
    pear: require("../games-folder/emotions-sounds/images/pear.png"),
    cough: require("../games-folder/emotions-sounds/images/cough.png"),
    sing: require("../games-folder/emotions-sounds/images/sing.png"),
    cry: require("../games-folder/emotions-sounds/images/cry.png"),
    spit: require("../games-folder/emotions-sounds/images/spit.png"),
    kiss: require("../games-folder/emotions-sounds/images/kiss.png"),
    arguing: require("../games-folder/emotions-sounds/images/arguing.png"),
    adultRofl: require("../games-folder/emotions-sounds/images/adultRofl.png"),
    rofl: require("../games-folder/emotions-sounds/images/rofl.png"),
    surprise: require("../games-folder/emotions-sounds/images/surprise.png"),
    clap: require("../games-folder/emotions-sounds/images/clap.png"),
    snore: require("../games-folder/emotions-sounds/images/snore.png"),
    slurp: require("../games-folder/emotions-sounds/images/slurp.png"),
    sneeze: require("../games-folder/emotions-sounds/images/sneeze.png"),
  },
  sounds: {
    startInstruction: require("../games-folder/emotions-sounds/sounds/startInstruction.mp3"),
    rightAnswer: require("../games-folder/emotions-sounds/sounds/rightAnswer.mp3"),
    finalSound: require("../games-folder/emotions-sounds/sounds/finalSound.mp3"),
    incorrectAnswer: require("../games-folder/emotions-sounds/sounds/no.mp3"),
    pear: require("../games-folder/emotions-sounds/sounds/pear.mp3"),
    cough: require("../games-folder/emotions-sounds/sounds/cough.mp3"),
    sing: require("../games-folder/emotions-sounds/sounds/sing.mp3"),
    cry: require("../games-folder/emotions-sounds/sounds/cry.mp3"),
    spit: require("../games-folder/emotions-sounds/sounds/spit.mp3"),
    kiss: require("../games-folder/emotions-sounds/sounds/kiss.mp3"),
    arguing: require("../games-folder/emotions-sounds/sounds/arguing.mp3"),
    adultRofl: require("../games-folder/emotions-sounds/sounds/adultRofl.mp3"),
    rofl: require("../games-folder/emotions-sounds/sounds/rofl.mp3"),
    surprise: require("../games-folder/emotions-sounds/sounds/surprise.mp3"),
    clap: require("../games-folder/emotions-sounds/sounds/clap.mp3"),
    snore: require("../games-folder/emotions-sounds/sounds/snore.mp3"),
    slurp: require("../games-folder/emotions-sounds/sounds/slurp.mp3"),
    sneeze: require("../games-folder/emotions-sounds/sounds/sneeze.mp3"),
  },
},

"birds-sounds": {
  images: {
    dynamic: require("../games-folder/birds-sounds/images/dynamic.svg"),
    crow: require("../games-folder/birds-sounds/images/crow.png"),
    pigeon: require("../games-folder/birds-sounds/images/pigeon.png"),
    goose: require("../games-folder/birds-sounds/images/goose.png"),
    woodpecker: require("../games-folder/birds-sounds/images/woodpecker.png"),
    turkey: require("../games-folder/birds-sounds/images/turkey.png"),
    chicken: require("../games-folder/birds-sounds/images/chicken.png"),
    eagle: require("../games-folder/birds-sounds/images/eagle.png"),
    cock: require("../games-folder/birds-sounds/images/cock.png"),
    parrot: require("../games-folder/birds-sounds/images/parrot.png"),
    owl: require("../games-folder/birds-sounds/images/owl.png"),
    nightingale: require("../games-folder/birds-sounds/images/nightingale.png"),
    duck: require("../games-folder/birds-sounds/images/duck.png"),
    smallChicken: require("../games-folder/birds-sounds/images/smallChicken.png"),
    gull: require("../games-folder/birds-sounds/images/gull.png"),
  },
  sounds: {
    startInstruction: require("../games-folder/birds-sounds/sounds/startInstruction.mp3"),
    rightAnswer: require("../games-folder/birds-sounds/sounds/rightAnswer.mp3"),
    finalSound: require("../games-folder/birds-sounds/sounds/finalSound.mp3"),
    incorrectAnswer: require("../games-folder/birds-sounds/sounds/no.mp3"),
    crow: require("../games-folder/birds-sounds/sounds/crow.mp3"),
    pigeon: require("../games-folder/birds-sounds/sounds/pigeon.mp3"),
    goose: require("../games-folder/birds-sounds/sounds/goose.mp3"),
    woodpecker: require("../games-folder/birds-sounds/sounds/woodpecker.mp3"),
    turkey: require("../games-folder/birds-sounds/sounds/turkey.mp3"),
    chicken: require("../games-folder/birds-sounds/sounds/chicken.mp3"),
    eagle: require("../games-folder/birds-sounds/sounds/eagle.mp3"),
    cock: require("../games-folder/birds-sounds/sounds/cock.mp3"),
    parrot: require("../games-folder/birds-sounds/sounds/parrot.mp3"),
    owl: require("../games-folder/birds-sounds/sounds/owl.mp3"),
    nightingale: require("../games-folder/birds-sounds/sounds/nightingale.mp3"),
    duck: require("../games-folder/birds-sounds/sounds/duck.mp3"),
    smallChicken: require("../games-folder/birds-sounds/sounds/smallChiken.mp3"),
    gull: require("../games-folder/birds-sounds/sounds/gull.mp3"),
  },
},

"musical-instruments-sounds": {
  images: {
    dynamic: require("../games-folder/musical-instruments-sounds/images/dynamic.svg"),
    harp: require("../games-folder/musical-instruments-sounds/images/harp.png"),
    balalaika: require("../games-folder/musical-instruments-sounds/images/balalaika.png"),
    dramMachine: require("../games-folder/musical-instruments-sounds/images/dramMachine.png"),
    tambourine: require("../games-folder/musical-instruments-sounds/images/tambourine.png"),
    harmonic: require("../games-folder/musical-instruments-sounds/images/harmonic.png"),
    guitar: require("../games-folder/musical-instruments-sounds/images/guitar.png"),
    flute: require("../games-folder/musical-instruments-sounds/images/flute.png"),
    bell: require("../games-folder/musical-instruments-sounds/images/bell.png"),
    xylophone: require("../games-folder/musical-instruments-sounds/images/xylophone.png"),
    maracas: require("../games-folder/musical-instruments-sounds/images/maracas.png"),
    piano: require("../games-folder/musical-instruments-sounds/images/piano.png"),
    violin: require("../games-folder/musical-instruments-sounds/images/violin.png"),
    triangle: require("../games-folder/musical-instruments-sounds/images/triangle.png"),
  },
  sounds: {
    startInstruction: require("../games-folder/musical-instruments-sounds/sounds/startInstruction.mp3"),
    rightAnswer: require("../games-folder/musical-instruments-sounds/sounds/rightAnswer.mp3"),
    finalSound: require("../games-folder/musical-instruments-sounds/sounds/finalSound.mp3"),
    incorrectAnswer: require("../games-folder/musical-instruments-sounds/sounds/no.mp3"),
    harp: require("../games-folder/musical-instruments-sounds/sounds/harp.mp3"),
    balalaika: require("../games-folder/musical-instruments-sounds/sounds/balalaika.mp3"),
    dramMachine: require("../games-folder/musical-instruments-sounds/sounds/dramMachine.mp3"),
    tambourine: require("../games-folder/musical-instruments-sounds/sounds/tambourine.mp3"),
    harmonic: require("../games-folder/musical-instruments-sounds/sounds/harmonic.mp3"),
    guitar: require("../games-folder/musical-instruments-sounds/sounds/guitar.mp3"),
    flute: require("../games-folder/musical-instruments-sounds/sounds/flute.mp3"),
    bell: require("../games-folder/musical-instruments-sounds/sounds/bell.mp3"),
    xylophone: require("../games-folder/musical-instruments-sounds/sounds/xylophone.mp3"),
    maracas: require("../games-folder/musical-instruments-sounds/sounds/maracas.mp3"),
    piano: require("../games-folder/musical-instruments-sounds/sounds/piano.mp3"),
    violin: require("../games-folder/musical-instruments-sounds/sounds/violin.mp3"),
    triangle: require("../games-folder/musical-instruments-sounds/sounds/triangle.mp3"),
  },
},

"insects-sounds": {
  images: {
    dynamic: require("../games-folder/insects-sounds/images/dynamic.svg"),
    juke: require("../games-folder/insects-sounds/images/juke.png"),
    mosquito: require("../games-folder/insects-sounds/images/mosquito.png"),
    grasshopper: require("../games-folder/insects-sounds/images/grasshopper.png"),
    fly: require("../games-folder/insects-sounds/images/fly.png"),
    bee: require("../games-folder/insects-sounds/images/bee.png"),
    dragonfly: require("../games-folder/insects-sounds/images/dragonfly.png"),
  },
  sounds: {
    startInstruction: require("../games-folder/insects-sounds/sounds/startInstruction.mp3"),
    rightAnswer: require("../games-folder/insects-sounds/sounds/rightAnswer.mp3"),
    finalSound: require("../games-folder/insects-sounds/sounds/finalSound.mp3"),
    incorrectAnswer: require("../games-folder/insects-sounds/sounds/no.mp3"),
    juke: require("../games-folder/insects-sounds/sounds/juke.mp3"),
    mosquito: require("../games-folder/insects-sounds/sounds/mosquito.mp3"),
    grasshopper: require("../games-folder/insects-sounds/sounds/grasshopper.mp3"),
    fly: require("../games-folder/insects-sounds/sounds/fly.mp3"),
    bee: require("../games-folder/insects-sounds/sounds/bee.mp3"),
    dragonfly: require("../games-folder/insects-sounds/sounds/dragonfly.mp3"),
  },
},

"math-figure-one": {
  images: {
    figureOne: require("../games-folder/math-one/images/1.png"),
    baloonThree: require("../games-folder/math-one/images/baloonThree.png"),
    baloonFive: require("../games-folder/math-one/images/baloonFive.png"),
    boxOpen: require("../games-folder/math-one/images/boxOpen.png"),
    bearInsideBox: require("../games-folder/math-one/images/bearInsideBox.png"),
    bearBig: require("../games-folder/math-one/images/bearBig.png"),
    bearSmall: require("../games-folder/math-one/images/bearSmall.png"),
    baloonNine: require("../games-folder/math-one/images/baloonNine.png"),
    baloonEight: require("../games-folder/math-one/images/baloonEight.png"),
    baloonSeven: require("../games-folder/math-one/images/baloonSeven.png"),
    baloonOneSecond: require("../games-folder/math-one/images/baloonOneSecond.png"),
    baloonOne: require("../games-folder/math-one/images/baloonOne.png"),
  },
  sounds: {
    startInstruction: require("../games-folder/math-one/sounds/startInstruction.mp3"),
    backgroundSound: require("../games-folder/math-one/sounds/backgroundSound.mp3"),
    incorrectAnswer: require("../games-folder/math-one/sounds/no.mp3"),
    taskZero: require("../games-folder/math-one/sounds/taskZero.mp3"),
    taskTwo: require("../games-folder/math-one/sounds/taskTwo.mp3"),
    taskThree: require("../games-folder/math-one/sounds/taskThree.mp3"),
    taskFour: require("../games-folder/math-one/sounds/taskFour.mp3"),
    baloonPopped: require("../games-folder/math-one/sounds/baloonPopped.mp3"),
    bearInBox: require("../games-folder/math-one/sounds/bearInBox.mp3"),
    praise: require("../games-folder/math-one/sounds/praise.mp3"),
    finalSound: require("../games-folder/math-one/sounds/finalSound.mp3"),
  },
},

"math-figure-two": {
  images: {
    figureTwo: require("../games-folder/math-two/images/2.png"),
    garageLarge: require("../games-folder/math-two/images/garageLarge.png"),
    garageSmall: require("../games-folder/math-two/images/garageSmall.png"),
    car: require("../games-folder/math-two/images/car.png"),
  },
  sounds: {
    startInstruction: require("../games-folder/math-two/sounds/startInstruction.mp3"),
    backgroundSound: require("../games-folder/math-two/sounds/backgroundSound.mp3"),
    taskZero: require("../games-folder/math-two/sounds/taskZero.mp3"),
    taskTwo: require("../games-folder/math-two/sounds/taskTwo.mp3"),
    taskThree: require("../games-folder/math-two/sounds/taskThree.mp3"),
    taskFour: require("../games-folder/math-two/sounds/taskFour.mp3"),
    carInGarage: require("../games-folder/math-two/sounds/carInGarage.mp3"),
    figureSound: require("../games-folder/math-two/sounds/figureSound.mp3"),
    praise: require("../games-folder/math-two/sounds/praise.mp3"),
  },
},

"math-figure-three": {
  images: {
    figureThree: require("../games-folder/math-three/images/3-1.png"),
    plate: require("../games-folder/math-three/images/plate.png"),
    eclairs: require("../games-folder/math-three/images/eclairs.png"),
    boyWithPlate: require("../games-folder/math-three/images/boyWithPlate.png"),
  },
  sounds: {
    startInstruction: require("../games-folder/math-three/sounds/startInstruction.mp3"),
    backgroundSound: require("../games-folder/math-three/sounds/backgroundSound.mp3"),
    taskZero: require("../games-folder/math-three/sounds/taskZero.mp3"),
    taskTwo: require("../games-folder/math-three/sounds/taskTwo.mp3"),
    taskThree: require("../games-folder/math-three/sounds/taskThree.mp3"),
    taskFour: require("../games-folder/math-three/sounds/taskFour.mp3"),
    ateEclair: require("../games-folder/math-three/sounds/ateEclair.mp3"),
    eclairOnPlate: require("../games-folder/math-three/sounds/eclairOnPlateSound.mp3"),
    praise: require("../games-folder/math-three/sounds/praise.mp3"),
    finalSound: require("../games-folder/math-three/sounds/finalSound.mp3"),
  },
},

"math-figure-four": {
  images: {
    figureFour: require("../games-folder/math-four/images/figureFour.png"),
    box: require("../games-folder/math-four/images/box.png"),
    flower: require("../games-folder/math-four/images/flower.png"),
    oneFlowerInBox: require("../games-folder/math-four/images/oneFlowerInBox.png"),
    twoFlowerInBox: require("../games-folder/math-four/images/twoFlowerInBox.png"),
    threeFlowerInBox: require("../games-folder/math-four/images/threeFlowerInBox.png"),
    fourFlowerInBox: require("../games-folder/math-four/images/fourFlowerInBox.png"),
    cubeOne: require("../games-folder/math-four/images/cube-1.png"),
    cubeTwo: require("../games-folder/math-four/images/cube-2.png"),
    cubeThree: require("../games-folder/math-four/images/cube-3.png"),
    cubeFour: require("../games-folder/math-four/images/cube-4.png"),
  },
  sounds: {
    startInstruction: require("../games-folder/math-four/sounds/startInstruction.mp3"),
    backgroundSound: require("../games-folder/math-four/sounds/backgroundSound.mp3"),
    taskZero: require("../games-folder/math-four/sounds/taskZero.mp3"),
    taskTwo: require("../games-folder/math-four/sounds/taskTwo.mp3"),
    taskThree: require("../games-folder/math-four/sounds/taskThree.mp3"),
    taskFour: require("../games-folder/math-four/sounds/taskFour.mp3"),
    cube: require("../games-folder/math-four/sounds/cube.mp3"),
    inBox: require("../games-folder/math-four/sounds/inBox.mp3"),
    finalSound: require("../games-folder/math-four/sounds/finalSound.mp3"),
    praise: require("../games-folder/math-four/sounds/praise.mp3"),
  },
},

"math-figure-five": {
  images: {
    figureFive: require("../games-folder/math-five/images/figureFive.png"),
    baloonOne: require("../games-folder/math-five/images/baloonOne.png"),
    baloonTwo: require("../games-folder/math-five/images/baloonTwo.png"),
    baloonThree: require("../games-folder/math-five/images/baloonThree.png"),
    baloonFour: require("../games-folder/math-five/images/baloonFour.png"),
    baloonFive: require("../games-folder/math-five/images/baloonFive.png"),
    baloonSix: require("../games-folder/math-five/images/baloonSix.png"),
    baloonSeven: require("../games-folder/math-five/images/baloonTwo.png"),
    baloonEight: require("../games-folder/math-five/images/baloonThree.png"),
    baloonNine: require("../games-folder/math-five/images/baloonOne.png"),
    baloonTen: require("../games-folder/math-five/images/baloonSix.png"),
    puzzleOne: require("../games-folder/math-five/images/1.png"),
    puzzleTwo: require("../games-folder/math-five/images/2.png"),
    puzzleThree: require("../games-folder/math-five/images/3.png"),
    puzzleFour: require("../games-folder/math-five/images/4.png"),
  },
  sounds: {
    startInstruction: require("../games-folder/math-five/sounds/startInstruction.mp3"),
    backgroundSound: require("../games-folder/math-five/sounds/backgroundSound.mp3"),
    taskZero: require("../games-folder/math-five/sounds/taskZero.mp3"),
    taskTwo: require("../games-folder/math-five/sounds/taskTwo.mp3"),
    taskThree: require("../games-folder/math-five/sounds/taskThree.mp3"),
    taskFour: require("../games-folder/math-five/sounds/taskFour.mp3"),
    baloon: require("../games-folder/math-five/sounds/baloon.mp3"),
    puzzle: require("../games-folder/math-five/sounds/puzzle.mp3"),
    finalSound: require("../games-folder/math-five/sounds/finalSound.mp3"),
    praise: require("../games-folder/math-five/sounds/praise.mp3"),
  },
},

"math-figure-six": {
  images: {
    figureSix: require("../games-folder/math-six/images/figureSix.png"),
    smallPyramid: require("../games-folder/math-six/images/smallPyramid.png"),
    pyramideBase: require("../games-folder/math-six/images/pyramideBase.png"),
    firstRing: require("../games-folder/math-six/images/firstRing.png"),
    secondRing: require("../games-folder/math-six/images/secondRing.png"),
    thirdRing: require("../games-folder/math-six/images/thirdRing.png"),
    fourthRing: require("../games-folder/math-six/images/fourthRing.png"),
    fifthRing: require("../games-folder/math-six/images/fifthRing.png"),
    sixthRing: require("../games-folder/math-six/images/sixthRing.png"),
    pyramideOneRing: require("../games-folder/math-six/images/1.png"),
    pyramideTwoRings: require("../games-folder/math-six/images/2.png"),
    pyramideThreeRings: require("../games-folder/math-six/images/3.png"),
    pyramideFourRings: require("../games-folder/math-six/images/4.png"),
    pyramideFiveRings: require("../games-folder/math-six/images/5.png"),
    pyramideSixRings: require("../games-folder/math-six/images/6.png"),
    railStation: require("../games-folder/math-six/images/railStation.png"),
    locomotive: require("../games-folder/math-six/images/locomotive.png"),
    wagonGrey: require("../games-folder/math-six/images/wagonGrey.png"),
    wagonColor: require("../games-folder/math-six/images/wagonColor.png"),
  },
  sounds: {
    startInstruction: require("../games-folder/math-six/sounds/startInstruction.mp3"),
    backgroundSound: require("../games-folder/math-six/sounds/backgroundSound.mp3"),
    taskZero: require("../games-folder/math-six/sounds/taskZero.mp3"),
    taskTwo: require("../games-folder/math-six/sounds/taskTwo.mp3"),
    taskThree: require("../games-folder/math-six/sounds/taskThree.mp3"),
    taskFour: require("../games-folder/math-six/sounds/taskFour.mp3"),
    ringRight: require("../games-folder/math-six/sounds/ringRight.mp3"),
    ringIncorrect: require("../games-folder/math-six/sounds/incorrectRing.mp3"),
    wagon: require("../games-folder/math-six/sounds/wagon.mp3"),
    finalSound: require("../games-folder/math-six/sounds/finalSound.mp3"),
    praise: require("../games-folder/math-six/sounds/praise.mp3"),
  },
},

"math-figure-seven": {
  images: {
    figureSeven: require("../games-folder/math-seven/images/figureSeven.png"),
    petal: require("../games-folder/math-seven/images/petal.png"),
    leftPetal: require("../games-folder/math-seven/images/leftPetal.png"),
    rightPetal: require("../games-folder/math-seven/images/rightPetal.png"),
    brich: require("../games-folder/math-seven/images/brich.png"),
    wallperBrich: require("../games-folder/math-seven/images/wallperBrich.png"),
    puzzleOne: require("../games-folder/math-seven/images/1.png"),
    puzzleTwo: require("../games-folder/math-seven/images/2.png"),
    puzzleThree: require("../games-folder/math-seven/images/3.png"),
    puzzleFour: require("../games-folder/math-seven/images/4.png"),
  },
  sounds: {
    startInstruction: require("../games-folder/math-seven/sounds/startInstruction.mp3"),
    backgroundSound: require("../games-folder/math-seven/sounds/backgroundSound.mp3"),
    taskZero: require("../games-folder/math-seven/sounds/taskZero.mp3"),
    taskTwo: require("../games-folder/math-seven/sounds/taskTwo.mp3"),
    taskThree: require("../games-folder/math-seven/sounds/taskThree.mp3"),
    taskFour: require("../games-folder/math-seven/sounds/taskFour.mp3"),
    puzzle: require("../games-folder/math-seven/sounds/puzzle.mp3"),
    leave: require("../games-folder/math-seven/sounds/leave.mp3"),
    finalSound: require("../games-folder/math-seven/sounds/finalSound.mp3"),
    praise: require("../games-folder/math-seven/sounds/praise.mp3"),
  },
},

"math-figure-eight": {
  images: {
    figureEight: require("../games-folder/math-eight/images/8 1.png"),
    bone: require("../games-folder/math-eight/images/bone.png"),
    dogWallper: require("../games-folder/math-eight/images/image 2 (1).png"),
    dog: require("../games-folder/math-eight/images/dog.png"),
    bowl: require("../games-folder/math-eight/images/bowl.png"),
    bowlOneBone: require("../games-folder/math-eight/images/bowlOneBone.png"),
    bowlTwoBones: require("../games-folder/math-eight/images/bowlTwoBones.png"),
    bowlThreeBones: require("../games-folder/math-eight/images/bowlThreeBones.png"),
    bowlFourBones: require("../games-folder/math-eight/images/bowlFourBones.png"),
    bowlFiveBones: require("../games-folder/math-eight/images/bowlFiveBones.png"),
    bowlSixBones: require("../games-folder/math-eight/images/bowlSixBones.png"),
    bowlSevenBones: require("../games-folder/math-eight/images/bowlSevenBones.png"),
    bowlEightBones: require("../games-folder/math-eight/images/bowlEightBones.png"),
    bubbleOne: require("../games-folder/math-eight/images/Ellipse 2.svg"),
    bubbleTwo: require("../games-folder/math-eight/images/Ellipse 3.svg"),
    bubbleThree: require("../games-folder/math-eight/images/Ellipse 4.svg"),
    bubbleFour: require("../games-folder/math-eight/images/Ellipse 5.svg"),
    bubbleFive: require("../games-folder/math-eight/images/Ellipse 6.svg"),
    bubbleSix: require("../games-folder/math-eight/images/Ellipse 7.svg"),
    bubbleSeven: require("../games-folder/math-eight/images/Ellipse 8.svg"),
    bubbleEight: require("../games-folder/math-eight/images/Ellipse 9.svg"),
    bubbleNine: require("../games-folder/math-eight/images/Ellipse 10.svg"),
  },
  sounds: {
    startInstruction: require("../games-folder/math-eight/sounds/startInstruction.mp3"),
    backgroundSound: require("../games-folder/math-eight/sounds/backgroundSound.mp3"),
    taskZero: require("../games-folder/math-eight/sounds/taskZero.mp3"),
    taskTwo: require("../games-folder/math-eight/sounds/taskTwo.mp3"),
    taskThree: require("../games-folder/math-eight/sounds/taskThree.mp3"),
    taskFour: require("../games-folder/math-eight/sounds/taskFour.mp3"),
    bubble: require("../games-folder/math-eight/sounds/bubble.mp3"),
    bone: require("../games-folder/math-eight/sounds/bone.mp3"),
    finalSound: require("../games-folder/math-eight/sounds/finalSound.mp3"),
    praise: require("../games-folder/math-eight/sounds/praise.mp3"),
  },
},

"math-figure-nine": {
  images: {
    figureNine: require("../games-folder/math-nine/images/figureNine.png"),
    candy: require("../games-folder/math-nine/images/candy.png"),
    shelf: require("../games-folder/math-nine/images/shelf.png"),
    puzzleOne: require("../games-folder/math-nine/images/puzzleOne.png"),
    puzzleTwo: require("../games-folder/math-nine/images/puzzleTwo.png"),
    puzzleThree: require("../games-folder/math-nine/images/puzzleThree.png"),
    puzzleFour: require("../games-folder/math-nine/images/puzzleFour.png"),
  },
  sounds: {
    startInstruction: require("../games-folder/math-nine/sounds/startInstruction.mp3"),
    backgroundSound: require("../games-folder/math-nine/sounds/backgroundSound.mp3"),
    taskZero: require("../games-folder/math-nine/sounds/taskZero.mp3"),
    taskTwo: require("../games-folder/math-nine/sounds/taskTwo.mp3"),
    taskThree: require("../games-folder/math-nine/sounds/taskThree.mp3"),
    taskFour: require("../games-folder/math-nine/sounds/taskFour.mp3"),
    puzzle: require("../games-folder/math-nine/sounds/puzzle.mp3"),
    candy: require("../games-folder/math-nine/sounds/candy.mp3"),
    finalSound: require("../games-folder/math-nine/sounds/finalSound.mp3"),
    praise: require("../games-folder/math-nine/sounds/praise.mp3"),
  },
},

"math-figure-ten": {
  images: {
    figureTen: require("../games-folder/math-ten/images/figureTen.png"),
    fishOne: require("../games-folder/math-ten/images/fishOne.png"),
    fishTwo: require("../games-folder/math-ten/images/fishTwo.png"),
    fishThree: require("../games-folder/math-ten/images/fishThree.png"),
    fishFour: require("../games-folder/math-ten/images/fishFour.png"),
    fishFive: require("../games-folder/math-ten/images/5.png"),
    fishSix: require("../games-folder/math-ten/images/fishSix.png"),
    fishSeven: require("../games-folder/math-ten/images/fishSeven.png"),
    fishEight: require("../games-folder/math-ten/images/fishEight.png"),
    fishNine: require("../games-folder/math-ten/images/fishNine.png"),
    fishTen: require("../games-folder/math-ten/images/fishTen.png"),
    aquarium: require("../games-folder/math-ten/images/aquarium.png"),
    baloonOne: require("../games-folder/math-ten/images/baloonOne.png"),
    baloonTwo: require("../games-folder/math-ten/images/baloonTwo.png"),
    baloonSeven: require("../games-folder/math-ten/images/baloonSeven.png"),
    baloonTen: require("../games-folder/math-ten/images/baloonTen.png"),
    baloonTenSecond: require("../games-folder/math-ten/images/baloonTenSecond.png"),
    baloonTenThird: require("../games-folder/math-ten/images/baloonTenThird.png"),
    baloonFifteen: require("../games-folder/math-ten/images/baloonFifteen.png"),
  },
  sounds: {
    startInstruction: require("../games-folder/math-ten/sounds/startInstruction.mp3"),
    backgroundSound: require("../games-folder/math-ten/sounds/backgroundSound.mp3"),
    taskZero: require("../games-folder/math-ten/sounds/taskZero.mp3"),
    taskTwo: require("../games-folder/math-ten/sounds/taskTwo.mp3"),
    taskThree: require("../games-folder/math-ten/sounds/taskThree.mp3"),
    taskFour: require("../games-folder/math-ten/sounds/taskFour.mp3"),
    incorrectBaloon: require("../games-folder/math-ten/sounds/no.mp3"),
    fish: require("../games-folder/math-ten/sounds/fish.mp3"),
    baloonPop: require("../games-folder/math-ten/sounds/baloon.mp3"),
    finalSound: require("../games-folder/math-ten/sounds/finalSound.mp3"),
    praise: require("../games-folder/math-ten/sounds/praise.mp3"),
  },
},

"angry-goat": {
  images: {
    wallper: require("../games-folder/angry-goat/images/wallper.png"),
    goat: require("../games-folder/angry-goat/images/goat.png"),
    goatRearUp: require("../games-folder/angry-goat/images/goatRearUp.png"),
    ba: require("../games-folder/angry-goat/images/ba.png"),
    bu: require("../games-folder/angry-goat/images/bu.png"),
    bo: require("../games-folder/angry-goat/images/bo.png"),
    by: require("../games-folder/angry-goat/images/by.png"),
    be: require("../games-folder/angry-goat/images/be.png"),
    ab: require("../games-folder/angry-goat/images/ab.png"),
    ob: require("../games-folder/angry-goat/images/ob.png"),
    ib: require("../games-folder/angry-goat/images/ib.png"),
    eb: require("../games-folder/angry-goat/images/eb.png"),
  },
  sounds: {
    startInstruction: require("../games-folder/angry-goat/sounds/startInstruction.mp3"),
    backgroundSound: require("../games-folder/angry-goat/sounds/backgroundSound.mp3"),
    firstStone: require("../games-folder/angry-goat/sounds/firstStone.mp3"),
    nextStone: require("../games-folder/angry-goat/sounds/nextStone.mp3"),
    praise: require("../games-folder/angry-goat/sounds/praise.mp3"),
    shoot: require("../games-folder/angry-goat/sounds/shoot.mp3"),
  },
},

"magic-lamps": {
  images: {
    wallper: require("../games-folder/magic-lamps/images/wallper.png"),
    serviceMan: require("../games-folder/magic-lamps/images/serviceMan.png"),
    lampWithLight: require("../games-folder/magic-lamps/images/lampWithLight.png"),
    blackLamp: require("../games-folder/magic-lamps/images/blackLamp.png"),
    smallBoat: require("../games-folder/magic-lamps/images/smallBoat.png"),
    boat: require("../games-folder/magic-lamps/images/boat.png"),
    smallFlippers: require("../games-folder/magic-lamps/images/smallFlippers.png"),
    flippers: require("../games-folder/magic-lamps/images/flippers.png"),
    smallVarnish: require("../games-folder/magic-lamps/images/smallVarnish.png"),
    varnish: require("../games-folder/magic-lamps/images/varnish.png"),
    smallSwallow: require("../games-folder/magic-lamps/images/smallSwallow.png"),
    swallow: require("../games-folder/magic-lamps/images/swallow.png"),
    smallShovel: require("../games-folder/magic-lamps/images/smallShovel.png"),
    shovel: require("../games-folder/magic-lamps/images/shovel.png"),
    smallFlower: require("../games-folder/magic-lamps/images/smallFlower.png"),
    flower: require("../games-folder/magic-lamps/images/flower.png"),
    smallOnion: require("../games-folder/magic-lamps/images/smallOnion.png"),
    onion: require("../games-folder/magic-lamps/images/onion.png"),
    smallRays: require("../games-folder/magic-lamps/images/smallRays.png"),
    rays: require("../games-folder/magic-lamps/images/rays.png"),
    smallZoom: require("../games-folder/magic-lamps/images/smallZoom.png"),
    zoom: require("../games-folder/magic-lamps/images/zoom.png"),
    smallBench: require("../games-folder/magic-lamps/images/smallBench.png"),
    bench: require("../games-folder/magic-lamps/images/bench.png"),
    smallMoon: require("../games-folder/magic-lamps/images/smallMoon.png"),
    moon: require("../games-folder/magic-lamps/images/moon.png"),
    smallPaws: require("../games-folder/magic-lamps/images/smallPaws.png"),
    paws: require("../games-folder/magic-lamps/images/paws.png"),
    smallLamp: require("../games-folder/magic-lamps/images/smallLamp.png"),
    lamp: require("../games-folder/magic-lamps/images/lamp.png"),
  },
  sounds: {
    startInstruction: require("../games-folder/magic-lamps/sounds/startInstruction.mp3"),
    secondInstruction: require("../games-folder/magic-lamps/sounds/secondInstruction.mp3"),
    backgroundSound: require("../games-folder/magic-lamps/sounds/backgroundSound.mp3"),
    lightOn: require("../games-folder/magic-lamps/sounds/lightOn.mp3"),
    praise: require("../games-folder/magic-lamps/sounds/praise.mp3"),
    finalSound: require("../games-folder/magic-lamps/sounds/finalSound.mp3"),
  },
},
"help-bumblebee-eat": {
  images: {
    wallper: require("../games-folder/bumblebee/images/wallper.png"),
    flowerOne: require("../games-folder/bumblebee/images/image 11.png"),
    flowerTwo: require("../games-folder/bumblebee/images/image 7.png"),
    flowerThree: require("../games-folder/bumblebee/images/image 8.png"),
    ishUsh: require("../games-folder/bumblebee/images/ishUsh.svg"),
    isha: require("../games-folder/bumblebee/images/isha.svg"),
    oshSha: require("../games-folder/bumblebee/images/oshSha.svg"),
    ushSha: require("../games-folder/bumblebee/images/ushSha.svg"),
    shaSha: require("../games-folder/bumblebee/images/shaSha.svg"),
    shaShu: require("../games-folder/bumblebee/images/shaShu.svg"),
    sheSha: require("../games-folder/bumblebee/images/sheSha.svg"),
    shoShi: require("../games-folder/bumblebee/images/shoShi.svg"),
    sho: require("../games-folder/bumblebee/images/sho.svg"),
    shuSha: require("../games-folder/bumblebee/images/shuSha.svg"),
    shu: require("../games-folder/bumblebee/images/shu.svg"),
    asha: require("../games-folder/bumblebee/images/asha.svg"),
  },
  sounds: {
    startInstruction: require("../games-folder/bumblebee/sounds/startInstruction.mp3"),
    backgroundSound: require("../games-folder/bumblebee/sounds/backgroundSound.mp3"),
    bee: require("../games-folder/bumblebee/sounds/bee.mp3"),
    praise: require("../games-folder/bumblebee/sounds/praise.mp3"),
  },
},
"what-in-snakes-hole": {
  images: {
    wallper: require("../games-folder/snakes-hole/images/wallper.png"),
    labyrinth: require("../games-folder/snakes-hole/images/labyrinth.svg"),
    snake: require("../games-folder/snakes-hole/images/snake.png"),
    flag: require("../games-folder/snakes-hole/images/flag.svg"),
    ball: require("../games-folder/snakes-hole/images/ball.png"),
    tent: require("../games-folder/snakes-hole/images/tent.png"),
    tire: require("../games-folder/snakes-hole/images/tire.png"),
    cone: require("../games-folder/snakes-hole/images/cone.png"),
    box: require("../games-folder/snakes-hole/images/box.png"),
    helmet: require("../games-folder/snakes-hole/images/helmet.png"),
    hat: require("../games-folder/snakes-hole/images/hat.png"),
    chocolate: require("../games-folder/snakes-hole/images/chocolate.png"),
    shorts: require("../games-folder/snakes-hole/images/shorts.png"),
    trousers: require("../games-folder/snakes-hole/images/trousers.png"),
    courtains: require("../games-folder/snakes-hole/images/courtains.png"),
    furCoats: require("../games-folder/snakes-hole/images/furCoats.png"),
    clown: require("../games-folder/snakes-hole/images/clown.png"),
    six: require("../games-folder/snakes-hole/images/6.png"),
  },
  sounds: {
    startInstruction: require("../games-folder/snakes-hole/sounds/startInstruction.mp3"),
    background: require("../games-folder/snakes-hole/sounds/backgroundSound.mp3"),
    item: require("../games-folder/snakes-hole/sounds/item.mp3"),
    final: require("../games-folder/snakes-hole/sounds/finalSound.mp3"),
    praise: require("../games-folder/snakes-hole/sounds/praise.mp3"),
  },
},

"dating-with-zhuzha": {
  images: {

    wallper: require("../games-folder/dating-with-zhuzha/images/wallper.png"),
    zhuzha: require("../games-folder/dating-with-zhuzha/images/zhuzha.png"),
    flowerOne: require("../games-folder/dating-with-zhuzha/images/flowerOne.png"),
    flowerTwo: require("../games-folder/dating-with-zhuzha/images/flowerTwo.png"),
    flowerThree: require("../games-folder/dating-with-zhuzha/images/flowerThree.png"),
    cafe: require("../games-folder/dating-with-zhuzha/images/cafe.png"),
    azh: require("../games-folder/dating-with-zhuzha/images/azh.svg"),
    azhAzh: require("../games-folder/dating-with-zhuzha/images/azhAzh.svg"),
    zha: require("../games-folder/dating-with-zhuzha/images/zha.svg"),
    zhi: require("../games-folder/dating-with-zhuzha/images/zhi.svg"),
    zhiZha: require("../games-folder/dating-with-zhuzha/images/zhiZha.svg"),
    zho: require("../games-folder/dating-with-zhuzha/images/zho.svg"),
    zhuZhu: require("../games-folder/dating-with-zhuzha/images/zhuZhu.svg"),
    zhuIzh: require("../games-folder/dating-with-zhuzha/images/zhuIzh.svg"),
    izh: require("../games-folder/dating-with-zhuzha/images/izh.svg"),
    izhZhi: require("../games-folder/dating-with-zhuzha/images/izhZhi.svg"),
    ozhZha: require("../games-folder/dating-with-zhuzha/images/ozhZha.svg"),
    ozhUzh: require("../games-folder/dating-with-zhuzha/images/ozhUzh.svg"),
    ezh: require("../games-folder/dating-with-zhuzha/images/ezh.svg"),
    zhu: require("../games-folder/dating-with-zhuzha/images/zhu.svg"),
    
    wallperTwo: require("../games-folder/dating-with-zhuzha/images/wallperTwo.png"),
    girl: require("../games-folder/dating-with-zhuzha/images/girl.png"),
    gift: require("../games-folder/dating-with-zhuzha/images/gift.png"),
    table: require("../games-folder/dating-with-zhuzha/images/table.png"),
    soundZh: require("../games-folder/dating-with-zhuzha/images/soundZh.svg"),
  },
  sounds: {

    startInstruction: require("../games-folder/dating-with-zhuzha/sounds/startInstruction.mp3"),
    background: require("../games-folder/dating-with-zhuzha/sounds/backgroundSound.mp3"),
    bee: require("../games-folder/dating-with-zhuzha/sounds/bee.mp3"),
  
    beeZhh: require("../games-folder/dating-with-zhuzha/sounds/beeZhh.mp3"),
    gift: require("../games-folder/dating-with-zhuzha/sounds/gift.mp3"),
    praise: require("../games-folder/dating-with-zhuzha/sounds/praise.mp3"),
  },
},


"acorn-with-surprise": {
  images: {
    wallper: require("../games-folder/acorn-with-surprise/images/wallper.png"),
    squirell: require("../games-folder/acorn-with-surprise/images/squirell.png"),
    hand: require("../games-folder/acorn-with-surprise/images/hand.png"),
    acorn: require("../games-folder/acorn-with-surprise/images/acorn.png"),
    crashedAcorn: require("../games-folder/acorn-with-surprise/images/crashedAcorn.png"),
    bug: require("../games-folder/acorn-with-surprise/images/bug.png"),
    toad: require("../games-folder/acorn-with-surprise/images/toad.png"),
    jasmine: require("../games-folder/acorn-with-surprise/images/jasmine.png"),
    juggler: require("../games-folder/acorn-with-surprise/images/juggler.png"),
    crane: require("../games-folder/acorn-with-surprise/images/crane.png"),
    jelly: require("../games-folder/acorn-with-surprise/images/jelly.png"),
    jetton: require("../games-folder/acorn-with-surprise/images/jetton.png"),
    coal: require("../games-folder/acorn-with-surprise/images/coal.png"),
    bigBug: require("../games-folder/acorn-with-surprise/images/bigBug.png"),
    bigToad: require("../games-folder/acorn-with-surprise/images/bigToad.png"),
    bigJasmine: require("../games-folder/acorn-with-surprise/images/bigJasmine.png"),
    bigJuggler: require("../games-folder/acorn-with-surprise/images/bigJuggler.png"),
    bigCrane: require("../games-folder/acorn-with-surprise/images/bigCrane.png"),
    bigJelly: require("../games-folder/acorn-with-surprise/images/bigJelly.png"),
    bigJetton: require("../games-folder/acorn-with-surprise/images/bigJetton.png"),
    bigCoal: require("../games-folder/acorn-with-surprise/images/bigCoal.png"),
  },
  sounds: {
    startInstruction: require("../games-folder/acorn-with-surprise/sounds/startInstruction.mp3"),
    background: require("../games-folder/acorn-with-surprise/sounds/backgroundSound.mp3"),
    nuts: require("../games-folder/acorn-with-surprise/sounds/nuts.mp3"),
    praise: require("../games-folder/acorn-with-surprise/sounds/praise.mp3"),
  },
},
"picking-fruits": {
  images: {
    trees: require("../games-folder/picking-fruits/images/trees.png"),
    boxFront: require("../games-folder/picking-fruits/images/boxFront.png"),
    boxBack: require("../games-folder/picking-fruits/images/boxBack.png"),
    as: require("../games-folder/picking-fruits/images/as.png"),
    isYz: require("../games-folder/picking-fruits/images/isYz.png"),
    is: require("../games-folder/picking-fruits/images/is.png"),
    os: require("../games-folder/picking-fruits/images/os.png"),
    saSy: require("../games-folder/picking-fruits/images/saSy.png"),
    sa: require("../games-folder/picking-fruits/images/sa.png"),
    so: require("../games-folder/picking-fruits/images/so.png"),
    su: require("../games-folder/picking-fruits/images/su.png"),
    suOs: require("../games-folder/picking-fruits/images/suOs.png"),
    sy: require("../games-folder/picking-fruits/images/sy.png"),
    sySo: require("../games-folder/picking-fruits/images/sySo.png"),
    usSy: require("../games-folder/picking-fruits/images/usSy.png"),
    us: require("../games-folder/picking-fruits/images/us.png"),
    esas: require("../games-folder/picking-fruits/images/esas.png"),
  },
  sounds: {
    startInstruction: require("../games-folder/picking-fruits/sounds/startInstruction.mp3"),
    background: require("../games-folder/picking-fruits/sounds/backgroundSound.mp3"),
    basket: require("../games-folder/picking-fruits/sounds/basket.mp3"),
    fruits: require("../games-folder/picking-fruits/sounds/fruit.mp3"),
    final: require("../games-folder/picking-fruits/sounds/finalSound.mp3"),
    praise: require("../games-folder/picking-fruits/sounds/praise.mp3"),
  },
},


  "what-owl-found-in-forest": {
    images: {
      wallper: require("../games-folder/what-owl-found-in-forest/images/wallper.png"),
      owl: require("../games-folder/what-owl-found-in-forest/images/owl.png"),
      unactiveWings: require("../games-folder/what-owl-found-in-forest/images/unactiveWings.png"),
      leftWing: require("../games-folder/what-owl-found-in-forest/images/leftWing.png"),
      rightWing: require("../games-folder/what-owl-found-in-forest/images/rightWing.png"),
      closedEyes: require("../games-folder/what-owl-found-in-forest/images/closedEyes.png"),
      salad: require("../games-folder/what-owl-found-in-forest/images/salad.png"),
      samovar: require("../games-folder/what-owl-found-in-forest/images/samovar.png"),
      aircraft: require("../games-folder/what-owl-found-in-forest/images/aircraft.png"),
      sled: require("../games-folder/what-owl-found-in-forest/images/sled.png"),
      som: require("../games-folder/what-owl-found-in-forest/images/som.png"),
      sausage: require("../games-folder/what-owl-found-in-forest/images/sausage.png"),
      box: require("../games-folder/what-owl-found-in-forest/images/box.png"),
      cracker: require("../games-folder/what-owl-found-in-forest/images/cracker.png"),
      cheese: require("../games-folder/what-owl-found-in-forest/images/cheese.png"),
      pacifier: require("../games-folder/what-owl-found-in-forest/images/pacifier.png"),
    },
    sounds: {
      startInstructionSound: require("../games-folder/what-owl-found-in-forest/sounds/startInstruction.mp3"),
      backgroundSound: require("../games-folder/what-owl-found-in-forest/sounds/backgroundSound.mp3"),
      flySound: require("../games-folder/what-owl-found-in-forest/sounds/fly.mp3"),
      praiseSound: require("../games-folder/what-owl-found-in-forest/sounds/praise.mp3"),
    },
  },

  "studying-circle": {
  images: {
    circle: require("../games-folder/studying-circle/images/circle.png"),
    circleTwo: require("../games-folder/studying-circle/images/circleTwo.svg"),
    circleThree: require("../games-folder/studying-circle/images/circleThree.svg"),
    rectangle: require("../games-folder/studying-circle/images/rectangle.svg"),
    rectangleTwo: require("../games-folder/studying-circle/images/rectangleTwo.svg"),
    squareOne: require("../games-folder/studying-circle/images/squareOne.svg"),
    squareTwo: require("../games-folder/studying-circle/images/squareTwo.svg"),
    squareThree: require("../games-folder/studying-circle/images/squareThree.svg"),
    rhombus: require("../games-folder/studying-circle/images/rhombus.svg"),
    ball: require("../games-folder/studying-circle/images/ball.png"),
    apple: require("../games-folder/studying-circle/images/apple.png"),
    clock: require("../games-folder/studying-circle/images/clock.png"),
    egg: require("../games-folder/studying-circle/images/egg.png"),
    tree: require("../games-folder/studying-circle/images/tree.svg"),
    tv: require("../games-folder/studying-circle/images/tv.png"),
    window: require("../games-folder/studying-circle/images/window.png"),
    greenBusa: require("../games-folder/studying-circle/images/greenBusa.png"),
    redBusa: require("../games-folder/studying-circle/images/redBusa.png"),
    redSquareBusa: require("../games-folder/studying-circle/images/redSquareBusa.png"),
    yellowBusa: require("../games-folder/studying-circle/images/yellowBusa.png"),
    yellowSquareBusa: require("../games-folder/studying-circle/images/yellowSquareBusa.png"),
    beadThread: require("../games-folder/studying-circle/images/beadThread.svg"),
    wallper: require("../games-folder/studying-circle/images/wallper.png"),
    snowBallOne: require("../games-folder/studying-circle/images/snowballOne.svg"),
    snowBallTwo: require("../games-folder/studying-circle/images/snowballTwo.svg"),
    snowBallThree: require("../games-folder/studying-circle/images/snowballThree.svg"),
    snowman: require("../games-folder/studying-circle/images/snowman.svg"),
  },
  sounds: {
    backgroundSound: require("../games-folder/studying-circle/sounds/backgroundSound.mp3"),
    incorrectAnswerSound: require("../games-folder/studying-circle/sounds/no.mp3"),
    taskZeroSound: require("../games-folder/studying-circle/sounds/taskZero.mp3"),
    taskTwoSound: require("../games-folder/studying-circle/sounds/taskTwo.mp3"),
    taskThreeSound: require("../games-folder/studying-circle/sounds/taskThree.mp3"),
    taskFourSound: require("../games-folder/studying-circle/sounds/taskFour.mp3"),
    taskFiveSound: require("../games-folder/studying-circle/sounds/taskFive.mp3"),
    rightCircleSound: require("../games-folder/studying-circle/sounds/rightCircle.mp3"),
    busaOneSound: require("../games-folder/studying-circle/sounds/busaOne.mp3"),
    busaTwoSound: require("../games-folder/studying-circle/sounds/busaTwo.mp3"),
    busaThreeSound: require("../games-folder/studying-circle/sounds/busaThree.mp3"),
    busaFourSound: require("../games-folder/studying-circle/sounds/busaFour.mp3"),
    busesSound: require("../games-folder/studying-circle/sounds/buses.mp3"),
    praiseSound: require("../games-folder/studying-circle/sounds/praise.mp3"),
    finalSound: require("../games-folder/studying-circle/sounds/finalSound.mp3"),
  },
},


"studying-square": {
  images: {
    square: require("../games-folder/studying-square/images/square.png"),
    pyramide: require("../games-folder/studying-square/images/pyramide.png"),
    greenCube: require("../games-folder/studying-square/images/greenCube.png"),
    yellowCube: require("../games-folder/studying-square/images/yellowCube.png"),
    redCube: require("../games-folder/studying-square/images/redCube.png"),
    camera: require("../games-folder/studying-square/images/camera.png"),
    chess: require("../games-folder/studying-square/images/chess.png"),
    ladybird: require("../games-folder/studying-square/images/ladybird.png"),
    melon: require("../games-folder/studying-square/images/melon.png"),
    pie: require("../games-folder/studying-square/images/pie.png"),
    pillow: require("../games-folder/studying-square/images/pillow.png"),
    pictureOne: require("../games-folder/studying-square/images/catOne.png"),
    pictureTwo: require("../games-folder/studying-square/images/catTwo.png"),
    pictureThree: require("../games-folder/studying-square/images/catThree.png"),
    pictureFour: require("../games-folder/studying-square/images/catFour.png"),
    wallper: require("../games-folder/studying-square/images/wallper.png"),
    table: require("../games-folder/studying-square/images/table.png"),
    aquarium: require("../games-folder/studying-square/images/aquarium.png"),
    picture: require("../games-folder/studying-square/images/picture.png")
  },
  sounds: {
    backgroundSound: require("../games-folder/studying-square/sounds/backgroundSound.mp3"),
    incorrectAnswerSound: require("../games-folder/studying-square/sounds/no.mp3"),
    correctAnswerSound: require("../games-folder/studying-square/sounds/correctAnswer.mp3"),
    taskZeroSound: require("../games-folder/studying-square/sounds/taskZero.mp3"),
    taskTwoSound: require("../games-folder/studying-square/sounds/taskTwo.mp3"),
    taskThreeSound: require("../games-folder/studying-square/sounds/taskThree.mp3"),
    taskFourSound: require("../games-folder/studying-square/sounds/taskFour.mp3"),
    cubeOneSound: require("../games-folder/studying-square/sounds/cubeOne.mp3"),
    cubeTwoSound: require("../games-folder/studying-square/sounds/cubeTwo.mp3"),
    puzzleSound: require("../games-folder/studying-square/sounds/puzzle.mp3"),
    praiseSound: require("../games-folder/studying-square/sounds/praise.mp3")
  }
},

"studying-triangle": {
  images: {
    triangle: require("../games-folder/studying-triangle/images/triangle.png"),
    circleOne: require("../games-folder/studying-triangle/images/circleOne.svg"),
    circleTwo: require("../games-folder/studying-triangle/images/circleTwo.svg"),
    ellipse: require("../games-folder/studying-triangle/images/ellipse.svg"),
    rhombOne: require("../games-folder/studying-triangle/images/rhombOne.svg"),
    rhombTwo: require("../games-folder/studying-triangle/images/rhombTwo.svg"),
    rhombThree: require("../games-folder/studying-triangle/images/rhombThree.svg"),
    rhombFour: require("../games-folder/studying-triangle/images/rhombFour.svg"),
    squareOne: require("../games-folder/studying-triangle/images/squareOne.svg"),
    squareTwo: require("../games-folder/studying-triangle/images/squareTwo.svg"),
    squareThree: require("../games-folder/studying-triangle/images/squareThree.svg"),
    squareFour: require("../games-folder/studying-triangle/images/squareFour.svg"),
    triangleOne: require("../games-folder/studying-triangle/images/triangleOne.svg"),
    triangleTwo: require("../games-folder/studying-triangle/images/triangleTwo.svg"),
    triangleThree: require("../games-folder/studying-triangle/images/triangleThree.svg"),
    cheese: require("../games-folder/studying-triangle/images/cheese.png"),
    bagel: require("../games-folder/studying-triangle/images/bagel.png"),
    box: require("../games-folder/studying-triangle/images/box.png"),
    fish: require("../games-folder/studying-triangle/images/fish.png"),
    pyramide: require("../games-folder/studying-triangle/images/pyramide.png"),
    table: require("../games-folder/studying-triangle/images/table.png"),
    tree: require("../games-folder/studying-triangle/images/tree.png"),
    window: require("../games-folder/studying-triangle/images/window.png"),
    greenHouse: require("../games-folder/studying-triangle/images/greenHouse.png"),
    pinkHouse: require("../games-folder/studying-triangle/images/pinkHouse.png"),
    blueHouse: require("../games-folder/studying-triangle/images/blueHouse.png"),
    postCircle: require("../games-folder/studying-triangle/images/postCircle.svg"),
    postSquare: require("../games-folder/studying-triangle/images/postSquare.svg"),
    postTriangle: require("../games-folder/studying-triangle/images/postTriangle.svg"),
    boatDown: require("../games-folder/studying-triangle/images/boatDown.png"),
    boatUp: require("../games-folder/studying-triangle/images/boatUp.png"),
    hangerDown: require("../games-folder/studying-triangle/images/hangerDown.svg"),
    hangerUp: require("../games-folder/studying-triangle/images/hangerUp.svg"),
    houseDown: require("../games-folder/studying-triangle/images/houseDown.png"),
    houseUp: require("../games-folder/studying-triangle/images/houseUp.png"),
    iceDown: require("../games-folder/studying-triangle/images/iceDown.png"),
    iceUp: require("../games-folder/studying-triangle/images/iceUp.png"),
    treeDown: require("../games-folder/studying-triangle/images/treeDown.svg"),
    treeUp: require("../games-folder/studying-triangle/images/treeUp.svg")
  },
  sounds: {
    backgroundSound: require("../games-folder/studying-triangle/sounds/backgroundSound.mp3"),
    incorrectAnswerSound: require("../games-folder/studying-triangle/sounds/no.mp3"),
    correctAnswerSound: require("../games-folder/studying-triangle/sounds/correct.mp3"),
    correctAnswerSoundTwo: require("../games-folder/studying-triangle/sounds/correctTwo.mp3"),
    taskZeroSound: require("../games-folder/studying-triangle/sounds/taskZero.mp3"),
    taskTwoSound: require("../games-folder/studying-triangle/sounds/taskTwo.mp3"),
    taskThreeSound: require("../games-folder/studying-triangle/sounds/taskThree.mp3"),
    taskFourSound: require("../games-folder/studying-triangle/sounds/taskFour.mp3"),
    taskFiveSound: require("../games-folder/studying-triangle/sounds/taskFive.mp3"),
    finalSound: require("../games-folder/studying-triangle/sounds/finalSound.mp3"),
    praiseSound: require("../games-folder/studying-triangle/sounds/praise.mp3")
  }
},

"studying-rectangle": {
  images: {
    rectangle: require("../games-folder/studying-rectangle/images/rectangle.png"),
    railStation: require("../games-folder/studying-rectangle/images/wallperTrail.png"),
    loko: require("../games-folder/studying-rectangle/images/loko.png"),
    lokoTwo: require("../games-folder/studying-rectangle/images/lokoTwo.png"),
    circleCarriage: require("../games-folder/studying-rectangle/images/circleCarriage.png"),
    ovalCarriage: require("../games-folder/studying-rectangle/images/ovalCarriage.png"),
    triangleCarriage: require("../games-folder/studying-rectangle/images/triangleCarriage.png"),
    squareCarriage: require("../games-folder/studying-rectangle/images/squareCarriage.png"),
    rectangleCarriage: require("../games-folder/studying-rectangle/images/rectangleCarriage.png"),
    blueCarriage: require("../games-folder/studying-rectangle/images/blueCarriage.png"),
    greenCarriage: require("../games-folder/studying-rectangle/images/greenCarriage.png"),
    redCarriage: require("../games-folder/studying-rectangle/images/redCarriage.png"),
    orangeCarriage: require("../games-folder/studying-rectangle/images/orangeCarriage.png"),
    ball: require("../games-folder/studying-rectangle/images/ball.png"),
    carpet: require("../games-folder/studying-rectangle/images/carpet.png"),
    envelope: require("../games-folder/studying-rectangle/images/envelope.png"),
    shelf: require("../games-folder/studying-rectangle/images/shelf.png"),
    spinning: require("../games-folder/studying-rectangle/images/spinning.png"),
    sun: require("../games-folder/studying-rectangle/images/sun.png"),
    wallperCity: require("../games-folder/studying-rectangle/images/wallperCity.png"),
    houseOne: require("../games-folder/studying-rectangle/images/houseOne.png"),
    houseTwo: require("../games-folder/studying-rectangle/images/houseTwo.png"),
    houseThree: require("../games-folder/studying-rectangle/images/houseThree.png"),
    path: require("../games-folder/studying-rectangle/images/path.svg"),
    catDog: require("../games-folder/studying-rectangle/images/catDog.png"),
    dogCow: require("../games-folder/studying-rectangle/images/dogCow.png"),
    cowGiraf: require("../games-folder/studying-rectangle/images/cowGiraf.png"),
    girafGiraf: require("../games-folder/studying-rectangle/images/girafGiraf.png"),
    hedgehogGiraf: require("../games-folder/studying-rectangle/images/hedgehogGiraf.png"),
    wolfHedgehog: require("../games-folder/studying-rectangle/images/wolfHedgehog.png"),
    wolfHip: require("../games-folder/studying-rectangle/images/wolfHip.png"),
    hipCat: require("../games-folder/studying-rectangle/images/hipCat.png")
  },
  sounds: {
    backgroundSound: require("../games-folder/studying-rectangle/sounds/backgroundSound.mp3"),
    correctAnswerSound: require("../games-folder/studying-rectangle/sounds/correctAnswer.mp3"),
    incorrectAnswerSound: require("../games-folder/studying-rectangle/sounds/no.mp3"),
    drawSound: require("../games-folder/studying-rectangle/sounds/draw.mp3"),
    taskZeroSound: require("../games-folder/studying-rectangle/sounds/taskZero.mp3"),
    taskTwoSound: require("../games-folder/studying-rectangle/sounds/taskTwo.mp3"),
    taskThreeSound: require("../games-folder/studying-rectangle/sounds/taskThree.mp3"),
    taskFourSound: require("../games-folder/studying-rectangle/sounds/taskFour.mp3"),
    taskFiveSound: require("../games-folder/studying-rectangle/sounds/taskFive.mp3"),
    praiseSound: require("../games-folder/studying-rectangle/sounds/praise.mp3")
  }
},

"studying-rhomb": {
  images: {
    rhomb: require("../games-folder/studying-rhomb/images/rhomb.png"),
    circleOne: require("../games-folder/studying-rhomb/images/circleOne.svg"),
    circleTwo: require("../games-folder/studying-rhomb/images/circleTwo.svg"),
    circleThree: require("../games-folder/studying-rhomb/images/circleThree.svg"),
    rhombTwo: require("../games-folder/studying-rhomb/images/rhombTwo.svg"),
    rhombThree: require("../games-folder/studying-rhomb/images/rhombThree.svg"),
    squareOne: require("../games-folder/studying-rhomb/images/squareOne.svg"),
    squareTwo: require("../games-folder/studying-rhomb/images/squareTwo.svg"),
    triangleOne: require("../games-folder/studying-rhomb/images/triangleOne.svg"),
    triangleTwo: require("../games-folder/studying-rhomb/images/triangleTwo.svg"),
    triagnleThree: require("../games-folder/studying-rhomb/images/triangleThree.svg"),
    btn: require("../games-folder/studying-rhomb/images/btn.png"),
    ball: require("../games-folder/studying-rhomb/images/ball.png"),
    cheese: require("../games-folder/studying-rhomb/images/cheese.png"),
    fish: require("../games-folder/studying-rhomb/images/fish.png"),
    flightSnake: require("../games-folder/studying-rhomb/images/flightSnake.png"),
    spinning: require("../games-folder/studying-rhomb/images/spinning.png"),
    waterlemon: require("../games-folder/studying-rhomb/images/waterlemon.png"),
    triangleBlueOne: require("../games-folder/studying-rhomb/images/triangleBlueOne.svg"),
    triangleBlueTwo: require("../games-folder/studying-rhomb/images/triangleBlueTwo.svg"),
    triangleGreenOne: require("../games-folder/studying-rhomb/images/triangleGreenOne.svg"),
    triangleGreenTwo: require("../games-folder/studying-rhomb/images/triangleGreenTwo.svg"),
    rhombLastTask: require("../games-folder/studying-rhomb/images/rhombLastTask.png"),
    circle: require("../games-folder/studying-rhomb/images/circle.png"),
    cross: require("../games-folder/studying-rhomb/images/cross.png"),
    wallperLastTask: require("../games-folder/studying-rhomb/images/wallperLastTask.svg")
  },
  sounds: {
    backgroundSound: require("../games-folder/studying-rhomb/sounds/backgroundSound.mp3"),
    incorrectAnswerSound: require("../games-folder/studying-rhomb/sounds/no.mp3"),
    correctAnswerSound: require("../games-folder/studying-rhomb/sounds/correctAnswer.mp3"),
    correctAnswerSoundTwo: require("../games-folder/studying-rhomb/sounds/correctAnswerTwo.mp3"),
    taskZeroSound: require("../games-folder/studying-rhomb/sounds/taskZero.mp3"),
    taskTwoSound: require("../games-folder/studying-rhomb/sounds/taskTwo.mp3"),
    taskThreeSound: require("../games-folder/studying-rhomb/sounds/taskThree.mp3"),
    taskFourSound: require("../games-folder/studying-rhomb/sounds/taskFour.mp3"),
    taskFiveSound: require("../games-folder/studying-rhomb/sounds/taskFive.mp3"),
    praiseSound: require("../games-folder/studying-rhomb/sounds/praise.mp3")
  }
},

"studying-oval": {
  images: {
    oval: require("../games-folder/studying-oval/images/oval.png"),
    circleBaloonOne: require("../games-folder/studying-oval/images/circleBaloonOne.png"),
    circleBaloonTwo: require("../games-folder/studying-oval/images/circleBaloonTwo.png"),
    circleBaloonThree: require("../games-folder/studying-oval/images/circleBaloonThree.png"),
    circleBaloonFour: require("../games-folder/studying-oval/images/circleBaloonOne.png"),
    ovalBaloonOne: require("../games-folder/studying-oval/images/ovalBaloonOne.png"),
    ovalBaloonTwo: require("../games-folder/studying-oval/images/ovalBaloonTwo.png"),
    ovalBaloonThree: require("../games-folder/studying-oval/images/ovalBaloonThree.png"),
    rhombBaloonOne: require("../games-folder/studying-oval/images/rhombBaloonOne.png"),
    rhombBaloonTwo: require("../games-folder/studying-oval/images/rhombBaloonTwo.png"),
    rhombBaloonThree: require("../games-folder/studying-oval/images/rhombBaloonThree.png"),
    squareBaloonOne: require("../games-folder/studying-oval/images/squareBaloonOne.png"),
    squareBaloonTwo: require("../games-folder/studying-oval/images/squareBaloonOne.png"),
    ball: require("../games-folder/studying-oval/images/ball.png"),
    bell: require("../games-folder/studying-oval/images/bell.png"),
    bread: require("../games-folder/studying-oval/images/bread.png"),
    donat: require("../games-folder/studying-oval/images/donat.png"),
    gift: require("../games-folder/studying-oval/images/gift.png"),
    mirror: require("../games-folder/studying-oval/images/mirror.png"),
    regbyBall: require("../games-folder/studying-oval/images/regbyBall.png"),
    sausage: require("../games-folder/studying-oval/images/sausage.png"),
    sign: require("../games-folder/studying-oval/images/sign.png"),
    apple: require("../games-folder/studying-oval/images/apple.png"),
    blueOval: require("../games-folder/studying-oval/images/blueOval.svg"),
    greenOval: require("../games-folder/studying-oval/images/greenOval.svg"),
    mushroom: require("../games-folder/studying-oval/images/mushroom.png"),
    orangeOval: require("../games-folder/studying-oval/images/orangeOval.svg"),
    rectangle: require("../games-folder/studying-oval/images/rectangle.svg"),
    redOval: require("../games-folder/studying-oval/images/redOval.svg"),
    squirell: require("../games-folder/studying-oval/images/squirell.png"),
    birOval: require("../games-folder/studying-oval/images/birOval.svg"),
    blackCircle: require("../games-folder/studying-oval/images/blackCircle.svg"),
    blackOval: require("../games-folder/studying-oval/images/blackOval.svg"),
    squirellFly: require("../games-folder/studying-oval/images/squirellFly.png"),
    squirellMushroom: require("../games-folder/studying-oval/images/squirellMushroom.png"),
    baloonDown: require("../games-folder/studying-oval/images/baloon.png"),
    baloonUp: require("../games-folder/studying-oval/images/baloonSecondPart.png"),
    catDown: require("../games-folder/studying-oval/images/cat.png"),
    catUp: require("../games-folder/studying-oval/images/catSecondPart.svg"),
    fishDown: require("../games-folder/studying-oval/images/fish.png"),
    fishUp: require("../games-folder/studying-oval/images/fishSecondPart.svg"),
    frogDown: require("../games-folder/studying-oval/images/frog.png"),
    frogUp: require("../games-folder/studying-oval/images/frogSecondPart.svg"),
    jarDown: require("../games-folder/studying-oval/images/jar.png"),
    jarUp: require("../games-folder/studying-oval/images/jarSecondPart.svg"),
    sunflowerDown: require("../games-folder/studying-oval/images/sunflower.png"),
    sunflowerUp: require("../games-folder/studying-oval/images/sunflowerSecondPart.svg")
  },
  sounds: {
    backgroundSound: require("../games-folder/studying-oval/sounds/backgroundSound.mp3"),
    incorrectAnswerSound: require("../games-folder/studying-oval/sounds/no.mp3"),
    correctAnswerSound: require("../games-folder/studying-oval/sounds/correctAnswer.mp3"),
    taskZeroSound: require("../games-folder/studying-oval/sounds/taskZero.mp3"),
    taskTwoSound: require("../games-folder/studying-oval/sounds/taskTwo.mp3"),
    taskThreeSound: require("../games-folder/studying-oval/sounds/taskThree.mp3"),
    taskFourSound: require("../games-folder/studying-oval/sounds/taskFour.mp3"),
    taskFiveSound: require("../games-folder/studying-oval/sounds/taskFive.mp3"),
    baloonSound: require("../games-folder/studying-oval/sounds/baloon.mp3"),
    coloredOvalSound: require("../games-folder/studying-oval/sounds/colored.mp3"),
    squirrelSound: require("../games-folder/studying-oval/sounds/squirellFly.mp3"),
    praiseSound: require("../games-folder/studying-oval/sounds/praise.mp3")
  }
},







"rabbit": {
  images: {
    rabbit: require("../games-folder/rabbit-game/images/rabbit.png"),
    wallper: require("../games-folder/rabbit-game/images/wallper.png"),
    carrot: require("../games-folder/rabbit-game/images/carrot.png"),
    carrotFood: require("../games-folder/rabbit-game/images/carrotFood.png"),
  },
  sounds: {
    startInstructionSound: require("../games-folder/rabbit-game/sounds/startInstruction.mp3"),
    backgroundSound: require("../games-folder/rabbit-game/sounds/backgroundSound.mp3"),
    eatCarrotSound: require("../games-folder/rabbit-game/sounds/eatCarrot.mp3"),
    jumpSound: require("../games-folder/rabbit-game/sounds/jump.mp3"),
    praiseSound: require("../games-folder/rabbit-game/sounds/praise.mp3"),
  },
},

"associations": {
  images: {
    wallper: require("../games-folder/associations/images/wallper.png"),
    goldStar: require("../games-folder/associations/images/gold-star-counter.png"),
    star: require("../games-folder/associations/images/star.png"),
    belka: require("../games-folder/associations/images/belka.png"),
    bearHouse: require("../games-folder/associations/images/bear-house.png"),
    bear: require("../games-folder/associations/images/bear.png"),
    beeHouse: require("../games-folder/associations/images/bee-house.png"),
    cow: require("../games-folder/associations/images/cow.png"),
    dogHouse: require("../games-folder/associations/images/dog-house.png"),
    dog: require("../games-folder/associations/images/dog.png"),
    duplo: require("../games-folder/associations/images/duplo.png"),
    gnezdo: require("../games-folder/associations/images/gnezdo.png"),
    house: require("../games-folder/associations/images/house.png"),
    kit: require("../games-folder/associations/images/kit.png"),
    mouseHouse: require("../games-folder/associations/images/mouse-house.png"),
    mouse: require("../games-folder/associations/images/mouse.png"),
    ocean: require("../games-folder/associations/images/ocean.png"),
    pchela: require("../games-folder/associations/images/pchela.png"),
    ptica: require("../games-folder/associations/images/ptica.png"),
  },
  sounds: {
    startInstructionSound: require("../games-folder/associations/sounds/startInstruction.mp3"),
    correctAnswerSound: require("../games-folder/associations/sounds/correctAnswer.mp3"),
    incorrectAnswerSound: require("../games-folder/associations/sounds/no.mp3"),
    finalSound: require("../games-folder/associations/sounds/finalSound.mp3"),
    praiseSound: require("../games-folder/associations/sounds/praise.mp3"),
  },
},

"ball-dance": {
  images: {
    wallper: require("../games-folder/ball-dancing/images/wallper.png"),
    alarm: require("../games-folder/ball-dancing/images/alarm.png"),
    badger: require("../games-folder/ball-dancing/images/badger.png"),
    beaver: require("../games-folder/ball-dancing/images/beaver.png"),
    barell: require("../games-folder/ball-dancing/images/barell.png"),
    bull: require("../games-folder/ball-dancing/images/bull.png"),
    butterfly: require("../games-folder/ball-dancing/images/butterfly.png"),
    house: require("../games-folder/ball-dancing/images/house.png"),
    loaf: require("../games-folder/ball-dancing/images/loaf.png"),
    notebook: require("../games-folder/ball-dancing/images/notebook.png"),
    shoes: require("../games-folder/ball-dancing/images/shoes.png"),
    squirell: require("../games-folder/ball-dancing/images/squirell.png"),
    repeatTaskImg: require("../games-folder/ball-dancing/images/repeatTask.png"),
    dynamicImg: require("../games-folder/ball-dancing/images/dynamic.png"),
    dynamicOff: require("../games-folder/ball-dancing/images/dynamicOff.png"),
  },
  sounds: {
    startInstructionSound: require("../games-folder/ball-dancing/sounds/startInstructionSound.mp3"),
    backgroundSound: require("../games-folder/ball-dancing/sounds/backgroundSound.mp3"),
    correctAnswerSound: require("../games-folder/ball-dancing/sounds/rightAnswer.mp3"),
    praiseSound: require("../games-folder/ball-dancing/sounds/praise.mp3"),
  },
},

"create-human": {
  images: {
    girlMain: require("../games-folder/create-a-human/images/girlMain.png"),
    boyMain: require("../games-folder/create-a-human/images/boyMain.png"),
    cover: require("../games-folder/create-a-human/images/cover.svg"),
    girlNoBody: require("../games-folder/create-a-human/images/girlNoBody.png"),
    girlNoEar: require("../games-folder/create-a-human/images/girlNoEar.png"),
    girlNoEye: require("../games-folder/create-a-human/images/girlNoEye.png"),
    girlNoHair: require("../games-folder/create-a-human/images/girlNoHair.png"),
    girlNoHand: require("../games-folder/create-a-human/images/girlNoHand.png"),
    girlNoLeg: require("../games-folder/create-a-human/images/girlNoLeg.png"),
    girlNoNose: require("../games-folder/create-a-human/images/girlNoNose.png"),
    girlBody: require("../games-folder/create-a-human/images/bodyGirl.png"),
    girlEar: require("../games-folder/create-a-human/images/earGirl.png"),
    girlEye: require("../games-folder/create-a-human/images/eyeGirl.png"),
    girlHair: require("../games-folder/create-a-human/images/hairGirl.png"),
    girlHand: require("../games-folder/create-a-human/images/handGirl.png"),
    girlLeg: require("../games-folder/create-a-human/images/legGirl.png"),
    girlNose: require("../games-folder/create-a-human/images/noseGirl.png"),
    boyNoBody: require("../games-folder/create-a-human/images/boyNoBody.png"),
    boyNoEar: require("../games-folder/create-a-human/images/boyNoEar.png"),
    boyNoEye: require("../games-folder/create-a-human/images/boyNoEye.png"),
    boyNoHair: require("../games-folder/create-a-human/images/boyNoHair.png"),
    boyNoHand: require("../games-folder/create-a-human/images/boyNoHand.png"),
    boyNoLeg: require("../games-folder/create-a-human/images/boyNoLeg.png"),
    boyNoNose: require("../games-folder/create-a-human/images/boyNoNose.png"),
    boyBody: require("../games-folder/create-a-human/images/bodyBoy.png"),
    boyEar: require("../games-folder/create-a-human/images/earBoy.png"),
    boyEye: require("../games-folder/create-a-human/images/eyeBoy.png"),
    boyHair: require("../games-folder/create-a-human/images/hairBoy.png"),
    boyHand: require("../games-folder/create-a-human/images/handBoy.png"),
    boyLeg: require("../games-folder/create-a-human/images/legBoy.png"),
    boyNose: require("../games-folder/create-a-human/images/noseBoy.png"),
  },
  sounds: {
    startInstructionSound: require("../games-folder/create-a-human/sounds/startInstruction.mp3"),
    backgroundSound: require("../games-folder/create-a-human/sounds/backgroundSound.mp3"),
    secondInstructionSound: require("../games-folder/create-a-human/sounds/secondInstruction.mp3"),
    thirdInstructionSound: require("../games-folder/create-a-human/sounds/thirdInstruction.mp3"),
    correctAnswerSound: require("../games-folder/create-a-human/sounds/yes.mp3"),
    incorrectAnswerSound: require("../games-folder/create-a-human/sounds/no.mp3"),
    praiseSound: require("../games-folder/create-a-human/sounds/praise.mp3"),
  },
},
"create-face": {
  images: {
    cover: require("../games-folder/create-face/images/cover.svg"),
    faceMask: require("../games-folder/create-face/images/faceMask.png"),
    blush: require("../games-folder/create-face/images/blush.png"),
    brows: require("../games-folder/create-face/images/brows.png"),
    eyes: require("../games-folder/create-face/images/eyes.png"),
    ears: require("../games-folder/create-face/images/ears.png"),
    hair: require("../games-folder/create-face/images/hair.png"),
    mouth: require("../games-folder/create-face/images/mouth.png"),
    nose: require("../games-folder/create-face/images/nose.png"),
    oneEye: require("../games-folder/create-face/images/oneEye.png"),
    browsTwo: require("../games-folder/create-face/images/browsTwo.png"),
    earsTwo: require("../games-folder/create-face/images/earsTwo.png"),
    hairTwo: require("../games-folder/create-face/images/hairTwo.png"),
    mouthTwo: require("../games-folder/create-face/images/mouthTwo.png"),
    noseTwo: require("../games-folder/create-face/images/noseTwo.png"),
    brushTwo: require("../games-folder/create-face/images/brushTwo.png"),
    noBrows: require("../games-folder/create-face/images/noBrows.png"),
    noEars: require("../games-folder/create-face/images/noEars.png"),
    noEyes: require("../games-folder/create-face/images/noEye.png"),
    noHair: require("../games-folder/create-face/images/noHair.png"),
    noMouth: require("../games-folder/create-face/images/noMouth.png"),
    noNose: require("../games-folder/create-face/images/noNose.png"),
    noBlush: require("../games-folder/create-face/images/noBlush.png"),
  },
  sounds: {
    backgroundSound: require("../games-folder/create-face/sounds/backgroundSound.mp3"),
    incorrectAnswerSound: require("../games-folder/create-face/sounds/no.mp3"),
    correctAnswerSound: require("../games-folder/create-face/sounds/yes.mp3"),
    startInstructionSound: require("../games-folder/create-face/sounds/startInstruction.mp3"),
    secondInstructionSound: require("../games-folder/create-face/sounds/secondInstruction.mp3"),
    praiseSound: require("../games-folder/create-face/sounds/praise.mp3"),
  },
},

"watch-cinema": {
  images: {
    wallper: require("../games-folder/watch-cinema/images/wallper.png"),
    dynamicImg: require("../games-folder/watch-cinema/images/dynamic.png"),
    repeatTaskImg: require("../games-folder/watch-cinema/images/repeatTask.png"),
    dynamicOff: require("../games-folder/watch-cinema/images/dynamicOff.png"),
    accordion: require("../games-folder/watch-cinema/images/accordion.png"),
    booth: require("../games-folder/watch-cinema/images/booth.png"),
    bouquet: require("../games-folder/watch-cinema/images/bouquet.png"),
    bread: require("../games-folder/watch-cinema/images/bread.png"),
    bud: require("../games-folder/watch-cinema/images/bud.png"),
    chicken: require("../games-folder/watch-cinema/images/chicken.png"),
    doll: require("../games-folder/watch-cinema/images/doll.png"),
    duck: require("../games-folder/watch-cinema/images/duck.png"),
    elephant: require("../games-folder/watch-cinema/images/elephant.png"),
    fairy: require("../games-folder/watch-cinema/images/fairy.png"),
    gnome: require("../games-folder/watch-cinema/images/gnome.png"),
    harp: require("../games-folder/watch-cinema/images/harp.png"),
    helicopter: require("../games-folder/watch-cinema/images/helicopter.png"),
    house: require("../games-folder/watch-cinema/images/house.png"),
    lawn: require("../games-folder/watch-cinema/images/lawn.png"),
    lemon: require("../games-folder/watch-cinema/images/lemon.png"),
    orange: require("../games-folder/watch-cinema/images/orange.png"),
    pipe: require("../games-folder/watch-cinema/images/pipe.png"),
    snow: require("../games-folder/watch-cinema/images/snow.png"),
    snowball: require("../games-folder/watch-cinema/images/snowball.png"),
    snowman: require("../games-folder/watch-cinema/images/snowman.png"),
    stork: require("../games-folder/watch-cinema/images/stork.png"),
    wagon: require("../games-folder/watch-cinema/images/wagon.png"),
    waterlemon: require("../games-folder/watch-cinema/images/waterlemon.png"),
  },
  sounds: {
    startInstructionSound: require("../games-folder/watch-cinema/sounds/startInstruction.mp3"),
    backgroundSound: require("../games-folder/watch-cinema/sounds/backgroundSound.mp3"),
    budkaDudkaUtka: require("../games-folder/watch-cinema/sounds/budkaDudkaUtka.mp3"),
    komDomGnom: require("../games-folder/watch-cinema/sounds/komDomGnom.mp3"),
    limonVagonButon: require("../games-folder/watch-cinema/sounds/limonVagonButon.mp3"),
    correctAnswerSound: require("../games-folder/watch-cinema/sounds/yes.mp3"),
    incorrectAnswerSound: require("../games-folder/watch-cinema/sounds/no.mp3"),
    praiseSound: require("../games-folder/watch-cinema/sounds/praise.mp3"),
  },
},

"most-attentive": {
  images: {
    dynamicImg: require("../games-folder/most-attentive/images/dynamic.png"),
    dynamicOff: require("../games-folder/most-attentive/images/dynamicOff.png"),
    repeatTaskImg: require("../games-folder/most-attentive/images/repeatTask.png"),
    boyBall: require("../games-folder/most-attentive/images/boyBall.png"),
    boyCat: require("../games-folder/most-attentive/images/boyCat.png"),
    boySquirell: require("../games-folder/most-attentive/images/boySquirell.png"),
    boyWolf: require("../games-folder/most-attentive/images/boyWolf.png"),
    catSleep: require("../games-folder/most-attentive/images/catSleep.png"),
    crab: require("../games-folder/most-attentive/images/crab.png"),
    crowCheese: require("../games-folder/most-attentive/images/crowCheese.png"),
    dogBall: require("../games-folder/most-attentive/images/dogBall.png"),
    donkey: require("../games-folder/most-attentive/images/donkey.png"),
    father: require("../games-folder/most-attentive/images/father.png"),
    girlDog: require("../games-folder/most-attentive/images/girlDog.png"),
    grandma: require("../games-folder/most-attentive/images/grandma.png"),
    grandpa: require("../games-folder/most-attentive/images/grandpa.png"),
    motherCat: require("../games-folder/most-attentive/images/motherCat.png"),
    rabbit: require("../games-folder/most-attentive/images/rabbit.png"),
    sharks: require("../games-folder/most-attentive/images/sharks.png"),
    tree: require("../games-folder/most-attentive/images/tree.png"),
    treeHedgehog: require("../games-folder/most-attentive/images/treeHedgehog.png"),
    treeMushroom: require("../games-folder/most-attentive/images/treeMushroom.png"),
    turtle: require("../games-folder/most-attentive/images/turtle.png"),
    wolf: require("../games-folder/most-attentive/images/wolf.png"),
  },
  sounds: {
    startInstructionSound: require("../games-folder/most-attentive/sounds/startInstruction.mp3"),
    seconInstructionSound: require("../games-folder/most-attentive/sounds/secondInstruction.mp3"),
    taskThreeSound: require("../games-folder/most-attentive/sounds/taskThree.mp3"),
    taskFourSound: require("../games-folder/most-attentive/sounds/taskFour.mp3"),
    taskFiveSound: require("../games-folder/most-attentive/sounds/taskFive.mp3"),
    taskSixSound: require("../games-folder/most-attentive/sounds/taskSix.mp3"),
    taskSevenSound: require("../games-folder/most-attentive/sounds/taskSeven.mp3"),
    taskEightSound: require("../games-folder/most-attentive/sounds/taskEight.mp3"),
    taskNineSound: require("../games-folder/most-attentive/sounds/taskNine.mp3"),
    backgroundSound: require("../games-folder/most-attentive/sounds/backgroundSound.mp3"),
    correctAnswerSound: require("../games-folder/most-attentive/sounds/yes.mp3"),
    incorrectAnswerSound: require("../games-folder/most-attentive/sounds/no.mp3"),
    praiseSound: require("../games-folder/most-attentive/sounds/praise.mp3"),
  }
},


"look-binoculars": {
  images: {
    dynamicImg: require("../games-folder/look-binoculars/images/dynamic.png"),
    dynamicOff: require("../games-folder/look-binoculars/images/dynamicOff.png"),
    arrowLeft: require("../games-folder/look-binoculars/images/arrowLeft.svg"),
    arrowRight: require("../games-folder/look-binoculars/images/arrowRight.svg"),
    captain: require("../games-folder/look-binoculars/images/captain.png"),
    chest: require("../games-folder/look-binoculars/images/chest.png"),
    doctor: require("../games-folder/look-binoculars/images/doctor.png"),
    islandCave: require("../games-folder/look-binoculars/images/islandCave.png"),
    islandFar: require("../games-folder/look-binoculars/images/islandFar.png"),
    islandTree: require("../games-folder/look-binoculars/images/islandTree.png"),
    jellyfish: require("../games-folder/look-binoculars/images/jellyfish.png"),
    kids: require("../games-folder/look-binoculars/images/kids.png"),
    sea: require("../games-folder/look-binoculars/images/sea.png"),
    seaCastle: require("../games-folder/look-binoculars/images/seaCastle.png"),
    shark: require("../games-folder/look-binoculars/images/shark.png"),
    squirell: require("../games-folder/look-binoculars/images/squirell.png"),
    binoculars: require("../games-folder/look-binoculars/images/binoculars.svg"),
    star: require("../games-folder/look-binoculars/images/star.png"),
  },
  sounds: {
    startInstructionSound: require("../games-folder/look-binoculars/sounds/startInstruction.mp3"),
    backgroundSound: require("../games-folder/look-binoculars/sounds/backgroundSound.mp3"),
    clickSound: require("../games-folder/look-binoculars/sounds/click.mp3"),
    incorrectAnswerSound: require("../games-folder/look-binoculars/sounds/no.mp3"),
    praiseSound: require("../games-folder/look-binoculars/sounds/praise.mp3"),
    starSound: require("../games-folder/look-binoculars/sounds/star.mp3"),
    whatYouSeeSound: require("../games-folder/look-binoculars/sounds/whatYouSee.mp3"),
    whoYouSeeSound: require("../games-folder/look-binoculars/sounds/whoYouSee.mp3"),
    taskZeroSound: require("../games-folder/look-binoculars/sounds/taskZero.mp3"),
    taskOneSound: require("../games-folder/look-binoculars/sounds/taskOne.mp3"),
    taskTwoSound: require("../games-folder/look-binoculars/sounds/taskTwo.mp3"),
    taskThreeSound: require("../games-folder/look-binoculars/sounds/taskThree.mp3"),
    taskFourSound: require("../games-folder/look-binoculars/sounds/taskFour.mp3"),
    taskFiveSound: require("../games-folder/look-binoculars/sounds/taskFive.mp3"),
    taskSixSound: require("../games-folder/look-binoculars/sounds/taskSix.mp3"),
    taskSevenSound: require("../games-folder/look-binoculars/sounds/taskSeven.mp3"),
    taskEightSound: require("../games-folder/look-binoculars/sounds/taskEight.mp3"),
    taskNineSound: require("../games-folder/look-binoculars/sounds/taskNine.mp3"),
  },
},

"talking-projector": {
  images: {
    dynamicImg: require("../games-folder/talking-projector/images/dynamic.png"),
    dynamicOff: require("../games-folder/talking-projector/images/dynamicOff.png"),
    apple: require("../games-folder/talking-projector/images/apple.png"),
    banana: require("../games-folder/talking-projector/images/banana.png"),
    bag: require("../games-folder/talking-projector/images/bag.png"),
    beet: require("../games-folder/talking-projector/images/beet.png"),
    boots: require("../games-folder/talking-projector/images/boots.png"),
    bull: require("../games-folder/talking-projector/images/bull.png"),
    cabbage: require("../games-folder/talking-projector/images/cabbage.png"),
    car: require("../games-folder/talking-projector/images/car.png"),
    carrot: require("../games-folder/talking-projector/images/carrot.png"),
    cat: require("../games-folder/talking-projector/images/cat.png"),
    cow: require("../games-folder/talking-projector/images/cow.png"),
    eggplant: require("../games-folder/talking-projector/images/eggplant.png"),
    fork: require("../games-folder/talking-projector/images/fork.png"),
    hat: require("../games-folder/talking-projector/images/hat.png"),
    hatTwo: require("../games-folder/talking-projector/images/hatTwo.png"),
    horse: require("../games-folder/talking-projector/images/horse.png"),
    jacket: require("../games-folder/talking-projector/images/jacket.png"),
    jeans: require("../games-folder/talking-projector/images/jeans.png"),
    knife: require("../games-folder/talking-projector/images/knife.png"),
    mittens: require("../games-folder/talking-projector/images/mittens.png"),
    onion: require("../games-folder/talking-projector/images/onion.png"),
    pear: require("../games-folder/talking-projector/images/pear.png"),
    plate: require("../games-folder/talking-projector/images/plate.png"),
    plum: require("../games-folder/talking-projector/images/plum.png"),
    potato: require("../games-folder/talking-projector/images/potato.png"),
    projector: require("../games-folder/talking-projector/images/projector.png"),
    scarf: require("../games-folder/talking-projector/images/scarf.png"),
    shoes: require("../games-folder/talking-projector/images/shoes.png"),
    shoesW: require("../games-folder/talking-projector/images/shoesW.png"),
    shorts: require("../games-folder/talking-projector/images/shorts.png"),
    skirt: require("../games-folder/talking-projector/images/skirt.png"),
    spoon: require("../games-folder/talking-projector/images/spoon.png"),
    swimsuit: require("../games-folder/talking-projector/images/swimsuit.png"),
    tomato: require("../games-folder/talking-projector/images/tomato.png"),
    train: require("../games-folder/talking-projector/images/train.png"),
    tram: require("../games-folder/talking-projector/images/tram.png"),
    trolleybus: require("../games-folder/talking-projector/images/trolleybus.png"),
    wolf: require("../games-folder/talking-projector/images/wolf.png"),
  },
  sounds: {
    startInstructionSound: require("../games-folder/talking-projector/sounds/startInstruction.mp3"),
    secondInstructionSound: require("../games-folder/talking-projector/sounds/secondInstruction.mp3"),
    backgroundSound: require("../games-folder/talking-projector/sounds/backgroundSound.mp3"),
    correctAnswerSound: require("../games-folder/talking-projector/sounds/rightAnswer.mp3"),
    incorrectAnswerSound: require("../games-folder/talking-projector/sounds/no.mp3"),
    praiseSound: require("../games-folder/talking-projector/sounds/praise.mp3"),
    taskZeroSound: require("../games-folder/talking-projector/sounds/taskZero.mp3"),
    taskOneSound: require("../games-folder/talking-projector/sounds/taskOne.mp3"),
    taskTwoSound: require("../games-folder/talking-projector/sounds/taskTwo.mp3"),
    taskThreeSound: require("../games-folder/talking-projector/sounds/taskThree.mp3"),
    taskFourSound: require("../games-folder/talking-projector/sounds/taskFour.mp3"),
    taskFiveSound: require("../games-folder/talking-projector/sounds/taskFive.mp3"),
  },
},





};

export default resourcesConfig;
