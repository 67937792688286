import React, { useState, useRef, useEffect } from "react";
import Confetti from "react-confetti";
import EndGameOptions from "../../end-game-options/end-game-options";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableItem from "./DraggableItem";
import DropTarget from "./DropTarget";
import styles from "./girl.module.css";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import cover from "./images/cover.svg";
import boyNoBody from "./images/boyNoBody.png";
import boyNoEar from "./images/boyNoEar.png";
import boyNoEye from "./images/boyNoEye.png";
import boyNoHair from "./images/boyNoHair.png";
import boyNoHand from "./images/boyNoHand.png";
import boyNoLeg from "./images/boyNoLeg.png";
import boyNoNose from "./images/boyNoNose.png";

import boyBody from "./images/bodyBoy.png";
import boyEar from "./images/earBoy.png";
import boyEye from "./images/eyeBoy.png";
import boyHair from "./images/hairBoy.png";
import boyHand from "./images/handBoy.png";
import boyLeg from "./images/legBoy.png";
import boyNose from "./images/noseBoy.png";

const tasks = [
  {
    image: boyNoHand,
    correctAnswer: "hand",
    dropZone: { top: "48%", left: "5%", width: "40%", height: "19%" },
    placement: { top: "51%", left: "7%", width: "27%", height: "15%" },
  },
  {
    image: boyNoHair,
    correctAnswer: "hair",
    dropZone: { top: "14%", left: "0%", width: "100%", height: "30%" },
    placement: { top: "0%", left: "0%", width: "100%", height: "36%" },
  },
  {
    image: boyNoBody,
    correctAnswer: "body",
    dropZone: { top: "45%", left: "15%", width: "60%", height: "40%" },
    placement: { top: "45%", left: "27%", width: "50%", height: "33%" },
  },
  {
    image: boyNoNose,
    correctAnswer: "nose",
    dropZone: { top: "30%", left: "42%", width: "15%", height: "8%" },
    placement: { top: "32%", left: "43%", width: "14%", height: "4%" },
  },
  {
    image: boyNoLeg,
    correctAnswer: "leg",
    dropZone: { top: "75%", left: "17%", width: "30%", height: "25%" },
    placement: { top: "73%", left: "13%", width: "34%", height: "27%" },
  },
  {
    image: boyNoEar,
    correctAnswer: "ear",
    dropZone: { top: "25%", left: "2%", width: "20%", height: "14%" },
    placement: { top: "28%", left: "1.2%", width: "28%", height: "13%" },
  },
  {
    image: boyNoEye,
    correctAnswer: "eye",
    dropZone: { top: "25%", left: "23%", width: "20%", height: "10%" },
    placement: { top: "25%", left: "23%", width: "23%", height: "11%" },
  },
];

const BoyAssemblyComponent = ({ audioRefs }) => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [placedParts, setPlacedParts] = useState([]);
  const [showConfetti, setShowConfetti] = useState(false);

  const currentTask = tasks[currentTaskIndex];

  const audioRef = useRef(null);
  const {
    secondInstructionAudioRef,
    thirdInstructionAudioRef,
    correctAnswerAudioRef,
    incorrectAnswerAudioRef,
    praiseAudioRef,
  } = audioRefs;

  useEffect(() => {
    setIsInteractionBlocked(true);

    if (secondInstructionAudioRef.current) {
      secondInstructionAudioRef.current.play();
      secondInstructionAudioRef.current.onended = () => {
        if (thirdInstructionAudioRef.current) {
          thirdInstructionAudioRef.current.play();
          thirdInstructionAudioRef.current.onended = () => {
            setIsInteractionBlocked(false);
          };
        } else {
          setIsInteractionBlocked(false);
        }
      };
    } else {
      setIsInteractionBlocked(false);
    }
  }, []);

  const parts = [
    { id: "body", src: boyBody, size: "60%" },
    { id: "ear", src: boyEar, size: "40%" },
    { id: "eye", src: boyEye, size: "30%" },
    { id: "hair", src: boyHair, size: "100%" },
    { id: "hand", src: boyHand, size: "70%" },
    { id: "leg", src: boyLeg, size: "55%" },
    { id: "nose", src: boyNose, size: "40%" },
  ];

  const handleDrop = (droppedItemId) => {
    const task = currentTask;
    const taskIndex = currentTaskIndex;

    if (droppedItemId === task.correctAnswer) {
      if (correctAnswerAudioRef.current) {
        correctAnswerAudioRef.current.play();
      }
      setIsInteractionBlocked(true);
      setPlacedParts((prevPlacedParts) => [
        ...prevPlacedParts,
        {
          id: droppedItemId,
          src: parts.find((part) => part.id === droppedItemId).src,
          position: task.placement,
          taskIndex: taskIndex,
        },
      ]);

      setTimeout(() => {
        if (taskIndex < tasks.length - 1) {
          setCurrentTaskIndex(taskIndex + 1);
          if (thirdInstructionAudioRef.current) {
            thirdInstructionAudioRef.current.play();
            thirdInstructionAudioRef.current.onended = () => {
              setIsInteractionBlocked(false);
            };
          } else {
            setIsInteractionBlocked(false);
          }
        } else {
          if (praiseAudioRef.current) {
            praiseAudioRef.current.play();
          }

          setShowConfetti(true);

          setTimeout(() => {
            setIsGameCompleted(true);
          }, 3000);
        }
      }, 2000);
    } else {
      incorrectAnswerAudioRef.current.play();
    }
  };
  return (
    <DndProvider backend={HTML5Backend}>
      {isGameCompleted && <EndGameOptions />}
      {showConfetti && <Confetti />}
      <div className={`container ${styles.someOtherClass}`}>
        <h4 className={styles.subHeader}>
          Задача: {currentTaskIndex + 1}/{tasks.length}
        </h4>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <div className={styles.leftColumn}>
            {parts.slice(0, 3).map((part, index) => (
              <div key={index} className={styles.partWrapper}>
                <img src={cover} alt="рамка" className={styles.cover} />
                <DraggableItem
                  id={part.id}
                  src={part.src}
                  alt="часть тела"
                  className={styles.partImage}
                  style={{ width: part.size }}
                />
              </div>
            ))}
          </div>
          <div className={styles.centerColumn}>
            <div className={styles.centralImageWrapper}>
              <img
                src={currentTask.image}
                alt="девочка"
                className={styles.centralImage}
              />
              <DropTarget
                targetId={currentTask.correctAnswer}
                onDrop={handleDrop}
                style={{
                  position: "absolute",
                  top: currentTask.dropZone.top,
                  left: currentTask.dropZone.left,
                  width: currentTask.dropZone.width,
                  height: currentTask.dropZone.height,
                  zIndex: 1,
                }}
              />
              {placedParts
                .filter((part) => part.taskIndex === currentTaskIndex)
                .map((part, index) => (
                  <img
                    key={index}
                    src={part.src}
                    alt={part.id}
                    style={{
                      position: "absolute",
                      top: part.position.top,
                      left: part.position.left,
                      width: part.position.width,
                      height: part.position.height,
                      zIndex: 2,
                    }}
                  />
                ))}
            </div>
          </div>
          <div className={styles.rightColumn}>
            {parts.slice(3, 6).map((part, index) => (
              <div key={index} className={styles.partWrapper}>
                <img src={cover} alt="рамка" className={styles.cover} />
                <DraggableItem
                  id={part.id}
                  src={part.src}
                  alt="часть тела"
                  className={styles.partImage}
                  style={{ width: part.size }}
                />
              </div>
            ))}
          </div>
        </div>

        <div className={styles.bottomRow}>
          <div className={styles.partWrapper}>
            <img src={cover} alt="рамка" className={styles.cover} />
            <DraggableItem
              id={parts[6].id}
              src={parts[6].src}
              alt="часть тела"
              className={styles.partImage}
              style={{ width: parts[6].size }}
            />
          </div>
        </div>
        <audio ref={audioRef} />
      </div>
    </DndProvider>
  );
};

export default BoyAssemblyComponent;
