import { useDrop } from "react-dnd";

const DropTarget = ({ children, targetId, onDrop, className, style }) => {
  const [, drop] = useDrop(
    () => ({
      accept: "PART",
      drop: (item) => onDrop(item.id),
      // canDrop: (item) => item.id === targetId,
    }),
    [targetId]
  );

  return (
    <div ref={drop} className={className} style={style}>
      {children}
    </div>
  );
};

export default DropTarget;
