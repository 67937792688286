import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import Confetti from "react-confetti";
import styles from "./look-binoculars.module.css";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";

import dynamicImg from "./images/dynamic.png";
import dynamicOff from "./images/dynamicOff.png";
// import repeatTaskImg from "./images/repeatTask.png";
import arrowLeft from "./images/arrowLeft.svg";
import arrowRight from "./images/arrowRight.svg";
import captain from "./images/captain.png";
import chest from "./images/chest.png";
import doctor from "./images/doctor.png";
import islandCave from "./images/islandCave.png";
import islandFar from "./images/islandFar.png";
import islandTree from "./images/islandTree.png";
import jellyfish from "./images/jellyfish.png";
import kids from "./images/kids.png";
import sea from "./images/sea.png";
import seaCastle from "./images/seaCastle.png";
import shark from "./images/shark.png";
import squirell from "./images/squirell.png";
import binoculars from "./images/binoculars.svg";
import star from "./images/star.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import clickSound from "./sounds/click.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";
import praiseSound from "./sounds/praise.mp3";
import starSound from "./sounds/star.mp3";
import whatYouSeeSound from "./sounds/whatYouSee.mp3";
import whoYouSeeSound from "./sounds/whoYouSee.mp3";

import taskZeroSound from "./sounds/taskZero.mp3";
import taskOneSound from "./sounds/taskOne.mp3";
import taskTwoSound from "./sounds/taskTwo.mp3";
import taskThreeSound from "./sounds/taskThree.mp3";
import taskFourSound from "./sounds/taskFour.mp3";
import taskFiveSound from "./sounds/taskFive.mp3";
import taskSixSound from "./sounds/taskSix.mp3";
import taskSevenSound from "./sounds/taskSeven.mp3";
import taskEightSound from "./sounds/taskEight.mp3";
import taskNineSound from "./sounds/taskNine.mp3";

const tasks = [
  {
    images: [
      { src: kids, alt: "дети" },
      { src: binoculars, alt: "бинокль" },
    ],
    correctDirection: "right",
    questionType: "who",
    instructionAudio: taskZeroSound,
  },

  {
    images: [
      { src: sea, alt: "море" },
      { src: binoculars, alt: "бинокль" },
    ],
    correctDirection: "left",
    questionType: "what",
    instructionAudio: taskOneSound,
  },

  {
    images: [
      { src: squirell, alt: "белка" },
      { src: binoculars, alt: "бинокль" },
    ],
    correctDirection: "left",
    questionType: "who",
    instructionAudio: taskThreeSound,
  },

  {
    images: [
      { src: seaCastle, alt: "морской замок" },
      { src: binoculars, alt: "бинокль" },
    ],
    correctDirection: "right",
    questionType: "what",
    instructionAudio: taskTwoSound,
  },
  {
    images: [
      { src: islandFar, alt: "остров в далеке" },
      { src: binoculars, alt: "бинокль" },
    ],
    correctDirection: "left",
    questionType: "what",
    instructionAudio: taskFourSound,
  },

  {
    images: [
      { src: shark, alt: "акула" },
      { src: binoculars, alt: "бинокль" },
    ],
    correctDirection: "right",
    questionType: "who",
    instructionAudio: taskFiveSound,
  },

  {
    images: [
      { src: islandTree, alt: "остров с пальмой" },
      { src: binoculars, alt: "бинокль" },
    ],
    correctDirection: "left",
    questionType: "what",
    instructionAudio: taskSixSound,
  },

  {
    images: [
      { src: jellyfish, alt: "медузы" },
      { src: binoculars, alt: "бинокль" },
    ],
    correctDirection: "right",
    questionType: "what",
    instructionAudio: taskSevenSound,
  },

  {
    images: [
      { src: doctor, alt: "доктор" },
      { src: binoculars, alt: "бинокль" },
    ],
    correctDirection: "right",
    questionType: "who",
    instructionAudio: taskEightSound,
  },

  {
    images: [
      { src: islandCave, alt: "пещера" },
      { src: binoculars, alt: "бинокль" },
    ],
    correctDirection: "left",
    questionType: "what",
    instructionAudio: taskNineSound,
  },

  {
    images: [
      { src: chest, alt: "сундук" },
      { src: binoculars, alt: "бинокль" },
    ],
  },
];

const LookBinocularsGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isSoundOn, setIsSoundOn] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [highlightedArrow, setHighlightedArrow] = useState(null);
  const [isIntro, setIsIntro] = useState(true);
  const [isStarVisible, setIsStarVisible] = useState(false);

  const starRef = useRef(null);
  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const clickAudioRef = useRef(new Audio(clickSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const incorrectAnswerSoundRef = useRef(new Audio(incorrectAnswerSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));
  const starAudioRef = useRef(new Audio(starSound));
  const whatYouSeeAudioRef = useRef(new Audio(whatYouSeeSound));
  const whoYouSeeAudioRef = useRef(new Audio(whoYouSeeSound));
  const instructionAudioRef = useRef(null);

  const currentTask = tasks[currentTaskIndex];

  useEffect(() => {
    if (!isIntro) {
      document.body.style.backgroundColor = "rgb(10, 9, 9)";
    } else {
      document.body.style.backgroundColor = "";
    }

    return () => {
      document.body.style.backgroundColor = "";
    };
  }, [isIntro]);

  const handleArrowClick = (direction) => {
    if (isInteractionBlocked) return;

    if (clickAudioRef.current) {
      clickAudioRef.current.play();
    }
    if (direction === currentTask.correctDirection) {
      setIsInteractionBlocked(true);

      setTimeout(() => {
        setHighlightedArrow(null);
        setIsInteractionBlocked(false);

        if (currentTaskIndex < tasks.length - 1) {
          setCurrentTaskIndex((prevIndex) => prevIndex + 1);
        } else {
          setIsStarVisible(true);
          setIsGameCompleted(true);
        }
      }, 2000);
    } else {
      if (incorrectAnswerSoundRef.current) {
        incorrectAnswerSoundRef.current.play();
      }
    }
  };

  const animateStar = () => {
    if (starAudioRef.current) {
      starAudioRef.current.play();
    }

    gsap.fromTo(
      starRef.current,
      {
        opacity: 0,
        scale: 0.5,
        boxShadow: "0px 0px 30px 10px rgba(255, 215, 0, 1)", // Золотое свечение
      },
      {
        opacity: 1,
        y: "-=200", // Поднимаем звезду на 100px вверх
        scale: 1.5,
        duration: 2,
        ease: "power2.out",
        boxShadow: "0px 0px 60px 20px rgba(255, 215, 0, 0)", // Свечение исчезает
        onComplete: () => {
          gsap.to(starRef.current, {
            boxShadow: "none", // Убираем тень полностью после анимации
          });

          if (praiseSoundRef.current) {
            praiseSoundRef.current.play();
          }
          setShowConfetti(true);

          setTimeout(() => {
            setIsGameCompleted(true);
          }, 3000);
        },
      }
    );
  };

  useEffect(() => {
    if (currentTaskIndex === tasks.length - 1) {
      setIsStarVisible(true);
    }
  }, [currentTaskIndex]);

  useEffect(() => {
    if (isStarVisible) {
      animateStar();
    }
  }, [isStarVisible]);

  const handleNextTask = () => {
    setIsInteractionBlocked(true);
    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        if (backgroundAudioRef.current) {
          backgroundAudioRef.current.loop = true;
          backgroundAudioRef.current.play();
        }
        setCurrentTaskIndex(0);
        setIsIntro(false);

        playQuestionAudioForCurrentTask();
      };
    }

    setIsButtonVisible(false);
  };

  const playQuestionAudioForCurrentTask = () => {
    setIsInteractionBlocked(true);

    let questionAudioRef;
    if (currentTask.questionType === "what") {
      questionAudioRef = whatYouSeeAudioRef.current;
    } else if (currentTask.questionType === "who") {
      questionAudioRef = whoYouSeeAudioRef.current;
    }

    if (questionAudioRef) {
      questionAudioRef.play();

      questionAudioRef.onended = () => {
        setIsInteractionBlocked(false);
      };
    } else {
      setIsInteractionBlocked(false);
    }
  };

  const handleScreenClick = () => {
    setIsInteractionBlocked(true);
    instructionAudioRef.current = new Audio(currentTask.instructionAudio);

    if (instructionAudioRef.current) {
      instructionAudioRef.current.play();

      instructionAudioRef.current.onended = () => {
        setHighlightedArrow(currentTask.correctDirection);
        setIsInteractionBlocked(false);
      };
    } else {
      setIsInteractionBlocked(false);
    }
  };

  useEffect(() => {
    if (currentTaskIndex > 0 && !isGameCompleted) {
      playQuestionAudioForCurrentTask();
    }
  }, [currentTaskIndex, isGameCompleted]);

  const handleSoundOnOff = () => {
    setIsSoundOn((prevIsSoundOn) => {
      const newIsSoundOn = !prevIsSoundOn;

      if (startInstructionAudioRef.current) {
        startInstructionAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (clickAudioRef.current) {
        clickAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (incorrectAnswerSoundRef.current) {
        incorrectAnswerSoundRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (praiseSoundRef.current) {
        praiseSoundRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (instructionAudioRef.current) {
        instructionAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (starAudioRef) {
        starAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      if (whatYouSeeAudioRef) {
        whatYouSeeAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (whoYouSeeAudioRef) {
        whoYouSeeAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      return newIsSoundOn;
    });
  };

  useEffect(() => {
    return () => {
      // Остановка всех аудио
      const allAudioRefs = [
        startInstructionAudioRef,
        backgroundAudioRef,
        clickAudioRef,
        incorrectAnswerSoundRef,
        praiseSoundRef,
        starAudioRef,
        whatYouSeeAudioRef,
        whoYouSeeAudioRef,
        instructionAudioRef,
      ];

      allAudioRefs.forEach((audioRef) => {
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
        }
      });

      // Сброс фона
      document.body.style.backgroundColor = "";
    };
  }, []);

  return (
    <>
      {isGameCompleted && <EndGameOptions />}
      {showConfetti && <Confetti className={styles.confettiCanvas} />}
      <div className={`container ${styles.someOtherClass}`}>
        <div className={styles.backgroundContainer}>
          <div className={styles.wallpaperWrapper}>
            <div
              onClick={handleScreenClick}
              style={{
                pointerEvents: isInteractionBlocked ? "none" : "auto",
              }}
            >
              {isIntro ? (
                <img className={styles.wallper} src={captain} alt="капитан" />
              ) : (
                currentTask.images.map((image, index) => {
                  let className;

                  if (image.alt === "бинокль") {
                    className =
                      currentTaskIndex === 0
                        ? styles.wallper
                        : styles.binocularsImage;
                  } else {
                    className = styles.wallper;
                  }

                  return (
                    <img
                      key={index}
                      className={className}
                      src={image.src}
                      alt={image.alt}
                    />
                  );
                })
              )}

              {isStarVisible && (
                <img
                  ref={starRef}
                  src={star}
                  alt="звезда"
                  className={styles.starImage}
                />
              )}

              {!isIntro && (
                <div className={styles.switchButtons}>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleArrowClick("left");
                    }}
                    className={`${styles.arrowButtonLeft} ${
                      highlightedArrow === "left" ? styles.highlighted : ""
                    }`}
                  >
                    <img src={arrowLeft} alt="стрелка влево" />
                  </button>

                  {/* Правая стрелка */}
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleArrowClick("right");
                    }}
                    className={`${styles.arrowButtonRight} ${
                      highlightedArrow === "right" ? styles.highlighted : ""
                    }`}
                  >
                    <img src={arrowRight} alt="стрелка вправо" />
                  </button>
                </div>
              )}
            </div>

            <div className={styles.absoluteContainer}>
              <div className={styles.buttonsContainer}>
                <button
                  className={styles.buttonsRepeatAndSoundOnOff}
                  onClick={handleSoundOnOff}
                >
                  <img
                    src={isSoundOn ? dynamicImg : dynamicOff}
                    alt="динамик"
                  />
                  <span className={styles.tooltip}>
                    Включить / выключить звук
                  </span>
                </button>
                {/* 
                <button
                  className={styles.buttonsRepeatAndSoundOnOff}
                  onClick={handleRepeatTask}
                >
                  <img src={repeatTaskImg} alt="повтор звука" />
                  <span className={styles.tooltip}>
                    Нажмите для повторного прослушивания инструкции
                  </span>
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <audio ref={audioRef} />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.button} onClick={handleNextTask}>
            {buttonText}
          </button>
        </div>
      )}
    </>
  );
};

export default LookBinocularsGame;
