import React, { useState, useRef, useEffect } from "react";
import styles from "./create-a-human.module.css";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import GirlAssemblyComponent from "./girl";
import BoyAssemblyComponent from "./boy";
import girlMain from "./images/girlMain.png";
import boyMain from "./images/boyMain.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";

import secondInstructionSound from "./sounds/secondInstruction.mp3";
import thirdInstructionSound from "./sounds/thirdInstruction.mp3";
import correctAnswerSound from "./sounds/yes.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";
import praiseSound from "./sounds/praise.mp3";

const CreateHumanGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [selectedCharacter, setSelectedCharacter] = useState(null);

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(null);
  const backgroundAudioRef = useRef(null);

  const secondInstructionAudioRef = useRef(null);
  const thirdInstructionAudioRef = useRef(null);
  const correctAnswerAudioRef = useRef(null);
  const incorrectAnswerAudioRef = useRef(null);
  const praiseAudioRef = useRef(null);

  useEffect(() => {
    document.body.style.backgroundColor = "rgba(252, 229, 170, 1)";

    startInstructionAudioRef.current = new Audio(startInstructionSound);
    backgroundAudioRef.current = new Audio(backgroundSound);

    secondInstructionAudioRef.current = new Audio(secondInstructionSound);
    thirdInstructionAudioRef.current = new Audio(thirdInstructionSound);
    correctAnswerAudioRef.current = new Audio(correctAnswerSound);
    incorrectAnswerAudioRef.current = new Audio(incorrectAnswerSound);
    praiseAudioRef.current = new Audio(praiseSound);

    return () => {
      if (startInstructionAudioRef.current) {
        startInstructionAudioRef.current.pause();
        startInstructionAudioRef.current.currentTime = 0;
      }
      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.pause();
        backgroundAudioRef.current.currentTime = 0;
      }
      if (secondInstructionAudioRef.current) {
        secondInstructionAudioRef.current.pause();
        secondInstructionAudioRef.current.currentTime = 0;
      }
      if (thirdInstructionAudioRef.current) {
        thirdInstructionAudioRef.current.pause();
        thirdInstructionAudioRef.current.currentTime = 0;
      }
      if (correctAnswerAudioRef.current) {
        correctAnswerAudioRef.current.pause();
        correctAnswerAudioRef.current.currentTime = 0;
      }
      if (incorrectAnswerAudioRef.current) {
        incorrectAnswerAudioRef.current.pause();
        incorrectAnswerAudioRef.current.currentTime = 0;
      }
      if (praiseAudioRef.current) {
        praiseAudioRef.current.pause();
        praiseAudioRef.current.currentTime = 0;
      }
      document.body.style.backgroundColor = "";
    };
  }, []);

  const handleGirlClick = () => {
    setSelectedCharacter("girl");
  };

  const handleBoyClick = () => {
    setSelectedCharacter("boy");
  };

  const handleNextTask = () => {
    setIsInteractionBlocked(true);

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        if (backgroundAudioRef.current) {
          backgroundAudioRef.current.loop = true;
          backgroundAudioRef.current.play();
        }
      };
    }

    setIsButtonVisible(false);
  };

  return (
    <>
      <div className={`container ${styles.someOtherClass}`}>
        {selectedCharacter === null && (
          <>
            <h3 className={styles.header}>Выбери своего персонажа</h3>
            <div
              className={styles.backgroundContainer}
              style={{
                pointerEvents: isInteractionBlocked ? "none" : "auto",
              }}
            >
              <img
                src={girlMain}
                alt="девочка"
                onClick={handleGirlClick}
                className={styles.selectableImage}
              />
              <img
                src={boyMain}
                alt="мальчик"
                onClick={handleBoyClick}
                className={styles.selectableImage}
              />
            </div>
          </>
        )}

        {selectedCharacter === "girl" && (
          <GirlAssemblyComponent
            audioRefs={{
              secondInstructionAudioRef,
              thirdInstructionAudioRef,
              correctAnswerAudioRef,
              incorrectAnswerAudioRef,
              praiseAudioRef,
            }}
          />
        )}

        {selectedCharacter === "boy" && (
          <BoyAssemblyComponent
            audioRefs={{
              secondInstructionAudioRef,
              thirdInstructionAudioRef,
              correctAnswerAudioRef,
              incorrectAnswerAudioRef,
              praiseAudioRef,
            }}
          />
        )}
      </div>
      <audio ref={audioRef} />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.button} onClick={handleNextTask}>
            {buttonText}
          </button>
        </div>
      )}
    </>
  );
};

export default CreateHumanGame;
