import React, { useState, useRef, useEffect } from "react";
import Confetti from "react-confetti";
import { gsap } from "gsap";
import TextPlugin from "gsap/TextPlugin";
import styles from "./watch-cinema.module.css";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";

import wallper from "./images/wallper.png";
import dynamicImg from "./images/dynamic.png";
import repeatTaskImg from "./images/repeatTask.png";
import dynamicOff from "./images/dynamicOff.png";
import accordion from "./images/accordion.png";
import booth from "./images/booth.png";
import bouquet from "./images/bouquet.png";
import bread from "./images/bread.png";
import bud from "./images/bud.png";
import chicken from "./images/chicken.png";
import doll from "./images/doll.png";
import duck from "./images/duck.png";
import elephant from "./images/elephant.png";
import fairy from "./images/fairy.png";
import gnome from "./images/gnome.png";
import harp from "./images/harp.png";
import helicopter from "./images/helicopter.png";
import house from "./images/house.png";
import lawn from "./images/lawn.png";
import lemon from "./images/lemon.png";
import orange from "./images/orange.png";
import pipe from "./images/pipe.png";
import snow from "./images/snow.png";
import snowball from "./images/snowball.png";
import snowman from "./images/snowman.png";
import stork from "./images/stork.png";
import wagon from "./images/wagon.png";
import waterlemon from "./images/waterlemon.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import budkaDudkaUtka from "./sounds/budkaDudkaUtka.mp3";
import komDomGnom from "./sounds/komDomGnom.mp3";
import limonVagonButon from "./sounds/limonVagonButon.mp3";
import correctAnswerSound from "./sounds/yes.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";
import praiseSound from "./sounds/praise.mp3";

const tasks = [
  {
    images: [
      { src: house, alt: "дом" },
      { src: wagon, alt: "вагон" },
      { src: booth, alt: "будка" },
    ],
    correctAnswer: "будка",
  },
  {
    images: [
      { src: accordion, alt: "гармошка" },
      { src: pipe, alt: "флейта" },
      { src: harp, alt: "арфа" },
    ],
    correctAnswer: "флейта",
  },
  {
    images: [
      { src: duck, alt: "утка" },
      { src: stork, alt: "аист" },
      { src: chicken, alt: "курица" },
    ],
    correctAnswer: "утка",
  },

  {
    images: [
      { src: snow, alt: "снег" },
      { src: snowball, alt: "ком" },
      { src: snowman, alt: "снеговик" },
    ],
    correctAnswer: "ком",
  },
  {
    images: [
      { src: house, alt: "дом" },
      { src: elephant, alt: "слон" },
      { src: wagon, alt: "вагон" },
    ],
    correctAnswer: "дом",
  },
  {
    images: [
      { src: doll, alt: "кукла" },
      { src: fairy, alt: "фея" },
      { src: gnome, alt: "гном" },
    ],
    correctAnswer: "гном",
  },
  {
    images: [
      { src: orange, alt: "апельсин" },
      { src: waterlemon, alt: "арбуз" },
      { src: lemon, alt: "лимон" },
    ],
    correctAnswer: "лимон",
  },
  {
    images: [
      { src: helicopter, alt: "вертолет" },
      { src: wagon, alt: "вагон" },
      { src: lawn, alt: "газон" },
    ],
    correctAnswer: "вагон",
  },
  {
    images: [
      { src: bud, alt: "бутон" },
      { src: bread, alt: "батон" },
      { src: bouquet, alt: "букет" },
    ],
    correctAnswer: "бутон",
  },
];

const WatchCinemaGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [showLevelMessage, setShowLevelMessage] = useState(false);
  const [isSoundOn, setIsSoundOn] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const correctAnswerSoundRef = useRef(new Audio(correctAnswerSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));
  const instructionAudioRef = useRef(null);

  const currentTask = tasks[currentTaskIndex];
  const levelMessageRef = useRef(null);
  gsap.registerPlugin(TextPlugin);

  const getInstructionSound = (taskIndex) => {
    if (taskIndex >= 0 && taskIndex <= 2) {
      return budkaDudkaUtka;
    } else if (taskIndex >= 3 && taskIndex <= 5) {
      return komDomGnom;
    } else if (taskIndex >= 6 && taskIndex <= 8) {
      return limonVagonButon;
    }
    return null;
  };

  const handleNextTask = () => {
    setIsInteractionBlocked(true);

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        // После стартовой инструкции воспроизводим соответствующую инструкцию
        const instructionSound = getInstructionSound(currentTaskIndex);
        if (instructionSound) {
          instructionAudioRef.current = new Audio(instructionSound);
          instructionAudioRef.current.play();
          instructionAudioRef.current.onended = () => {
            setIsInteractionBlocked(false);
            if (backgroundAudioRef.current) {
              backgroundAudioRef.current.loop = true;
              backgroundAudioRef.current.play();
            }
          };
        } else {
          setIsInteractionBlocked(false);
          if (backgroundAudioRef.current) {
            backgroundAudioRef.current.loop = true;
            backgroundAudioRef.current.play();
          }
        }
      };
    }

    setIsButtonVisible(false);
  };

  useEffect(() => {
    document.body.style.backgroundColor = "rgba(0, 0, 0, 0.85)";
  }, []);

  useEffect(() => {
    if (showLevelMessage) {
      gsap.fromTo(
        levelMessageRef.current,
        { text: "", opacity: 0 },
        {
          duration: 2,
          text: "Уровень 2",
          ease: "none",
          opacity: 1,
          delay: 0.5,
        }
      );
    }
  }, [showLevelMessage]);

  useEffect(() => {
    if (
      [3, 6].includes(currentTaskIndex) &&
      !isButtonVisible &&
      !isGameCompleted
    ) {
      setIsInteractionBlocked(true);
      const instructionSound = getInstructionSound(currentTaskIndex);
      if (instructionSound) {
        instructionAudioRef.current = new Audio(instructionSound);
        instructionAudioRef.current.play();
        instructionAudioRef.current.onended = () => {
          setIsInteractionBlocked(false);
        };
      } else {
        setIsInteractionBlocked(false);
      }
    }
  }, [currentTaskIndex, isButtonVisible, isGameCompleted]);

  const imageRefs = useRef([]);
  imageRefs.current = [];

  const addToRefs = (el) => {
    if (el && !imageRefs.current.includes(el)) {
      imageRefs.current.push(el);
    }
  };

  const handleImageClick = (imageAlt, index) => {
    if (isInteractionBlocked) return;

    if (imageAlt === currentTask.correctAnswer) {
      setIsInteractionBlocked(true);

      if (correctAnswerSoundRef.current) {
        correctAnswerSoundRef.current.play();
      }

      // Скрываем остальные изображения
      imageRefs.current.forEach((img, idx) => {
        if (idx !== index) {
          gsap.to(img, { opacity: 0, duration: 0.5 });
        }
      });

      // Анимация перемещения и увеличения
      const image = imageRefs.current[index];
      const rect = image.getBoundingClientRect();
      const centerX = window.innerWidth / 2;
      const centerY = window.innerHeight / 2;

      const deltaX = centerX - (rect.left + rect.width / 2);
      const deltaY = centerY - (rect.top + rect.height / 2);

      gsap.to(image, {
        x: deltaX,
        y: deltaY,
        scale: 2,
        duration: 1,
        ease: "power2.out",
        transformOrigin: "center center",
        onStart: () => {
          gsap.set(image.parentElement, { perspective: 800 });
          gsap.set(image, { zIndex: 1000 }); // Устанавливаем высокий z-index
        },
        onComplete: () => {
          gsap.set(image.parentElement, { perspective: 0 });

          // Ждем 3 секунды и скрываем изображение
          setTimeout(() => {
            gsap.to(image, {
              opacity: 0,
              duration: 1,
              onComplete: () => {
                if (currentTaskIndex === 2) {
                  // Перед задачей с индексом 3
                  setShowLevelMessage(true);
                  setTimeout(() => {
                    setShowLevelMessage(false);
                    setCurrentTaskIndex((prev) => prev + 1);
                    setIsInteractionBlocked(false);
                    // Сбрасываем стили изображений
                    imageRefs.current.forEach((img) => {
                      gsap.set(img, {
                        x: 0,
                        y: 0,
                        scale: 1,
                        opacity: 1,
                        zIndex: 1,
                      });
                    });
                  }, 3000);
                } else if (currentTaskIndex < tasks.length - 1) {
                  setCurrentTaskIndex((prev) => prev + 1);
                  setIsInteractionBlocked(false);
                  // Сбрасываем стили изображений
                  imageRefs.current.forEach((img) => {
                    gsap.set(img, {
                      x: 0,
                      y: 0,
                      scale: 1,
                      opacity: 1,
                      zIndex: 1,
                    });
                  });
                } else {
                  if (praiseSoundRef.current) {
                    praiseSoundRef.current.play();
                  }

                  setShowConfetti(true);
                  setTimeout(() => {
                    setIsGameCompleted(true);
                  }, 3000);
                }
              },
            });
          }, 3000);
        },
      });
    } else {
      // Неверный ответ
      if (incorrectAnswerSound && isSoundOn) {
        const incorrectAudio = new Audio(incorrectAnswerSound);
        incorrectAudio.play();
      }
    }
  };

  const handleSoundOnOff = () => {
    setIsSoundOn((prevIsSoundOn) => {
      const newIsSoundOn = !prevIsSoundOn;

      if (startInstructionAudioRef.current) {
        startInstructionAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (correctAnswerSoundRef.current) {
        correctAnswerSoundRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (praiseSoundRef.current) {
        praiseSoundRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (instructionAudioRef.current) {
        instructionAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      return newIsSoundOn;
    });
  };

  const handleRepeatTask = () => {
    if (instructionAudioRef.current) {
      instructionAudioRef.current.currentTime = 0;
      instructionAudioRef.current.play();
    }
  };

  useEffect(() => {
    document.body.style.backgroundColor = "rgba(0, 0, 0, 0.85)";

    // Очистка аудио при размонтировании компонента
    return () => {
      if (startInstructionAudioRef.current) {
        startInstructionAudioRef.current.pause();
        startInstructionAudioRef.current.currentTime = 0;
      }
      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.pause();
        backgroundAudioRef.current.currentTime = 0;
      }
      if (correctAnswerSoundRef.current) {
        correctAnswerSoundRef.current.pause();
        correctAnswerSoundRef.current.currentTime = 0;
      }
      if (praiseSoundRef.current) {
        praiseSoundRef.current.pause();
        praiseSoundRef.current.currentTime = 0;
      }
      if (instructionAudioRef.current) {
        instructionAudioRef.current.pause();
        instructionAudioRef.current.currentTime = 0;
      }
      document.body.style.backgroundColor = null;
    };
  }, []);

  return (
    <>
      {isGameCompleted && <EndGameOptions />}
      {showConfetti && <Confetti />}
      <div className={`container ${styles.someOtherClass}`}>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <div className={styles.wallpaperWrapper}>
            <img className={styles.wallper} src={wallper} alt="обои" />
            <div className={styles.absoluteContainer}>
              {!showLevelMessage && (
                <h4 className={styles.headerCounter}>
                  Вопрос {currentTaskIndex + 1} из {tasks.length}
                </h4>
              )}
              {showLevelMessage ? (
                <div className={styles.levelMessageContainer}>
                  <h5
                    className={styles.levelMessage}
                    ref={levelMessageRef}
                  ></h5>
                </div>
              ) : (
                <div className={styles.imagesRow}>
                  {currentTask.images.map((image, index) => (
                    <img
                      key={index}
                      ref={addToRefs}
                      className={styles.taskImage}
                      src={image.src}
                      alt={image.alt}
                      onClick={() => handleImageClick(image.alt, index)}
                    />
                  ))}
                </div>
              )}
              <div className={styles.buttonsContainer}>
                <button
                  className={styles.buttonsRepeatAndSoundOnOff}
                  onClick={handleSoundOnOff}
                >
                  <img
                    src={isSoundOn ? dynamicImg : dynamicOff}
                    alt="динамик"
                  />
                  <span className={styles.tooltip}>
                    Включить / выключить звук
                  </span>
                </button>

                <button
                  className={styles.buttonsRepeatAndSoundOnOff}
                  onClick={handleRepeatTask}
                >
                  <img src={repeatTaskImg} alt="повтор звука" />
                  <span className={styles.tooltip}>
                    Нажмите для повторного прослушивания инструкции
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <audio ref={audioRef} />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.button} onClick={handleNextTask}>
            {buttonText}
          </button>
        </div>
      )}
    </>
  );
};

export default WatchCinemaGame;
