import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import EndGameOptions from "../../end-game-options/end-game-options";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableItem from "./DraggableItem";
import DropTarget from "./DropTarget";
import styles from "./create-face.module.css";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import cover from "./images/cover.svg";
import faceMask from "./images/faceMask.png";
import blush from "./images/blush.png";
import brows from "./images/brows.png";
import eyes from "./images/eyes.png";
import ears from "./images/ears.png";
import hair from "./images/hair.png";
import mouth from "./images/mouth.png";
import nose from "./images/nose.png";

import oneEye from "./images/oneEye.png";
import browsTwo from "./images/browsTwo.png";
import earsTwo from "./images/earsTwo.png";
import hairTwo from "./images/hairTwo.png";
import mouthTwo from "./images/mouthTwo.png";
import noseTwo from "./images/noseTwo.png";
import brushTwo from "./images/brushTwo.png";

import noBrows from "./images/noBrows.png";
import noEars from "./images/noEars.png";
import noEyes from "./images/noEye.png";
import noHair from "./images/noHair.png";
import noMouth from "./images/noMouth.png";
import noNose from "./images/noNose.png";
import noBlush from "./images/noBlush.png";

import backgroundSound from "./sounds/backgroundSound.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";
import correctAnswerSound from "./sounds/yes.mp3";
import startInstructionSound from "./sounds/startInstruction.mp3";
import secondInstructionSound from "./sounds/secondInstruction.mp3";
import praiseSound from "./sounds/praise.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const tasks = [
  {
    image: faceMask,
    correctAnswers: [
      {
        id: "hair",
        dropZone: { top: "10%", left: "20%", width: "60%", height: "15%" },
        placement: { top: "4%", left: "2%", width: "99%", height: "47%" },
      },
      {
        id: "eyes",
        dropZone: { top: "35%", left: "15%", width: "75%", height: "14%" },
        placement: { top: "37%", left: "18%", width: "67%", height: "17%" },
      },
      {
        id: "ear",
        dropZone: { top: "33%", left: "-15%", width: "30%", height: "27%" },
        placement: { top: "35%", left: "-5%", width: "111%", height: "28%" },
      },
      {
        id: "ear",
        dropZone: { top: "33%", left: "84%", width: "30%", height: "27%" },
        placement: { top: "52%", left: "62%", width: "18%", height: "13%" },
      },
      {
        id: "nose",
        dropZone: { top: "54%", left: "38%", width: "25%", height: "22%" },
        placement: { top: "55%", left: "45%", width: "12%", height: "14%" },
      },
      {
        id: "mouth",
        dropZone: { top: "79%", left: "30%", width: "45%", height: "14%" },
        placement: { top: "81%", left: "38%", width: "23%", height: "5%" },
      },
      {
        id: "brows",
        dropZone: { top: "26%", left: "17%", width: "70%", height: "12%" },
        placement: { top: "30%", left: "17%", width: "70%", height: "10%" },
      },
      {
        id: "blush",
        dropZone: { top: "50%", left: "8%", width: "30%", height: "30%" },
        placement: { top: "56%", left: "13%", width: "76%", height: "19%" },
      },

      {
        id: "blush",
        dropZone: { top: "50%", left: "65%", width: "30%", height: "30%" },
        placement: { top: "56%", left: "13%", width: "76%", height: "19%" },
      },
    ],
  },

  {
    image: noEars,
    correctAnswers: [
      {
        id: "earSmall",
        dropZone: { top: "44%", left: "-9%", width: "130%", height: "30%" },
        placement: { top: "47%", left: "0%", width: "104%", height: "20%" },
      },
    ],
  },

  {
    image: noEyes,
    correctAnswers: [
      {
        id: "oneEye",
        dropZone: { top: "42%", left: "47%", width: "31%", height: "19%" },
        placement: { top: "47%", left: "52%", width: "33%", height: "15%" },
      },
    ],
  },

  {
    image: noHair,
    correctAnswers: [
      {
        id: "hairSmall",
        dropZone: { top: "8%", left: "14%", width: "72%", height: "26%" },
        placement: { top: "5%", left: "1%", width: "96%", height: "52%" },
      },
    ],
  },
  {
    image: noNose,
    correctAnswers: [
      {
        id: "noseSmall",
        dropZone: { top: "57%", left: "35%", width: "27%", height: "26%" },
        placement: { top: "59%", left: "41%", width: "15%", height: "20%" },
      },
    ],
  },

  {
    image: noBlush,
    correctAnswers: [
      {
        id: "blushSmall",
        dropZone: { top: "57%", left: "18%", width: "58%", height: "28%" },
        placement: { top: "60%", left: "19%", width: "61%", height: "19%" },
      },
    ],
  },

  {
    image: noMouth,
    correctAnswers: [
      {
        id: "mouthSmall",
        dropZone: { top: "77%", left: "25%", width: "50%", height: "15%" },
        placement: { top: "77%", left: "27%", width: "40%", height: "18%" },
      },
    ],
  },

  {
    image: noBrows,
    correctAnswers: [
      {
        id: "browsSmall",
        dropZone: { top: "35%", left: "14%", width: "66%", height: "17%" },
        placement: { top: "37%", left: "15%", width: "66%", height: "11%" },
      },
    ],
  },
];

const CreateFaceGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [placedParts, setPlacedParts] = useState([]);

  const currentTask = tasks[currentTaskIndex];

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const secondInstructionAudioRef = useRef(new Audio(secondInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const correctAnswerSoundRef = useRef(new Audio(correctAnswerSound));
  const incorrectAnswerSoundRef = useRef(new Audio(incorrectAnswerSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));

  const handleNextTask = () => {
    setIsInteractionBlocked(true);

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        if (backgroundAudioRef.current) {
          backgroundAudioRef.current.loop = true;
          backgroundAudioRef.current.play();
        }
      };
    }

    setIsButtonVisible(false);
  };

  const getUniqueIdsCount = (answers) => {
    const uniqueIds = new Set(answers.map((answer) => answer.id));
    return uniqueIds.size;
  };

  const normalParts = [
    { id: "eyes", src: eyes, size: "90%" },
    { id: "ear", src: ears, size: "90%" },
    { id: "hair", src: hair, size: "90%" },
    { id: "nose", src: nose, size: "50%" },
    { id: "mouth", src: mouth, size: "65%" },
    { id: "brows", src: brows, size: "80%" },
    { id: "blush", src: blush, size: "80%" },
  ];

  const smallParts = [
    { id: "oneEye", src: oneEye, size: "90%" },
    { id: "earSmall", src: earsTwo, size: "90%" },
    { id: "hairSmall", src: hairTwo, size: "90%" },
    { id: "noseSmall", src: noseTwo, size: "50%" },
    { id: "mouthSmall", src: mouthTwo, size: "65%" },
    { id: "browsSmall", src: browsTwo, size: "80%" },
    { id: "blushSmall", src: brushTwo, size: "80%" },
  ];

  const parts = currentTaskIndex === 0 ? normalParts : smallParts;

  const playSecondInstruction = () => {
    setIsInteractionBlocked(true);

    if (secondInstructionAudioRef.current) {
      secondInstructionAudioRef.current.play();
      secondInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
      };
    } else {
      setIsInteractionBlocked(false);
    }
  };

  const completeGame = () => {
    if (praiseSoundRef.current) {
      praiseSoundRef.current.play();
    }

    triggerFireworks();

    setTimeout(() => {
      setIsGameCompleted(true);
    }, 3000);
  };

  const handleDrop = (droppedItemId) => {
    const task = currentTask;
    const taskIndex = currentTaskIndex;

    const correctAnswer = task.correctAnswers.find(
      (answer) => answer.id === droppedItemId
    );

    if (correctAnswer) {
      if (correctAnswerSoundRef.current) {
        correctAnswerSoundRef.current.play();
      }
      if (
        !placedParts.some(
          (part) => part.id === droppedItemId && part.taskIndex === taskIndex
        )
      ) {
        setPlacedParts((prevPlacedParts) => {
          const newPlacedParts = [
            ...prevPlacedParts,
            {
              id: droppedItemId,
              src: parts.find((part) => part.id === droppedItemId).src,
              position: correctAnswer.placement,
              taskIndex: taskIndex,
            },
          ];

          const uniquePlacedIds = new Set(
            newPlacedParts
              .filter((part) => part.taskIndex === taskIndex)
              .map((part) => part.id)
          );

          const totalRequiredParts = getUniqueIdsCount(task.correctAnswers);

          if (uniquePlacedIds.size === totalRequiredParts) {
            setTimeout(() => {
              if (taskIndex < tasks.length - 1) {
                setPlacedParts([]);
                setCurrentTaskIndex(taskIndex + 1);
                playSecondInstruction();
              } else {
                completeGame();
              }
            }, 2000);
          }

          return newPlacedParts;
        });
      }
    } else {
      if (taskIndex === 0 && incorrectAnswerSoundRef.current) {
        incorrectAnswerSoundRef.current.play();
      }
    }
  };

  useEffect(() => {
    document.body.style.backgroundColor = "rgba(252, 229, 170, 1)";

    return () => {
      if (startInstructionAudioRef.current) {
        startInstructionAudioRef.current.pause();
        startInstructionAudioRef.current.currentTime = 0;
      }
      if (secondInstructionAudioRef.current) {
        secondInstructionAudioRef.current.pause();
        secondInstructionAudioRef.current.currentTime = 0;
      }
      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.pause();
        backgroundAudioRef.current.currentTime = 0;
      }
      if (correctAnswerSoundRef.current) {
        correctAnswerSoundRef.current.pause();
        correctAnswerSoundRef.current.currentTime = 0;
      }
      if (incorrectAnswerSoundRef.current) {
        incorrectAnswerSoundRef.current.pause();
        incorrectAnswerSoundRef.current.currentTime = 0;
      }
      if (praiseSoundRef.current) {
        praiseSoundRef.current.pause();
        praiseSoundRef.current.currentTime = 0;
      }

      gsap.globalTimeline.clear();
      document.body.style.backgroundColor = null;
    };
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        <h4 className={styles.subHeader}>
          Задача: {currentTaskIndex + 1}/{tasks.length}
        </h4>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <div className={styles.leftColumn}>
            {parts.slice(0, 3).map((part) => (
              <div
                key={`${part.id}-${currentTaskIndex}`}
                className={styles.partWrapper}
              >
                <img src={cover} alt="рамка" className={styles.cover} />
                <DraggableItem
                  id={part.id}
                  src={part.src}
                  alt="часть тела"
                  className={styles.partImage}
                  style={{ width: part.size }}
                />
              </div>
            ))}
          </div>
          <div className={styles.centerColumn}>
            <div className={styles.centralImageWrapper}>
              <img
                src={currentTask.image}
                alt="лицо"
                className={styles.centralImage}
              />

              {currentTask.correctAnswers &&
                currentTask.correctAnswers.map((answer, index) => (
                  <DropTarget
                    key={`${index}-${currentTaskIndex}`}
                    targetId={answer.id}
                    onDrop={handleDrop}
                    style={{
                      position: "absolute",
                      top: answer.dropZone.top,
                      left: answer.dropZone.left,
                      width: answer.dropZone.width,
                      height: answer.dropZone.height,
                      zIndex: 1,
                    }}
                  />
                ))}
              {placedParts
                .filter((part) => part.taskIndex === currentTaskIndex)
                .map((part, index) => (
                  <img
                    key={`${part.id}-${index}-${currentTaskIndex}`}
                    src={part.src}
                    alt={part.id}
                    style={{
                      position: "absolute",
                      top: part.position.top,
                      left: part.position.left,
                      width: part.position.width,
                      height: part.position.height,
                    }}
                  />
                ))}
            </div>
          </div>
          <div className={styles.rightColumn}>
            {parts.slice(3, 6).map((part) => (
              <div
                key={`${part.id}-${currentTaskIndex}`}
                className={styles.partWrapper}
              >
                <img src={cover} alt="рамка" className={styles.cover} />
                <DraggableItem
                  id={part.id}
                  src={part.src}
                  alt="часть тела"
                  className={styles.partImage}
                  style={{ width: part.size }}
                />
              </div>
            ))}
          </div>
        </div>

        <div className={styles.bottomRow}>
          <div
            key={`${parts[6].id}-${currentTaskIndex}`}
            className={styles.partWrapper}
          >
            <img src={cover} alt="рамка" className={styles.cover} />
            <DraggableItem
              id={parts[6].id}
              src={parts[6].src}
              alt="часть тела"
              className={styles.partImage}
              style={{ width: parts[6].size }}
            />
          </div>
          <div className={styles.fireworksContainer}></div>
        </div>
        <audio ref={audioRef} />
        {isButtonVisible && (
          <div className={styles.darkOverlay}>
            <button className={styles.button} onClick={handleNextTask}>
              {buttonText}
            </button>
          </div>
        )}
      </div>
    </DndProvider>
  );
};

export default CreateFaceGame;
