import React, { useState, useRef, useEffect } from "react";
import EndGameOptions from "../../end-game-options/end-game-options";
import Confetti from "react-confetti";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableItem from "./DraggableItem";
import DropTarget from "./DropTarget";
import styles from "./girl.module.css";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import cover from "./images/cover.svg";
import girlNoBody from "./images/girlNoBody.png";
import girlNoEar from "./images/girlNoEar.png";
import girlNoEye from "./images/girlNoEye.png";
import girlNoHair from "./images/girlNoHair.png";
import girlNoHand from "./images/girlNoHand.png";
import girlNoLeg from "./images/girlNoLeg.png";
import girlNoNose from "./images/girlNoNose.png";

import girlBody from "./images/bodyGirl.png";
import girlEar from "./images/earGirl.png";
import girlEye from "./images/eyeGirl.png";
import girlHair from "./images/hairGirl.png";
import girlHand from "./images/handGirl.png";
import girlLeg from "./images/legGirl.png";
import girlNose from "./images/noseGirl.png";

const tasks = [
  {
    image: girlNoEye,
    correctAnswer: "eye",
    dropZone: { top: "28%", left: "23%", width: "20%", height: "10%" },
    placement: { top: "27%", left: "21%", width: "22%", height: "12%" },
  },
  {
    image: girlNoHand,
    correctAnswer: "hand",
    dropZone: { top: "48%", left: "5%", width: "40%", height: "10%" },
    placement: { top: "48%", left: "0%", width: "43%", height: "24%" },
  },
  {
    image: girlNoBody,
    correctAnswer: "body",
    dropZone: { top: "45%", left: "15%", width: "60%", height: "40%" },
    placement: { top: "39%", left: "-5%", width: "100%", height: "47%" },
  },
  {
    image: girlNoNose,
    correctAnswer: "nose",
    dropZone: { top: "32%", left: "38%", width: "15%", height: "8%" },
    placement: { top: "35%", left: "39%", width: "14%", height: "4%" },
  },
  {
    image: girlNoHair,
    correctAnswer: "hair",
    dropZone: { top: "14%", left: "0%", width: "100%", height: "30%" },
    placement: { top: "0%", left: "0%", width: "100%", height: "53%" },
  },
  {
    image: girlNoEar,
    correctAnswer: "ear",
    dropZone: { top: "30%", left: "2%", width: "20%", height: "10%" },
    placement: { top: "30%", left: "1%", width: "23%", height: "14%" },
  },
  {
    image: girlNoLeg,
    correctAnswer: "leg",
    dropZone: { top: "75%", left: "10%", width: "30%", height: "25%" },
    placement: { top: "75%", left: "10%", width: "32%", height: "25%" },
  },
];

const GirlAssemblyComponent = ({ audioRefs }) => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [placedParts, setPlacedParts] = useState([]);
  const [showConfetti, setShowConfetti] = useState(false);

  const currentTask = tasks[currentTaskIndex];

  const parts = [
    { id: "body", src: girlBody, size: "100%" },
    { id: "ear", src: girlEar, size: "40%" },
    { id: "eye", src: girlEye, size: "30%" },
    { id: "hair", src: girlHair, size: "100%" },
    { id: "hand", src: girlHand, size: "70%" },
    { id: "leg", src: girlLeg, size: "55%" },
    { id: "nose", src: girlNose, size: "40%" },
  ];

  const audioRef = useRef(null);
  const {
    secondInstructionAudioRef,
    thirdInstructionAudioRef,
    correctAnswerAudioRef,
    incorrectAnswerAudioRef,
    praiseAudioRef,
  } = audioRefs;

  useEffect(() => {
    setIsInteractionBlocked(true);

    if (secondInstructionAudioRef.current) {
      secondInstructionAudioRef.current.play();
      secondInstructionAudioRef.current.onended = () => {
        if (thirdInstructionAudioRef.current) {
          thirdInstructionAudioRef.current.play();
          thirdInstructionAudioRef.current.onended = () => {
            setIsInteractionBlocked(false);
          };
        } else {
          setIsInteractionBlocked(false);
        }
      };
    } else {
      setIsInteractionBlocked(false);
    }
  }, []);

  const handleDrop = (droppedItemId) => {
    const task = currentTask;
    const taskIndex = currentTaskIndex;

    if (droppedItemId === task.correctAnswer) {
      if (correctAnswerAudioRef.current) {
        correctAnswerAudioRef.current.play();
      }
      setIsInteractionBlocked(true);
      setPlacedParts((prevPlacedParts) => [
        ...prevPlacedParts,
        {
          id: droppedItemId,
          src: parts.find((part) => part.id === droppedItemId).src,
          position: task.placement,
          taskIndex: taskIndex,
        },
      ]);

      setTimeout(() => {
        if (taskIndex < tasks.length - 1) {
          setCurrentTaskIndex(taskIndex + 1);
          if (thirdInstructionAudioRef.current) {
            thirdInstructionAudioRef.current.play();
            thirdInstructionAudioRef.current.onended = () => {
              setIsInteractionBlocked(false);
            };
          } else {
            setIsInteractionBlocked(false);
          }
        } else {
          if (praiseAudioRef.current) {
            praiseAudioRef.current.play();
          }

          setShowConfetti(true);

          setTimeout(() => {
            setIsGameCompleted(true);
          }, 3000);
        }
      }, 2000);
    } else {
      incorrectAnswerAudioRef.current.play();
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      {isGameCompleted && <EndGameOptions />}
      {showConfetti && <Confetti />}
      <div className={`container ${styles.someOtherClass}`}>
        <h4 className={styles.subHeader}>
          Задача: {currentTaskIndex + 1}/{tasks.length}
        </h4>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <div className={styles.leftColumn}>
            {parts.slice(0, 3).map((part, index) => (
              <div key={index} className={styles.partWrapper}>
                <img src={cover} alt="рамка" className={styles.cover} />
                <DraggableItem
                  id={part.id}
                  src={part.src}
                  alt="часть тела"
                  className={styles.partImage}
                  style={{ width: part.size }}
                />
              </div>
            ))}
          </div>
          <div className={styles.centerColumn}>
            <div className={styles.centralImageWrapper}>
              <img
                src={currentTask.image}
                alt="девочка"
                className={styles.centralImage}
              />
              <DropTarget
                targetId={currentTask.correctAnswer}
                onDrop={handleDrop}
                style={{
                  position: "absolute",
                  top: currentTask.dropZone.top,
                  left: currentTask.dropZone.left,
                  width: currentTask.dropZone.width,
                  height: currentTask.dropZone.height,
                  zIndex: 1,
                }}
              />
              {placedParts
                .filter((part) => part.taskIndex === currentTaskIndex)
                .map((part, index) => (
                  <img
                    key={index}
                    src={part.src}
                    alt={part.id}
                    style={{
                      position: "absolute",
                      top: part.position.top,
                      left: part.position.left,
                      width: part.position.width,
                      height: part.position.height,
                      zIndex: 2,
                    }}
                  />
                ))}
            </div>
          </div>
          <div className={styles.rightColumn}>
            {parts.slice(3, 6).map((part, index) => (
              <div key={index} className={styles.partWrapper}>
                <img src={cover} alt="рамка" className={styles.cover} />
                <DraggableItem
                  id={part.id}
                  src={part.src}
                  alt="часть тела"
                  className={styles.partImage}
                  style={{ width: part.size }}
                />
              </div>
            ))}
          </div>
        </div>

        <div className={styles.bottomRow}>
          <div className={styles.partWrapper}>
            <img src={cover} alt="рамка" className={styles.cover} />
            <DraggableItem
              id={parts[6].id}
              src={parts[6].src}
              alt="часть тела"
              className={styles.partImage}
              style={{ width: parts[6].size }}
            />
          </div>
        </div>
        <audio ref={audioRef} />
      </div>
    </DndProvider>
  );
};

export default GirlAssemblyComponent;
