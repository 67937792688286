import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import styles from "./most-attentive.module.css";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";

import dynamicImg from "./images/dynamic.png";
import dynamicOff from "./images/dynamicOff.png";
import repeatTaskImg from "./images/repeatTask.png";
import boyBall from "./images/boyBall.png";
import boyCat from "./images/boyCat.png";
import boySquirell from "./images/boySquirell.png";
import boyWolf from "./images/boyWolf.png";
import catSleep from "./images/catSleep.png";
import crab from "./images/crab.png";
import crowCheese from "./images/crowCheese.png";
import dogBall from "./images/dogBall.png";
import donkey from "./images/donkey.png";
import father from "./images/father.png";
import girlDog from "./images/girlDog.png";
import grandma from "./images/grandma.png";
import grandpa from "./images/grandpa.png";
import motherCat from "./images/motherCat.png";
import rabbit from "./images/rabbit.png";
import sharks from "./images/sharks.png";
import tree from "./images/tree.png";
import treeHedgehog from "./images/treeHedgehog.png";
import treeMushroom from "./images/treeMushroom.png";
import turtle from "./images/turtle.png";
import wolf from "./images/wolf.png";

import startInstructionSound from "./sounds/startInstruction.mp3";
import seconInstructionSound from "./sounds/secondInstruction.mp3";
import taskThreeSound from "./sounds/taskThree.mp3";
import taskFourSound from "./sounds/taskFour.mp3";
import taskFiveSound from "./sounds/taskFive.mp3";
import taskSixSound from "./sounds/taskSix.mp3";
import taskSevenSound from "./sounds/taskSeven.mp3";
import taskEightSound from "./sounds/taskEight.mp3";
import taskNineSound from "./sounds/taskNine.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import correctAnswerSound from "./sounds/yes.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";
import praiseSound from "./sounds/praise.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const tasks = [
  {
    images: [
      { src: dogBall, alt: "собака" },
      { src: crowCheese, alt: "ворона" },
      { src: boyBall, alt: "мальчик" },
    ],
    correctAnswer: "ворона",
  },
  {
    images: [
      { src: motherCat, alt: "мама" },
      { src: catSleep, alt: "кошка" },
      { src: girlDog, alt: "девочка" },
    ],
    correctAnswer: "мама",
  },
  {
    images: [
      { src: boySquirell, alt: "мальчик" },
      { src: boyCat, alt: "мальчиккошка" },
      { src: boyWolf, alt: "мальчикволк" },
    ],
    correctAnswer: "мальчикволк",
  },

  {
    images: [
      { src: treeMushroom, alt: "мухомор" },
      { src: tree, alt: "дерево" },
      { src: treeHedgehog, alt: "ежик" },
    ],
    correctAnswer: "дерево",
  },
  {
    images: [
      { src: sharks, alt: "акулы" },
      { src: turtle, alt: "черепаха" },
      { src: crab, alt: "краб" },
    ],
    correctAnswer: "акулы",
  },
  {
    images: [
      { src: grandpa, alt: "дедушка" },
      { src: grandma, alt: "бабушка" },
      { src: father, alt: "папа" },
    ],
    correctAnswer: "бабушка",
  },
  {
    images: [
      { src: rabbit, alt: "заяц" },
      { src: donkey, alt: "осел" },
      { src: wolf, alt: "волк" },
    ],
    correctAnswer: "заяц",
  },
];

const MostAttentiveGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [isSoundOn, setIsSoundOn] = useState(true);

  const currentTask = tasks[currentTaskIndex];

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const secondInstructionAudioRef = useRef(new Audio(seconInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const correctAnswerSoundRef = useRef(new Audio(correctAnswerSound));
  const incorrectAnswerSoundRef = useRef(new Audio(incorrectAnswerSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));
  const instructionAudioRef = useRef(null);

  const imageRefs = useRef([]);
  imageRefs.current = [];

  const addToRefs = (el) => {
    if (el && !imageRefs.current.includes(el)) {
      imageRefs.current.push(el);
    }
  };

  const handleImageClick = (imageAlt, index) => {
    if (isInteractionBlocked) return;

    if (imageAlt === currentTask.correctAnswer) {
      setIsInteractionBlocked(true);

      if (correctAnswerSoundRef.current) {
        correctAnswerSoundRef.current.play();
      }

      // Скрываем остальные изображения
      imageRefs.current.forEach((img, idx) => {
        if (idx !== index) {
          gsap.to(img, { opacity: 0, duration: 0.5 });
        }
      });

      // Анимация перемещения и увеличения с мягкой зеленой тенью
      const image = imageRefs.current[index];
      const rect = image.getBoundingClientRect();
      const centerX = window.innerWidth / 2;
      const centerY = window.innerHeight / 2;

      const deltaX = centerX - (rect.left + rect.width / 2);
      const deltaY = centerY - (rect.top + rect.height / 2);

      gsap.to(image, {
        x: deltaX,
        y: deltaY,
        scale: 1.3, // Увеличиваем изображение не сильно
        boxShadow: "0px 0px 30px 15px rgba(0, 255, 0, 0.5)", // Мягкая зеленая тень
        duration: 1,
        ease: "power2.out",
        transformOrigin: "center center",
        onStart: () => {
          gsap.set(image.parentElement, { perspective: 800 });
          gsap.set(image, { zIndex: 1000 });
        },
        onComplete: () => {
          gsap.set(image.parentElement, { perspective: 0 });

          // Ждем 3 секунды и скрываем изображение
          setTimeout(() => {
            gsap.to(image, {
              opacity: 0,
              duration: 1,
              onComplete: () => {
                if (currentTaskIndex < tasks.length - 1) {
                  setCurrentTaskIndex((prev) => prev + 1);
                  setIsInteractionBlocked(false);

                  imageRefs.current.forEach((img) => {
                    gsap.set(img, {
                      x: 0,
                      y: 0,
                      scale: 1,
                      opacity: 1,
                      zIndex: 1,
                      boxShadow: "none", // Убираем тень
                    });
                  });
                } else {
                  if (praiseSoundRef.current) {
                    praiseSoundRef.current.play();
                  }

                  triggerFireworks();
                  setTimeout(() => {
                    setIsGameCompleted(true);
                  }, 3000);
                }
              },
            });
          }, 3000);
        },
      });
    } else {
      if (incorrectAnswerSound && isSoundOn) {
        const incorrectAudio = new Audio(incorrectAnswerSound);
        incorrectAudio.play();
      }
    }
  };

  const handleNextTask = () => {
    setIsInteractionBlocked(true);

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        if (secondInstructionAudioRef.current) {
          secondInstructionAudioRef.current.play();
        }
        secondInstructionAudioRef.current.onended = () => {
          const instructionSound = getInstructionSound(currentTaskIndex);
          if (instructionSound) {
            instructionAudioRef.current = new Audio(instructionSound);
            instructionAudioRef.current.play();
            instructionAudioRef.current.onended = () => {
              setIsInteractionBlocked(false);
              if (backgroundAudioRef.current) {
                backgroundAudioRef.current.loop = true;
                backgroundAudioRef.current.play();
              }
            };
          } else {
            setIsInteractionBlocked(false);
            if (backgroundAudioRef.current) {
              backgroundAudioRef.current.loop = true;
              backgroundAudioRef.current.play();
            }
          }
        };
      };
    }

    setIsButtonVisible(false);
  };

  useEffect(() => {
    if (
      [1, 2, 3, 4, 5, 6].includes(currentTaskIndex) &&
      !isButtonVisible &&
      !isGameCompleted
    ) {
      setIsInteractionBlocked(true);
      const instructionSound = getInstructionSound(currentTaskIndex);
      if (instructionSound) {
        instructionAudioRef.current = new Audio(instructionSound);
        instructionAudioRef.current.play();
        instructionAudioRef.current.onended = () => {
          setIsInteractionBlocked(false);
        };
      } else {
        setIsInteractionBlocked(false);
      }
    }
  }, [currentTaskIndex, isButtonVisible, isGameCompleted]);

  const handleSoundOnOff = () => {
    setIsSoundOn((prevIsSoundOn) => {
      const newIsSoundOn = !prevIsSoundOn;

      if (startInstructionAudioRef.current) {
        startInstructionAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (secondInstructionAudioRef.current) {
        secondInstructionAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (correctAnswerSoundRef.current) {
        correctAnswerSoundRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (incorrectAnswerSoundRef.current) {
        incorrectAnswerSoundRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (praiseSoundRef.current) {
        praiseSoundRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (instructionAudioRef.current) {
        instructionAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      return newIsSoundOn;
    });
  };

  const handleRepeatTask = () => {
    if (instructionAudioRef.current) {
      instructionAudioRef.current.currentTime = 0;
      instructionAudioRef.current.play();
    }
  };

  const getInstructionSound = (taskIndex) => {
    if (taskIndex === 0) {
      return taskThreeSound;
    } else if (taskIndex === 1) {
      return taskFourSound;
    } else if (taskIndex === 2) {
      return taskFiveSound;
    } else if (taskIndex === 3) {
      return taskSixSound;
    } else if (taskIndex === 4) {
      return taskSevenSound;
    } else if (taskIndex === 5) {
      return taskEightSound;
    } else if (taskIndex === 6) {
      return taskNineSound;
    }
    return null;
  };

  useEffect(() => {
    document.body.style.backgroundColor = "rgba(252, 229, 170, 1)";

    return () => {
      if (startInstructionAudioRef.current) {
        startInstructionAudioRef.current.pause();
        startInstructionAudioRef.current.currentTime = 0;
      }

      if (secondInstructionAudioRef.current) {
        secondInstructionAudioRef.current.pause();
        secondInstructionAudioRef.current.currentTime = 0;
      }

      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.pause();
        backgroundAudioRef.current.currentTime = 0;
      }
      if (correctAnswerSoundRef.current) {
        correctAnswerSoundRef.current.pause();
        correctAnswerSoundRef.current.currentTime = 0;
      }
      if (incorrectAnswerSoundRef.current) {
        incorrectAnswerSoundRef.current.pause();
        incorrectAnswerSoundRef.current.currentTime = 0;
      }
      if (praiseSoundRef.current) {
        praiseSoundRef.current.pause();
        praiseSoundRef.current.currentTime = 0;
      }
      if (instructionAudioRef.current) {
        instructionAudioRef.current.pause();
        instructionAudioRef.current.currentTime = 0;
      }
      document.body.style.backgroundColor = null;

      gsap.globalTimeline.clear();
    };
  }, []);

  return (
    <>
      {isGameCompleted && <EndGameOptions />}

      <div className={`container ${styles.someOtherClass}`}>
        <div className={styles.backgroundContainer}>
          <div className={styles.absoluteContainer}>
            <h4 className={styles.headerCounter}>
              Вопрос {currentTaskIndex + 1} из {tasks.length}
            </h4>

            <div
              className={styles.imagesRow}
              style={{
                pointerEvents: isInteractionBlocked ? "none" : "auto",
              }}
            >
              {currentTask.images.map((image, index) => (
                <img
                  key={index}
                  ref={addToRefs}
                  className={styles.taskImage}
                  src={image.src}
                  alt={image.alt}
                  onClick={() => handleImageClick(image.alt, index)}
                />
              ))}
            </div>

            <div className={styles.buttonsContainer}>
              <button
                className={styles.buttonsRepeatAndSoundOnOff}
                onClick={handleSoundOnOff}
              >
                <img src={isSoundOn ? dynamicImg : dynamicOff} alt="динамик" />
                <span className={styles.tooltip}>
                  Включить / выключить звук
                </span>
              </button>

              <button
                className={styles.buttonsRepeatAndSoundOnOff}
                onClick={handleRepeatTask}
              >
                <img src={repeatTaskImg} alt="повтор звука" />
                <span className={styles.tooltip}>
                  Нажмите для повторного прослушивания инструкции
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className={styles.fireworksContainer}></div>
      </div>

      <audio ref={audioRef} />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.button} onClick={handleNextTask}>
            {buttonText}
          </button>
        </div>
      )}
    </>
  );
};

export default MostAttentiveGame;
