import React, { useState, useEffect, useRef } from "react";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableItem from "./DraggableItem";
import DropTarget from "./DropTarget";
import styles from "./studying-oval.module.css";

import oval from "./images/oval.png";
import circleBaloonOne from "./images/circleBaloonOne.png";
import circleBaloonTwo from "./images/circleBaloonTwo.png";
import circleBaloonThree from "./images/circleBaloonThree.png";
import circleBaloonFour from "./images/circleBaloonOne.png";
import ovalBaloonOne from "./images/ovalBaloonOne.png";
import ovalBaloonTwo from "./images/ovalBaloonTwo.png";
import ovalBaloonThree from "./images/ovalBaloonThree.png";
import rhombBaloonOne from "./images/rhombBaloonOne.png";
import rhombBaloonTwo from "./images/rhombBaloonTwo.png";
import rhombBaloonThree from "./images/rhombBaloonThree.png";
import squareBaloonOne from "./images/squareBaloonOne.png";
import squareBaloonTwo from "./images/squareBaloonOne.png";
import ball from "./images/ball.png";
import bell from "./images/bell.png";
import bread from "./images/bread.png";
import donat from "./images/donat.png";
import gift from "./images/gift.png";
import mirror from "./images/mirror.png";
import regbyBall from "./images/regbyBall.png";
import sausage from "./images/sausage.png";
import sign from "./images/sign.png";
import apple from "./images/apple.png";
import blueOval from "./images/blueOval.svg";
import greenOval from "./images/greenOval.svg";
import mushroom from "./images/mushroom.png";
import orangeOval from "./images/orangeOval.svg";
import rectangle from "./images/rectangle.svg";
import redOval from "./images/redOval.svg";
import squirell from "./images/squirell.png";
import birOval from "./images/birOval.svg";
import blackCircle from "./images/blackCircle.svg";
import blackOval from "./images/blackOval.svg";
import squirellFly from "./images/squirellFly.png";
import squirellMushroom from "./images/squirellMushroom.png";
import baloonDown from "./images/baloon.png";
import baloonUp from "./images/baloonSecondPart.png";
import catDown from "./images/cat.png";
import catUp from "./images/catSecondPart.svg";
import fishDown from "./images/fish.png";
import fishUp from "./images/fishSecondPart.svg";
import frogDown from "./images/frog.png";
import frogUp from "./images/frogSecondPart.svg";
import jarDown from "./images/jar.png";
import jarUp from "./images/jarSecondPart.svg";
import sunflowerDown from "./images/sunflower.png";
import sunflowerUp from "./images/sunflowerSecondPart.svg";

import backgroundSound from "./sounds/backgroundSound.mp3";
import incorrectAnswerSound from "./sounds/no.mp3";
import correctAnswerSound from "./sounds/correctAnswer.mp3";

import taskZeroSound from "./sounds/taskZero.mp3";
import taskTwoSound from "./sounds/taskTwo.mp3";
import taskThreeSound from "./sounds/taskThree.mp3";
import taskFourSound from "./sounds/taskFour.mp3";
import taskFiveSound from "./sounds/taskFive.mp3";

import baloonSound from "./sounds/baloon.mp3";
import coloredOvalSound from "./sounds/colored.mp3";
import squirrelSound from "./sounds/squirellFly.mp3";

import praiseSound from "./sounds/praise.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const StudyingOvalGame = () => {
  useDynamicHeight();
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(true);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(1);
  const [buttonText, setButtonText] = useState("Послушать");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [selectedOvals, setSelectedOvals] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [foundOvalCount, setFoundOvalCount] = useState(0);
  const [droppedTriangles, setDroppedTriangles] = useState([]);
  const [visibleBalloons, setVisibleBalloons] = useState({
    baloonOne: true,
    baloonTwo: true,
    baloonSeven: true,
    baloonTen: true,
    baloonTenSecond: true,
    baloonTenThird: true,
    baloonFifteen: true,
  });
  gsap.registerPlugin(MotionPathPlugin);

  const [coloredOvals, setColoredOvals] = useState({});
  const [droppedItems, setDroppedItems] = useState([]);

  const audioRef = useRef(null);
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const taskZeroSoundRef = useRef(new Audio(taskZeroSound));
  const taskTwoSoundRef = useRef(new Audio(taskTwoSound));
  const taskThreeSoundRef = useRef(new Audio(taskThreeSound));
  const taskFourSoundRef = useRef(new Audio(taskFourSound));
  const taskFiveSoundRef = useRef(new Audio(taskFiveSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));
  const correctSoundRef = useRef(new Audio(correctAnswerSound));
  const incorrectSoundRef = useRef(new Audio(incorrectAnswerSound));
  const baloonSoundRef = useRef(new Audio(baloonSound));
  const coloredOvalSoundRef = useRef(new Audio(coloredOvalSound));
  const squirrelSoundRef = useRef(new Audio(squirrelSound));

  const tasks = [
    {
      question: "Это овал",
      images: [{ src: oval, alt: "овал", class: styles.oval }],
      audioRef: taskZeroSoundRef,
    },
    {
      question: "Лопни овальные шарики",
      images: [
        {
          src: circleBaloonOne,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "circleBaloonOne",
        },
        {
          src: ovalBaloonOne,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "ovalBaloonOne",
        },
        {
          src: rhombBaloonOne,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "rhombBaloonOne",
        },
        {
          src: squareBaloonOne,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "squareBaloonOne",
        },
        {
          src: circleBaloonTwo,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "circleBaloonTwo",
        },
        {
          src: ovalBaloonTwo,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "ovalBaloonTwo",
        },
        {
          src: circleBaloonThree,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "circleBaloonThree",
        },
        {
          src: rhombBaloonTwo,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "rhombBaloonTwo",
        },
        {
          src: circleBaloonFour,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "circleBaloonFour",
        },
        {
          src: ovalBaloonThree,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "ovalBaloonThree",
        },
        {
          src: rhombBaloonThree,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "rhombBaloonThree",
        },
        {
          src: squareBaloonTwo,
          alt: "Воздушный шарик",
          class: styles.baloon,
          isBalloon: true,
          id: "squareBaloonOne",
        },
      ],
      audioRef: taskTwoSoundRef,
    },
    {
      question: "Найди предметы похожие на овал",
      images: [
        {
          src: oval,
          alt: "овал",
          class: styles.largeOval,
        },
        {
          src: ball,
          alt: "мяч",
          class: styles.ball,
          isOval: false,
        },
        {
          src: bell,
          alt: "колокол",
          class: styles.bell,
          isOval: false,
        },
        {
          src: bread,
          alt: "хлеб",
          class: styles.bread,
          isOval: true,
        },
        {
          src: donat,
          alt: "пончик",
          class: styles.donat,
          isOval: false,
        },
        {
          src: gift,
          alt: "подарок",
          class: styles.gift,
          isOval: false,
        },
        {
          src: mirror,
          alt: "зеркало",
          class: styles.mirror,
          isOval: true,
        },
        {
          src: regbyBall,
          alt: "регбийный мяч",
          class: styles.regbyBall,
          isOval: false,
        },
        {
          src: sausage,
          alt: "колбаса",
          class: styles.sausage,
          isOval: true,
        },
        {
          src: sign,
          alt: "знак",
          class: styles.sign,
          isOval: false,
        },
        {
          src: apple,
          alt: "яблоко",
          class: styles.apple,
          isOval: false,
        },
      ],
      audioRef: taskThreeSoundRef,
    },
    {
      question: "Кликом мыши раскрась овалы, чтобы белка добралась до гриба",
      images: [
        {
          src: rectangle,
          alt: "прямоугольник",
          class: styles.blackRectangle,
        },
        {
          src: rectangle,
          alt: "прямоугольник",
          class: styles.blackRectangleTwo,
        },
        {
          src: rectangle,
          alt: "прямоугольник",
          class: styles.blackRectangleThree,
        },
        {
          src: rectangle,
          alt: "прямоугольник",
          class: styles.blackRectangleFour,
        },
        {
          src: rectangle,
          alt: "прямоугольник",
          class: styles.blackRectangleFive,
        },
        {
          src: blackCircle,
          alt: "круг",
          class: styles.blackCircle,
        },
        {
          src: blackCircle,
          alt: "круг",
          class: styles.blackCircleTwo,
        },
        {
          src: blackCircle,
          alt: "круг",
          class: styles.blackCircleThree,
        },
        {
          src: blackCircle,
          alt: "круг",
          class: styles.blackCircleFour,
        },
        {
          src: blackOval,
          alt: "овал",
          class: styles.blackOval,
          id: "oval1",
        },
        {
          src: blackOval,
          alt: "овал",
          class: styles.blackOvalTwo,
          id: "oval2",
        },
        {
          src: blackOval,
          alt: "овал",
          class: styles.blackOvalThree,
          id: "oval3",
        },
        {
          src: blackOval,
          alt: "овал",
          class: styles.blackOvalFour,
          id: "oval4",
        },
        {
          src: blackOval,
          alt: "овал",
          class: styles.blackOvalFive,
          id: "oval5",
        },
        {
          src: squirell,
          alt: "белка",
          class: styles.squirell,
        },
        {
          src: mushroom,
          alt: "гриб",
          class: styles.mushroom,
        },
      ],
      audioRef: taskFourSoundRef,
    },
    {
      question: "Расставь овальные предметы по своим местам и назови их",
      images: [
        {
          src: baloonUp,
          alt: "воздушный шар",
          class: styles.baloonUp,
          type: "UP",
        },
        {
          src: catUp,
          alt: "кот",
          class: styles.catUp,
          type: "UP",
        },
        { src: fishUp, alt: "рыба", class: styles.fishUp, type: "UP" },

        {
          src: frogUp,
          alt: "лягушка",
          class: styles.frogUp,
          type: "UP",
        },
        {
          src: jarUp,
          alt: "банка",
          class: styles.jarUp,
          type: "UP",
          coordinates: { top: "0%", left: "0%" },
        },
        {
          src: sunflowerUp,
          alt: "подсолнух",
          class: styles.sunflowerUp,
          type: "UP",
        },

        {
          src: sunflowerDown,
          alt: "подсолнух",
          class: styles.sunflowerDown,
          type: "DOWN",
          selectedWidth: "49%",
          coordinates: { top: "16%", left: "27%" },
        },
        {
          src: frogDown,
          alt: "лягушка",
          class: styles.frogDown,
          type: "DOWN",
          selectedWidth: "76%",
          coordinates: { top: "19%", left: "23%" },
        },

        {
          src: fishDown,
          alt: "рыба",
          class: styles.fishDown,
          selectedWidth: "61%",
          type: "DOWN",
          coordinates: { top: "13%", left: "32%" },
        },
        {
          src: baloonDown,
          alt: "воздушный шар",
          class: styles.baloonDown,
          type: "DOWN",
          coordinates: { top: "96%", left: "30%" },
          selectedWidth: "39%",
        },

        {
          src: jarDown,
          alt: "банка",
          class: styles.jarDown,
          type: "DOWN",
          selectedWidth: "46%",
          coordinates: { top: "3%", left: "27%" },
        },
        {
          src: catDown,
          alt: "кот",
          class: styles.catDown,
          type: "DOWN",
          selectedWidth: "72%",
          coordinates: { top: "4%", left: "8%" },
        },
      ],
      audioRef: taskFiveSoundRef,
    },
  ];

  const currentTask = tasks[currentTaskIndex];

  const playSound = () => {
    const taskAudioRef = currentTask.audioRef;

    if (taskAudioRef && taskAudioRef.current) {
      setIsInteractionBlocked(true);
      setButtonDisabled(true);

      taskAudioRef.current.play().catch((error) => {
        console.error("Playback failed:", error);
        setButtonDisabled(false);
        setIsInteractionBlocked(false);
      });

      taskAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        setButtonDisabled(false);
        setButtonText("Далее");
      };
    }
  };

  useEffect(() => {
    if (currentTaskIndex === 1) {
      const allOvalsSelected = selectedOvals.length === 3;
      setButtonDisabled(!allOvalsSelected && buttonText === "Далее");
    }
  }, [selectedOvals, currentTaskIndex, buttonText]);

  useEffect(() => {
    if (currentTaskIndex === 2) {
      const allItemsSelected = selectedItems.length === 3;
      setButtonDisabled(!allItemsSelected && buttonText === "Далее");
    }
  }, [selectedItems, currentTaskIndex, buttonText]);

  useEffect(() => {
    if (currentTaskIndex === 3) {
      const allOvalsColored = coloredOvals.length === 5;
      setButtonDisabled(!allOvalsColored && buttonText === "Далее");
    }
  }, [coloredOvals, currentTaskIndex, buttonText]);

  const handleNextTask = () => {
    if (buttonText === "Послушать") {
      setIsInteractionBlocked(true);
      playSound();
    } else if (buttonText === "Далее" && !buttonDisabled) {
      setImageOpacity(0);
      setTimeout(() => {
        if (currentTaskIndex + 1 < tasks.length) {
          setCurrentTaskIndex(currentTaskIndex + 1);
          setButtonText("Послушать");
          setButtonDisabled(false);
          setIsInteractionBlocked(true);
          if (backgroundAudioRef.current) {
            setTimeout(() => {
              backgroundAudioRef.current.play();
            }, 10);
          }
        }
        setImageOpacity(1);
      }, 500);
    }
  };

  const handleItemClick = (index, isOval) => {
    if (isOval && !selectedItems.includes(index)) {
      correctSoundRef.current.play();

      const newSelectedItems = [...selectedItems, index];
      setSelectedItems(newSelectedItems);
      setFoundOvalCount(newSelectedItems.length);
      if (newSelectedItems.length === 3) {
        setButtonDisabled(false);
        setButtonText("Далее");
      }
    } else {
      incorrectSoundRef.current.play();
    }
  };

  const handleBalloonClick = (balloonId) => {
    const correctBalloons = [
      "ovalBaloonOne",
      "ovalBaloonTwo",
      "ovalBaloonThree",
    ];

    if (correctBalloons.includes(balloonId)) {
      baloonSoundRef.current.play();

      setVisibleBalloons((prevState) => ({
        ...prevState,
        [balloonId]: "popped",
      }));

      setTimeout(() => {
        setVisibleBalloons((prevState) => {
          const newState = {
            ...prevState,
            [balloonId]: false,
          };

          const allRequiredBalloonsPopped = correctBalloons.every(
            (id) => newState[id] === false
          );

          if (allRequiredBalloonsPopped) {
            setButtonDisabled(false);
            setButtonText("Далее");
          }

          return newState;
        });
      }, 500);
    } else {
      incorrectSoundRef.current.play();
    }
  };

  const handleOvalClick = (id) => {
    const colorMapping = {
      oval1: blueOval,
      oval2: greenOval,
      oval3: orangeOval,
      oval4: redOval,
      oval5: birOval,
    };

    if (!colorMapping[id]) {
      incorrectSoundRef.current.play();
      return;
    }

    coloredOvalSoundRef.current.play();

    setColoredOvals((prev) => {
      const newColoredOvals = { ...prev };
      if (!newColoredOvals[id]) {
        newColoredOvals[id] = colorMapping[id];
      }

      const allOvalsColored =
        Object.keys(newColoredOvals).length ===
        Object.keys(colorMapping).length;

      if (allOvalsColored) {
        squirrelSoundRef.current.play();
        animateSquirrel();
      }

      return newColoredOvals;
    });
  };

  const animateSquirrel = () => {
    const squirrelElement = document.querySelector(`.${styles.squirell}`);
    const mushroomElement = document.querySelector(`.${styles.mushroom}`);
    const containerElement = document.querySelector(
      `.${styles.canvasContainer}`
    );

    if (squirrelElement && mushroomElement && containerElement) {
      squirrelElement.src = squirellFly;

      const squirrelRect = squirrelElement.getBoundingClientRect();
      const mushroomRect = mushroomElement.getBoundingClientRect();
      const containerRect = containerElement.getBoundingClientRect();
      const startX = squirrelRect.left - containerRect.left;
      const startY = squirrelRect.top - containerRect.top;
      const endX = mushroomRect.left - containerRect.left;
      const endY = mushroomRect.top - containerRect.top;

      const deltaX = endX - startX;
      const deltaY = endY - startY;

      gsap.to(squirrelElement, {
        duration: 2,
        x: deltaX,
        y: deltaY,
        onComplete: () => {
          mushroomElement.style.display = "none";
          squirrelElement.src = squirellMushroom;

          squirrelElement.onload = () => {
            const newSquirrelRect = squirrelElement.getBoundingClientRect();

            const offsetX = (newSquirrelRect.width - squirrelRect.width) / 2;
            const offsetY = (newSquirrelRect.height - squirrelRect.height) / 2;

            gsap.set(squirrelElement, {
              x: deltaX - offsetX,
              y: deltaY - offsetY,
            });
          };

          setButtonDisabled(false);
          setButtonText("Далее");
        },
      });
    }
  };

  const handleDrop = (item, targetItem) => {
    if (item.alt === targetItem.alt) {
      if (correctSoundRef.current) {
        correctSoundRef.current.pause();
        correctSoundRef.current.currentTime = 0;
        correctSoundRef.current.play();
      }

      const newItem = {
        ...item,
        coordinates: targetItem.coordinates,
        selectedWidth: targetItem.selectedWidth || "100%",
      };

      setSelectedItems((prevItems) => [...prevItems, newItem]);
      setDroppedItems((prevItems) => {
        const updatedDroppedItems = [...prevItems, item.alt];

        const totalRequiredItems = 6;
        if (updatedDroppedItems.length === totalRequiredItems) {
          praiseSoundRef.current.play();
          triggerFireworks();
          setTimeout(() => {
            setIsGameCompleted(true);
          }, 5000);
        }
        return updatedDroppedItems;
      });
    } else {
      incorrectSoundRef.current.play();
    }
  };

  useEffect(() => {
    document.body.style.backgroundColor = "rgba(252, 229, 170, 1)";

    return () => {
      if (taskZeroSoundRef.current) {
        taskZeroSoundRef.current.pause();
        taskZeroSoundRef.current.currentTime = 0;
      }

      if (taskTwoSoundRef.current) {
        taskTwoSoundRef.current.pause();
        taskTwoSoundRef.current.currentTime = 0;
      }

      if (taskThreeSoundRef.current) {
        taskThreeSoundRef.current.pause();
        taskThreeSoundRef.current.currentTime = 0;
      }

      if (taskFourSoundRef.current) {
        taskFourSoundRef.current.pause();
        taskFourSoundRef.current.currentTime = 0;
      }

      if (taskFiveSoundRef.current) {
        taskFiveSoundRef.current.pause();
        taskFiveSoundRef.current.currentTime = 0;
      }

      if (praiseSoundRef.current) {
        praiseSoundRef.current.pause();
        praiseSoundRef.current.currentTime = 0;
      }

      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.pause();
        backgroundAudioRef.current.currentTime = 0;
      }

      document.body.style.backgroundColor = "";
      gsap.globalTimeline.clear();
    };
  }, [currentTaskIndex]);
  return (
    <DndProvider backend={HTML5Backend}>
      {isGameCompleted && <EndGameOptions />}
      <div className={`container ${styles.someOtherClass}`}>
        <div
          className={styles.backgroundContainer}
          style={{
            pointerEvents: isInteractionBlocked ? "none" : "auto",
          }}
        >
          <h3 className={styles.header}>{currentTask.question}</h3>
          <div
            style={{
              opacity: imageOpacity,
              transition: "opacity 0.5s ease-in-out",
            }}
            className={styles.taskContainer}
          >
            {currentTaskIndex === 0 &&
              currentTask.images.map((img, index) => (
                <img
                  key={index}
                  src={img.src}
                  alt={img.alt}
                  className={img.class}
                />
              ))}
            {currentTaskIndex === 1 && (
              <div className={styles.balloonContainer}>
                {currentTask.images.map((img, index) => {
                  const isPopped = visibleBalloons[img.id] === "popped";
                  const isVisible = visibleBalloons[img.id] !== false;
                  return (
                    <img
                      key={index}
                      src={img.src}
                      alt={img.alt}
                      className={`${img.class} ${
                        img.isBalloon ? styles.balloon : ""
                      } ${isPopped ? styles.pop : ""}`}
                      onClick={() =>
                        img.isBalloon && handleBalloonClick(img.id)
                      }
                      style={{ display: isVisible ? "block" : "none" }}
                    />
                  );
                })}
              </div>
            )}
            {currentTaskIndex === 2 && (
              <div className={styles.triangleTaskContainerTwo}>
                <h4 className={styles.subHeader}>
                  Найдено: {foundOvalCount}/3
                </h4>
                <div className={styles.triangleAndItems}>
                  <div className={styles.itemsLeft}>
                    {currentTask.images.slice(1, 6).map((image, index) => (
                      <div
                        key={index}
                        className={`${styles.itemContainer} ${
                          selectedItems.includes(index + 1)
                            ? styles.selectedCircle
                            : ""
                        }`}
                      >
                        <img
                          src={image.src}
                          alt={image.alt}
                          className={image.class}
                          onClick={() =>
                            handleItemClick(index + 1, image.isOval)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className={styles.centerTriangle}>
                    <img
                      src={currentTask.images[0].src}
                      alt={currentTask.images[0].alt}
                      className={styles.largeOval}
                    />
                  </div>
                  <div className={styles.itemsRight}>
                    {currentTask.images.slice(6, 11).map((image, index) => (
                      <div
                        key={index + 5}
                        className={`${styles.itemContainer} ${
                          selectedItems.includes(index + 6)
                            ? styles.selectedCircle
                            : ""
                        }`}
                      >
                        <img
                          src={image.src}
                          alt={image.alt}
                          className={image.class}
                          onClick={() =>
                            handleItemClick(index + 6, image.isOval)
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {currentTaskIndex === 3 && (
              <div className={styles.canvasContainer}>
                {currentTask.images.map((img, index) => (
                  <img
                    key={index}
                    src={coloredOvals[img.id] || img.src}
                    alt={img.alt}
                    className={img.class}
                    onClick={() => handleOvalClick(img.id)}
                  />
                ))}
              </div>
            )}
            {currentTaskIndex === 4 && (
              <div className={styles.triangleTaskContainer}>
                <div className={styles.itemsUpContainer}>
                  {currentTask.images
                    .filter(
                      (img) =>
                        img.type === "UP" && !droppedItems.includes(img.alt)
                    )
                    .map((image, index) => (
                      <DraggableItem key={index} item={image} type="UP" />
                    ))}
                </div>
                <div className={styles.itemsDownContainer}>
                  {currentTask.images
                    .filter((img) => img.type === "DOWN")
                    .map((image, index) => (
                      <DropTarget
                        key={index}
                        accept="UP"
                        onDrop={(item) => handleDrop(item, image)}
                        className={styles.itemContainer}
                      >
                        <div style={{ position: "relative" }}>
                          <img
                            src={image.src}
                            alt={image.alt}
                            className={image.class}
                          />
                          {selectedItems
                            .filter((selected) => selected.alt === image.alt)
                            .map((selected, i) => (
                              <img
                                key={i}
                                src={selected.src}
                                alt={selected.alt}
                                className={selected.class}
                                style={{
                                  position: "absolute",
                                  top: selected.coordinates?.top,
                                  left: selected.coordinates?.left,
                                  width: selected.selectedWidth || "100%",
                                }}
                              />
                            ))}
                        </div>
                      </DropTarget>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className={styles.fireworksContainer}></div>
        </div>
        {currentTaskIndex < tasks.length - 1 ? (
          <button
            className={styles.button}
            onClick={handleNextTask}
            disabled={buttonDisabled}
          >
            {buttonText}
          </button>
        ) : (
          buttonText === "Послушать" && (
            <button
              className={styles.button}
              onClick={() => {
                playSound();
                setButtonText("");
              }}
              disabled={buttonDisabled}
            >
              {buttonText}
            </button>
          )
        )}
      </div>
      <audio ref={audioRef} />
      <audio ref={backgroundAudioRef} src={backgroundSound} loop />
    </DndProvider>
  );
};
export default StudyingOvalGame;
