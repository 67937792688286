import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import { isSafari, isChrome } from "react-device-detect";
import useDynamicHeight from "../../../hooks/useDynamicHeight";
import EndGameOptions from "../../end-game-options/end-game-options";
import styles from "./ball-dancing.module.css";

import wallper from "./images/wallper.png";
import alarm from "./images/alarm.png";
import badger from "./images/badger.png";
import beaver from "./images/beaver.png";
import barell from "./images/barell.png";
import bull from "./images/bull.png";
import butterfly from "./images/butterfly.png";
import house from "./images/house.png";
import loaf from "./images/loaf.png";
import notebook from "./images/notebook.png";
import shoes from "./images/shoes.png";
import squirell from "./images/squirell.png";
import repeatTaskImg from "./images/repeatTask.png";
import dynamicImg from "./images/dynamic.png";
import dynamicOff from "./images/dynamicOff.png";

import startInstructionSound from "./sounds/startInstructionSound.mp3";
import backgroundSound from "./sounds/backgroundSound.mp3";
import correctAnswerSound from "./sounds/rightAnswer.mp3";
import praiseSound from "./sounds/praise.mp3";

const colors = [
  "#FF0000",
  "#FF7F00",
  "#FFFF00",
  "#00FF00",
  "#0000FF",
  "#4B0082",
  "#8B00FF",
];

const triggerFireworks = () => {
  const fireworksContainer = document.querySelector(
    `.${styles.fireworksContainer}`
  );
  if (fireworksContainer) {
    for (let i = 0; i < 10; i++) {
      setTimeout(() => createFirework(fireworksContainer), i * 500);
    }
  } else {
    console.error("Fireworks container not found");
  }
};

const createFirework = (container) => {
  const firework = document.createElement("div");
  firework.className = styles.firework;
  firework.style.left = `${Math.random() * 100}%`;
  firework.style.top = `${Math.random() * 100}%`;
  container.appendChild(firework);

  const particles = [];
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement("div");
    particle.className = styles.particle;
    particle.style.background =
      colors[Math.floor(Math.random() * colors.length)];
    firework.appendChild(particle);
    particles.push(particle);
  }

  const tl = gsap.timeline({
    onComplete: () => {
      firework.remove();
    },
  });

  particles.forEach((particle) => {
    const angle = Math.random() * Math.PI * 2;
    const distance = Math.random() * 200 + 50;
    tl.fromTo(
      particle,
      { x: 0, y: 0, opacity: 1 },
      {
        x: Math.cos(angle) * distance,
        y: Math.sin(angle) * distance,
        opacity: 0,
        duration: 2,
        ease: "power2.out",
      },
      0
    );
  });
};

const BallDancingGame = () => {
  useDynamicHeight();
  const [isInteractionBlocked, setIsInteractionBlocked] = useState(false);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [buttonText, setButtonText] = useState("Начать игру");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isSoundOn, setIsSoundOn] = useState(true);

  const audioRef = useRef(null);
  const startInstructionAudioRef = useRef(new Audio(startInstructionSound));
  const backgroundAudioRef = useRef(new Audio(backgroundSound));
  const correctAnswerSoundRef = useRef(new Audio(correctAnswerSound));
  const praiseSoundRef = useRef(new Audio(praiseSound));

  const handleNextTask = () => {
    setIsInteractionBlocked(true);

    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.play();

      startInstructionAudioRef.current.onended = () => {
        setIsInteractionBlocked(false);
        if (backgroundAudioRef.current) {
          backgroundAudioRef.current.loop = true;
          backgroundAudioRef.current.play();
        }
      };
    }

    setIsButtonVisible(false);
  };

  const clickOnSubject = (e) => {
    if (isSafari) {
      handleSafariClick(e);
    } else {
      handleChromeAndOtherBrowsersClick(e);
    }
  };

  const handleChromeAndOtherBrowsersClick = (e) => {
    const classesToCheck = [
      styles.alarm,
      styles.badger,
      styles.beaver,
      styles.barell,
      styles.bull,
      styles.butterfly,
      styles.house,
      styles.loaf,
      styles.notebook,
      styles.shoes,
      styles.squirell,
    ];
    const classFound = classesToCheck.some((className) =>
      e.target.className.includes(className)
    );
    if (classFound) {
      setIsInteractionBlocked(true);
      if (correctAnswerSoundRef.current) {
        correctAnswerSoundRef.current.play();
      }
      e.target.classList.add(styles.growAndFade);
      const handleAnimationEnd = () => {
        e.target.style.display = "none";
        e.target.removeEventListener("animationend", handleAnimationEnd);
        setIsInteractionBlocked(false);

        const escapedClassName = CSS.escape(styles.backgroundContainer);

        const remainingItems = document.querySelectorAll(
          `.${escapedClassName} img:not([style*="display: none"])`
        );

        const remainingClasses = Array.from(remainingItems).some((item) =>
          classesToCheck.some((className) => item.className.includes(className))
        );

        if (!remainingClasses) {
          triggerFireworks();

          if (praiseSoundRef.current) {
            praiseSoundRef.current.play();
          }

          setTimeout(() => {
            setIsGameCompleted(true);
          }, 3000);
        }
      };

      e.target.addEventListener("animationend", handleAnimationEnd);
    }
  };

  const handleSafariClick = (e) => {
    const classesToCheck = [
      styles.alarm,
      styles.badger,
      styles.beaver,
      styles.barell,
      styles.bull,
      styles.butterfly,
      styles.house,
      styles.loaf,
      styles.notebook,
      styles.shoes,
      styles.squirell,
    ];
    const classFound = classesToCheck.some((className) =>
      e.target.className.includes(className)
    );
    if (classFound) {
      setIsInteractionBlocked(true);
      if (correctAnswerSoundRef.current) {
        correctAnswerSoundRef.current.play();
      }
      const element = e.target;
      let liftDistance = "-80px";
      if (e.target.className.includes(styles.lynx)) {
        liftDistance = "-130px";
      }

      const tl = gsap.timeline({
        onComplete: () => {
          element.style.display = "none";

          setIsInteractionBlocked(false);

          const escapedClassName = CSS.escape(styles.backgroundContainer);

          const remainingItems = document.querySelectorAll(
            `.${escapedClassName} img:not([style*="display: none"])`
          );

          const remainingClasses = Array.from(remainingItems).some((item) =>
            classesToCheck.some((className) =>
              item.className.includes(className)
            )
          );
          if (!remainingClasses) {
            // Запускаем фейерверк
            triggerFireworks();

            // Воспроизводим звук похвалы
            if (praiseSoundRef.current) {
              praiseSoundRef.current.play();
            }

            setTimeout(() => {
              setIsGameCompleted(true);
            }, 3000);
          }
        },
      });

      tl.to(element, {
        y: liftDistance,
        scale: 1.2,
        duration: 1.5,
        ease: "power2.out",
      });

      tl.to(element, {
        opacity: 0,
        duration: 1,
        ease: "power2.out",
      });
    }
  };

  const handleSoundOnOff = () => {
    setIsSoundOn((prevIsSoundOn) => {
      const newIsSoundOn = !prevIsSoundOn;

      if (startInstructionAudioRef.current) {
        startInstructionAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (correctAnswerSoundRef.current) {
        correctAnswerSoundRef.current.volume = newIsSoundOn ? 1 : 0;
      }
      if (praiseSoundRef.current) {
        praiseSoundRef.current.volume = newIsSoundOn ? 1 : 0;
      }

      return newIsSoundOn;
    });
  };

  const handleRepeatTask = () => {
    if (startInstructionAudioRef.current) {
      startInstructionAudioRef.current.currentTime = 0;
      startInstructionAudioRef.current.play();
    }
  };

  useEffect(() => {
    return () => {
      startInstructionAudioRef.current.pause();
      startInstructionAudioRef.current.currentTime = 0;
      backgroundAudioRef.current.pause();
      backgroundAudioRef.current.currentTime = 0;

      praiseSoundRef.current.pause();
      praiseSoundRef.current.currentTime = 0;

      gsap.globalTimeline.clear();
    };
  }, []);

  return (
    <>
      {isGameCompleted && <EndGameOptions />}

      <div className={`container ${styles.someOtherClass}`}>
        <div className={styles.backgroundContainer} onClick={clickOnSubject}>
          <div className={styles.wallpaperWrapper}>
            <img className={styles.wallper} src={wallper} alt="обои" />
            <div className={styles.absoluteContainer}>
              <div
                style={{
                  pointerEvents: isInteractionBlocked ? "none" : "auto",
                }}
              >
                <img className={styles.alarm} src={alarm} alt="будильник" />
                <img className={styles.badger} src={badger} alt="барсук" />
                <img className={styles.beaver} src={beaver} alt="бобер" />
                <img className={styles.barell} src={barell} alt="бочка" />
                <img className={styles.bull} src={bull} alt="бык" />
                <img
                  className={styles.butterfly}
                  src={butterfly}
                  alt="бабочка"
                />
                <img className={styles.house} src={house} alt="дом" />
                <img className={styles.loaf} src={loaf} alt="буханка" />
                <img className={styles.notebook} src={notebook} alt="тетрадь" />
                <img className={styles.shoes} src={shoes} alt="туфли" />
                <img className={styles.squirell} src={squirell} alt="белка" />
              </div>

              <div className={styles.buttonsContainer}>
                <button
                  className={styles.buttonsRepeatAndSoundOnOff}
                  onClick={handleSoundOnOff}
                >
                  <img
                    src={isSoundOn ? dynamicImg : dynamicOff}
                    alt="динамик"
                  />
                  <span className={styles.tooltip}>
                    Включить / выключить звук
                  </span>
                </button>

                <button
                  className={styles.buttonsRepeatAndSoundOnOff}
                  onClick={handleRepeatTask}
                >
                  <img src={repeatTaskImg} alt="повтор звука" />
                  <span className={styles.tooltip}>
                    Нажмите для повторного прослушивания инструкции
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.fireworksContainer}></div>
      </div>

      <audio ref={audioRef} />
      {isButtonVisible && (
        <div className={styles.darkOverlay}>
          <button className={styles.button} onClick={handleNextTask}>
            {buttonText}
          </button>
        </div>
      )}
    </>
  );
};

export default BallDancingGame;
