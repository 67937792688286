import { useDrag, DragPreviewImage } from "react-dnd";
import { isSafari } from "react-device-detect";

const DraggableItem = ({ id, src, alt, className, style }) => {
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: "PART",
    item: { id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <>
      {isSafari && <DragPreviewImage connect={preview} src={src} />}
      <img
        ref={drag}
        src={src}
        alt={alt}
        className={className}
        style={{ opacity: isDragging ? 0.5 : 1, touchAction: "none", ...style }}
      />
    </>
  );
};

export default DraggableItem;
